import csCZ from "./cs-CZ.json";
import da from "./da.json";
import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import fi from "./fi.json";
import fr from "./fr.json";
import hiIN from "./hi-IN.json";
import huHU from "./hu-HU.json";
import it from "./it.json";
import ja from "./ja.json";
import ko from "./ko.json";
import mrIN from "./mr-IN.json";
import msMY from "./ms-MY.json";
import nb from "./nb.json";
import nl from "./nl.json";
import plPL from "./pl-PL.json";
import pt from "./pt.json";
import ruRU from "./ru-RU.json";
import sk from "./sk.json";
import sv from "./sv.json";
import th from "./th.json";
import trTR from "./tr-TR.json";
import ukUA from "./uk-UA.json";
import viVN from "./vi-VN.json";
import zh from "./zh.json";
import lv from "./lv.json";
import zhTW from "./zh-TW.json";

const LANGUAGES_MAP = {
    "cs-CZ": csCZ,
    da: da,
    de: de,
    en: en,
    es: es,
    fi: fi,
    fr: fr,
    "hi-IN": hiIN,
    "hu-HU": huHU,
    it: it,
    ja: ja,
    ko: ko,
    "mr-IN": mrIN,
    "ms-MY": msMY,
    nb: nb,
    nl: nl,
    nn: nb,
    no: nb,
    "pl-PL": plPL,
    pt: pt,
    "ru-RU": ruRU,
    "sk": sk,
    sv: sv,
    "th": th,
    "tr-TR": trTR,
    "uk-UA": ukUA,
    "vi-VN": viVN,
    zh: zh,
    lv: lv,
    "zh-TW": zhTW
  };;
  
export const getLanguage = language => LANGUAGES_MAP[language];
