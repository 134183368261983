import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "@onelogin/react-components";
import PropTypes from "prop-types";

import {
  DetailCell,
  TextItem,
  SubTextItem,
  BoldSpan,
  ImgWrapper
} from "../../common/Table/ResponsiveTableStyles";
import Moment from "../../MomentWrapper";
import {
  ActionToggle,
  ActionToggleIcon,
  ActionToggleIconActive,
  ActionMenu,
  ActionItem
} from "../../ActionToggle";
import { commonMessages } from "../../../translations/commonMessages";

import { BrowserIcon } from "./BrowserIcon";

export const CompositeBrowserCell = ({ browserName, os }) => (
  <DetailCell>
    <ImgWrapper>
      <BrowserIcon browserName={browserName} />
      <div>
        <TextItem>
          <BoldSpan>{browserName}</BoldSpan>
        </TextItem>
        <SubTextItem>
          {os.name} {os.version}
        </SubTextItem>
      </div>
    </ImgWrapper>
  </DetailCell>
);

const osShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired
});

CompositeBrowserCell.propTypes = {
  browserName: PropTypes.string.isRequired,
  os: osShape
};

export const CompositeLastUsedCell = ({ lastUsed, locale }) => (
  <Moment fromNow locale={locale}>
    {lastUsed}
  </Moment>
);

CompositeLastUsedCell.propTypes = {
  lastUsed: PropTypes.string.isRequired,
  locale: PropTypes.string
};

export const CompositeExpireCell = ({ expirationDate, locale }) =>
  expirationDate ? (
    <Moment fromNow locale={locale}>
      {expirationDate}
    </Moment>
  ) : (
    <FormattedMessage id="noExpiration" defaultMessage="Never expires" />
  );

CompositeExpireCell.propTypes = {
  expirationDate: PropTypes.string,
  locale: PropTypes.string
};

const ExpanderMenu = ({
  browser,
  setMenuOpen,
  onShowDetails,
  onClickRemove
}) => (
  <ActionMenu>
    <ActionItem
      tabIndex={0}
      onClick={() => {
        setMenuOpen(null);
        onClickRemove(browser);
      }}
      data-testid="revoke-button"
    >
      <FormattedMessage {...commonMessages.revoke} />
    </ActionItem>
    <ActionItem
      tabIndex={0}
      onClick={() => {
        setMenuOpen(null);
        onShowDetails(browser.id);
      }}
      data-testid="show-details-button"
    >
      <FormattedMessage {...commonMessages.showDetails} />
    </ActionItem>
  </ActionMenu>
);

const browserShape = PropTypes.shape({
  id: PropTypes.number.isRequired
});

ExpanderMenu.propTypes = {
  browser: browserShape,
  onClickRemove: PropTypes.func.isRequired,
  onShowDetails: PropTypes.func.isRequired,
  setMenuOpen: PropTypes.func.isRequired
};

const ExpanderCell = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CompositeExpanderCell = ({
  browser,
  open,
  setMenuOpen,
  onShowDetails,
  onClickRemove
}) => (
  <ExpanderCell>
    <div data-testid="tooltip">
      <Tooltip
        open={open}
        dir="bottom"
        lean="left"
        onFocusLeave={() => setMenuOpen(null)}
        tooltip={() => (
          <ExpanderMenu
            {...{
              browser,
              setMenuOpen,
              onShowDetails,
              onClickRemove
            }}
          />
        )}
      >
        <ActionToggle onClick={() => setMenuOpen(open ? null : browser.id)}>
          {open ? <ActionToggleIconActive /> : <ActionToggleIcon />}
        </ActionToggle>
      </Tooltip>
    </div>
  </ExpanderCell>
);

CompositeExpanderCell.propTypes = {
  browser: PropTypes.object.isRequired,
  onClickRemove: PropTypes.func.isRequired,
  onShowDetails: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired
};
