import * as profileService from "../api/profileServiceApi";
import * as types from "../constants/ActionTypes";

import { FormattedMessage, defineMessages } from "react-intl";
import {
  getTranslations,
  resolveBestLanguage
} from "@onelogin/react-components";
import { notify, notifyError } from "../actions/notificationsActions";

import React from "react";
import { addMiddleware } from "redux-dynamic-middlewares";
import { fatalError } from "../actions/fatalErrorActions";
import { fetchBranding } from "../actions/otherActions";
import { fetchDesktopDetails } from "../actions/desktopActions";
import { fetchPasswordPolicy } from "../actions/passwordActions";
import { fetchRecentActivity } from "../actions/recentActivityActions";
import { getLanguage } from "../translations/translations";
import { isEmpty } from "ramda";
import localStorage from "../storage/localStorage";
import lspMiddleware from "../utils/lspMiddleware";
import { updateIntl } from "react-intl-redux";

const uuidv4 = require("uuid/v4");

const messages = defineMessages({
  failedToFetchUserInfo: {
    id: "failedToFetchUserInfo",
    defaultMessage: `We couldn’t load your user information.`
  }
}); //Formatted Message? YES

export const uploadProfilePhoto = blob => dispatch => {
  return profileService
    .uploadProfilePhoto(blob)
    .then(response => {
      dispatch({
        type: types.RELOAD_PROFILE_PHOTO,
        profilePicture: response.profilePicture
      });

      dispatch(
        notify({
          message: (
            <FormattedMessage
              defaultMessage="You have successfully updated your profile picture."
              id="updateUserProfilePictureSuccess"
            />
          ),
          type: "success"
        })
      );
    })
    .catch(() => {
      dispatch(
        notifyError({
          message: (
            <FormattedMessage
              defaultMessage={`Profile Photo Update Failed`}
              id="updateUserProfilePhotoFailure"
            />
          )
        })
      );
    });
};

export const notifyImageParseFail = () => dispatch => {
  dispatch(
    notifyError({
      message: (
        <FormattedMessage
          defaultMessage={`Getting image details failed`}
          id="parsingUserProfilePhotoFailure"
        />
      )
    })
  );
};

export const deleteProfilePhoto = () => dispatch => {
  return profileService.deleteProfilePhoto().then(response => {
    dispatch({
      type: types.RELOAD_PROFILE_PHOTO,
      profilePicture: response.profilePicture
    });
    dispatch(
      notify({
        message: (
          <FormattedMessage
            defaultMessage="You have successfully removed your profile picture."
            id="removedProfilePictureSuccess"
          />
        ),
        type: "success"
      })
    );
  });
};

export const updateUserProfile = data => (dispatch, getState) => {
  dispatch({ type: types.UPDATE_USER_REQUEST });
  return profileService
    .updateSettings(data)
    .then(() => {
      const state = getState();
      //when language changed reload whole app
      if (
        data.currentLanguageLocale &&
        state.user.currentLanguageLocale !== data.currentLanguageLocale
      ) {
        dispatch({
          type: types.UPDATE_USER_RECEIVED_LANGUAGE_CHANGED
        });
      } else {
        //otherwise update local settings and notify user
        dispatch({
          type: types.UPDATE_USER_RECEIVED,
          data
        });

        dispatch(
          notify({
            message: (
              <FormattedMessage
                defaultMessage="You have successfully updated your profile."
                id="updateUserProfileSuccess"
              />
            ),
            type: "success"
          })
        );
      }
    })
    .catch(() => {
      dispatch({
        type: types.UPDATE_USER_FAILED,
        data
      });
      dispatch(
        notifyError({
          message: (
            <FormattedMessage
              defaultMessage={`Profile Update Failed`}
              id="updateUserProfileFailure"
            />
          )
        })
      );
    });
};

export const fetchMe = dataVersion => dispatch => {
  return profileService.fetchMe().then(user => {
    if (user) {
      const email = user.email || user.username;
      const { getBrandingCacheState } = localStorage({
        dataVersion,
        email
      });

      addMiddleware(lspMiddleware({ dataVersion, email }));

      dispatch({
        type: types.FETCH_TRACKING_ID,
        trackingId: uuidv4()
      });

      dispatch({
        type: types.FETCH_USER_RECEIVED,
        user
      });

      const menuConditions = {
        showOlDesktopMenu:
          user.userSettings && !!user.userSettings.ol_desktop_enabled,
        showTrustedBrowsersMenu:
          user.userSettings && !!user.userSettings.trusted_browsers_enabled,
        showChangePasswordMenu:
          user.userSettings && !!user.userSettings.allow_change_password,
        showBrowserExtensionMenu:
          user.userSettings && !!user.userSettings.policy_enables_extension
      };

      dispatch({
        type: types.FILTER_MENU_ITEMS,
        menuConditions
      });

      const brandingCacheState = getBrandingCacheState();
      if (!isEmpty(brandingCacheState)) {
        dispatch({
          type: types.FETCH_BRANDING_RECEIVED,
          data: {
            ...brandingCacheState
          }
        });
      }

      dispatch(loadTranslations(user.locale));
      dispatch(fetchBranding());
      dispatch(fetchPasswordPolicy());
      dispatch(fetchRecentActivity);
      dispatch(fetchDesktopDetails);
    } else {
      dispatch(fatalError(messages.failedToFetchUserInfo));
    }
  });
};

export const fetchAccount = () => dispatch => {
  return profileService.fetchAccount().then(
    data => {
      dispatch({
        type: types.FETCH_ACCOUNT_RECEIVED,
        data
      });
    },
    error => {
      dispatch({
        type: types.FETCH_ACCOUNT_ERROR
      });
    }
  );
};

export const loadTranslations = userLanguage => (dispatch, getState) => {
  const { languageTag, primaryLanguageTag } = resolveBestLanguage({
    userLanguage
  });
  const messages = {
    ...getTranslations(languageTag),
    ...getLanguage(languageTag)
  };
  if (messages) {
    dispatch(updateIntl({ locale: primaryLanguageTag, messages }));
  }
};

export const setProfileImageData = imageData => dispatch => {
  dispatch({
    type: types.SET_PROFILE_IMAGE_DATA,
    imageData
  });
};

export const setProfileFormData = formData => dispatch => {
  dispatch({
    type: types.SET_PROFILE_FORM_DATA,
    formData
  });
};
