import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { pick, find, propEq, keys } from "ramda";
import PropTypes from "prop-types";
import CookieSettingsButton from "./CookieSettingsButton";
import SwitchLanguageDialog from "./SwitchLanguageDialog";
import {
  ContentBlock,
  Label,
  Button,
  Dropdown
} from "@onelogin/react-components";
import phoneNumberInputStyles from "./PhoneNumberInput.styles";
import { Languages } from "./SupportedLanguages";
import { MID_VIEW_LIMIT, PUBLIC_URL } from "../../../constants/Env";
import FormFooter from "../../FormFooter";
import {
  updateUserProfile,
  setProfileFormData
} from "../../../actions/profileActions";
import { commonMessages } from "../../../translations/commonMessages";

const formDataProps = ["currentLanguageLocale", "phoneNumber", "defaultTabId"];
// this component is still exported as default from react-phone-input-2
const PhoneInput = require("react-phone-input-2").default;

const PhoneInputWrapper = styled.div`
  ${phoneNumberInputStyles}

  .react-tel-input {
    .form-control {
      line-height: normal;
      height: 38px;
      border-radius: 2px;
      width: 100%;
      padding-left: 66px;
    }

    .flag-dropdown {
      background-color: white;
      width: 54px;

      .selected-flag {
        padding-left: 12px;
        z-index: inherit;
        .arrow {
          left: 24px;
        }
      }
    }
  }
`;
const TryHorizontalContentBlock = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${MID_VIEW_LIMIT}px) {
    flex-direction: column;
  }
`;

const LeftFlexBlock = styled.div`
  margin-right: 15px;
  width: 100%;
  max-width: 350px;

  @media screen and (max-width: ${MID_VIEW_LIMIT}px) {
    margin-bottom: 16px;
    max-width: 100%;
  }
`;
const RightFlexBlock = styled.div`
  margin-left: 15px;
  width: 100%;

  @media screen and (max-width: ${MID_VIEW_LIMIT}px) {
    margin: 0px;
  }
`;

export class ProfileDetails extends React.Component {
  componentDidMount() {
    document.title = this.props.intl.formatMessage(
      {
        id: "profileTitle",
        defaultMessage: "Profile | {brandName}"
      },
      { brandName: this.props.brandedPageTitle }
    );
  }

  onLanguageChange = selectedLocale => {
    if (!this.props.isAssumed) {
      this.props.setProfileFormData({
        currentLanguageLocale: selectedLocale
      });
    }
  };

  onLanguageClose = () => {
    this.props.history.push(`${PUBLIC_URL}`);
  };

  onLanguageConfirm = () => {
    const { changedFormData = {} } = this.props;
    this.props.updateUserProfile(changedFormData);

    this.props.history.push(`${PUBLIC_URL}`);
  };

  handleProfileSubmit = () => {
    const { changedFormData = {}, savedLanguageLocale } = this.props;
    if (
      changedFormData.currentLanguageLocale !== undefined &&
      changedFormData.currentLanguageLocale !== savedLanguageLocale
    ) {
      this.props.history.push(
        `${PUBLIC_URL}/language/${changedFormData.currentLanguageLocale}`
      );
    } else {
      if (changedFormData?.phoneNumber?.length <= 2) {
        changedFormData.phoneNumber = "";
      } else if (changedFormData?.phoneNumber) {
        changedFormData.phoneNumber = "+" + changedFormData.phoneNumber;
      }
      this.props.updateUserProfile(changedFormData);
    }
  };

  handleChangeTabs = selectedTabId => {
    if (!this.props.isAssumed) {
      this.props.setProfileFormData({
        defaultTabId: selectedTabId
      });
    }
  };

  tabOptions = () => {
    //TODO: tabs should be sorted already in readucer
    const sortedTabOptions = this.props.tabs
      .map(({ id, position, name }) => ({ value: id, text: name, position }))
      .sort((a, b) => a.position - b.position);

    return [
      {
        value: null,
        position: -2,
        text: this.props.intl.formatMessage({
          id: "useLastSelectedOption",
          defaultMessage: "-- Use Last Selected --"
        })
      },
      {
        value: 0,
        position: -1,
        text: this.props.intl.formatMessage({
          id: "allAppsOption",
          defaultMessage: "-- All Apps --"
        })
      },
      ...sortedTabOptions
    ];
  };

  render() {
    const {
      phoneNumberIsEditable,
      localeEnabled,
      cookieManagementEnabled,
      isAssumed,
      languageOptions,
      newLanguage,
      doReload,
      changed,
      phoneNumber,
      defaultTabId,
      currentLanguageLocale,
      saving
    } = this.props;
    if (doReload) {
      //when language changed
      window.location.reload();
      return;
    }
    return (
      <>
        <TryHorizontalContentBlock>
          <LeftFlexBlock>
            <ContentBlock>
              <Label>
                <FormattedMessage
                  defaultMessage="Phone number"
                  id={"phoneNumber"}
                />
              </Label>
              <PhoneInputWrapper>
                <PhoneInput
                  onChange={phoneNumber => {
                    this.props.setProfileFormData({
                      phoneNumber
                    });
                  }}
                  country={"us"}
                  countryCodeEditable
                  value={phoneNumber}
                  disabled={!phoneNumberIsEditable || isAssumed}
                />
              </PhoneInputWrapper>
            </ContentBlock>
            {localeEnabled && (
              <>
                <Label>
                  <FormattedMessage defaultMessage="Language" id={"language"} />
                </Label>
                <Dropdown
                  id="language"
                  list={languageOptions}
                  value={currentLanguageLocale}
                  onChange={this.onLanguageChange}
                  ariaLabel={this.props.intl.formatMessage({
                    id: "languageDropdown",
                    defaultMessage: "Language Dropdown"
                  })}
                />
              </>
            )}
          </LeftFlexBlock>
          <RightFlexBlock>
            <ContentBlock>
              <Label>
                <FormattedMessage
                  defaultMessage="Default for company tab"
                  id={"companyTab"}
                />
              </Label>
              <Dropdown
                id="company-tab"
                list={this.tabOptions()}
                value={defaultTabId}
                onChange={this.handleChangeTabs}
                ariaLabel={this.props.intl.formatMessage({
                  id: "companyTabDropdown",
                  defaultMessage: "Default Company Tab Dropdown"
                })}
              />
            </ContentBlock>
          </RightFlexBlock>
        </TryHorizontalContentBlock>
        <FormFooter>
          <Button
            size="large"
            type="submit"
            look="primary"
            data-testid="profile-details-save-button"
            disabled={saving || !changed || isAssumed}
            onClick={this.handleProfileSubmit.bind(this)}
          >
            <FormattedMessage {...commonMessages.save} />
          </Button>
          {cookieManagementEnabled && <CookieSettingsButton />}
        </FormFooter>
        <Switch>
          <Route exact path={`${PUBLIC_URL}/language/:locale`}>
            <SwitchLanguageDialog
              newLanguage={newLanguage}
              onClose={this.onLanguageClose}
              onConfirm={this.onLanguageConfirm}
            />
          </Route>
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const permittedLocales = state.account.locales || [];
  const languageOptions = Languages.filter(lang =>
    permittedLocales.includes(lang.locale)
  ).map(lang => ({ ...lang, value: lang.locale, text: lang.name }));

  const newLanguageItem = find(
    propEq("locale", ownProps.match.params.locale),
    languageOptions
  );

  const changedFormData = pick(formDataProps, state.user.changedFormData || {});

  return {
    phoneNumber: changedFormData.phoneNumber || state.user.phoneNumber,
    phoneNumberIsEditable: state.user.phoneNumberIsEditable,
    defaultTabId:
      changedFormData.defaultTabId !== undefined
        ? changedFormData.defaultTabId
        : state.user.defaultTabId,
    currentLanguageLocale:
      changedFormData.currentLanguageLocale || state.user.currentLanguageLocale,
    savedLanguageLocale: state.user.currentLanguageLocale,
    tabs: state.user.tabOptions,
    localeEnabled: state.account.localeEnabled,
    cookieManagementEnabled: state.account.cookieManagementEnabled,
    isAssumed: state.user.isAssumed,
    brandedPageTitle: state.account.brandedPageTitle,
    languageOptions,
    newLanguage: newLanguageItem ? newLanguageItem.name : "",
    doReload: state.user.doReload,
    changed: keys(changedFormData).length > 0,
    changedFormData,
    saving: state.user.saving
  };
};

const mapDispatchToProps = dispatch => ({
  updateUserProfile: userData => {
    dispatch(updateUserProfile(userData));
  },
  setProfileFormData: formData => {
    dispatch(setProfileFormData(formData));
  }
});

const changedFormDataShape = PropTypes.shape({
  phoneNumber: PropTypes.string,
  defaultTabId: PropTypes.number,
  currentLanguageLocale: PropTypes.string
}).isRequired;

const languageOptionShape = PropTypes.shape({
  locale: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
});

const tabShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
});

ProfileDetails.propTypes = {
  brandedPageTitle: PropTypes.string.isRequired,
  changed: PropTypes.bool.isRequired,
  changedFormData: changedFormDataShape,
  currentLanguageLocale: PropTypes.string.isRequired,
  defaultTabId: PropTypes.number,
  doReload: PropTypes.bool,
  isAssumed: PropTypes.bool.isRequired,
  languageOptions: PropTypes.arrayOf(languageOptionShape).isRequired,
  localeEnabled: PropTypes.bool.isRequired,
  cookieManagementEnabled: PropTypes.bool.isRequired,
  newLanguage: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  phoneNumberIsEditable: PropTypes.bool.isRequired,
  savedLanguageLocale: PropTypes.string.isRequired,
  saving: PropTypes.bool,
  setProfileFormData: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(tabShape),
  updateUserProfile: PropTypes.func.isRequired
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ProfileDetails)
  )
);
