import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

import { Button, ModalDialog } from "@onelogin/react-components";
import { MOBILE_VIEW_LIMIT } from "../../../constants/Env";

const Instruction = styled.div`
  margin-top: 16px;
`;

const PasswordRedirectModal = ({
  onCancelChangePassword,
  redirectMessage,
  redirectUrl
}) => {
  return (
    <ModalDialog
      fixedWidth={MOBILE_VIEW_LIMIT}
      onClose={onCancelChangePassword}
      closeOnEscape
      closeOnClick
    >
      <ModalDialog.Header>
        <FormattedMessage
          id={"redirectToChangePassword"}
          defaultMessage="Redirect to change your password?"
        />
      </ModalDialog.Header>
      <ModalDialog.Content>
        <Instruction>
          <ReactMarkdown source={redirectMessage} />
        </Instruction>
      </ModalDialog.Content>
      <ModalDialog.Actions>
        <a href={redirectUrl} target="_blank" rel="noopener noreferrer">
          <Button look="regular" data-testid="redirect-button-modal">
            <FormattedMessage
              defaultMessage="Go to external site"
              id="goToExternalSite"
            />
          </Button>
        </a>
      </ModalDialog.Actions>
    </ModalDialog>
  );
};

PasswordRedirectModal.propTypes = {
  onCancelChangePassword: PropTypes.func.isRequired,
  redirectMessage: PropTypes.string,
  redirectUrl: PropTypes.string
};

export default PasswordRedirectModal;
