import { isNil } from "ramda";
import moment from "moment";

import * as localStorageConstants from "../constants/localStorageConstants";
import localStoragePackage from "@onelogin/local-storage-package";

// This flag is shared among web-apps
export const getProceedUnsupportedBrowser = () =>
  localStorage.getItem(localStorageConstants.PROCEED_UNSUPPORTED);

export const setProceedUnsupportedBrowser = isDeprecatedBrowser => {
  localStorage.setItem(localStorageConstants.PROCEED_UNSUPPORTED, true);

  if (isDeprecatedBrowser) {
    localStorage.setItem(
      localStorageConstants.NEXT_DEPRECATED_WARNING_DATE,
      moment()
        .add(7, "days")
        .valueOf()
    );
  }
};

export const getNextDeprecatedWarningDate = () => {
  return localStorage.getItem(
    localStorageConstants.NEXT_DEPRECATED_WARNING_DATE
  );
};

export default ({ dataVersion, email }) => {
  const { getItem, setItem } = localStoragePackage({ dataVersion, email });

  const saveDataCache = data => {
    const dataCache = getItem(localStorageConstants.DATA_CACHE) || {};
    setItem(localStorageConstants.DATA_CACHE, { ...dataCache, ...data });
  };

  const getDataCache = () => getItem(localStorageConstants.DATA_CACHE) || {};

  // To ensure that data intended for redux always returns an empty object from the cache
  const readCachedData = key => {
    const data = getDataCache()[key];
    return isNil(data) ? {} : data;
  };

  const getBrandingCacheState = () =>
    readCachedData(localStorageConstants.BRANDING);

  return {
    saveDataCache,
    getDataCache,
    getBrandingCacheState
  };
};
