import { Airbrake } from "./airbrake";
import airbrakeMiddleware from "./airbrakeMiddleware";

export default class ErrorReporting {
  constructor({ isEnabled, environment, projectId, projectKey }) {
    this.isEnabled = isEnabled;

    if (isEnabled) {
      this.airbrakeClient = new Airbrake({
        environment: environment,
        projectId: projectId,
        projectKey: projectKey
      });
    }
  }

  getAirbrakeMiddleware = () => {
    return this.isEnabled ? airbrakeMiddleware(this.airbrakeClient) : undefined;
  };

  getAirbrakeClient = () => {
    return this.isEnabled ? this.airbrakeClient : undefined;
  };
}
