import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { ModalDialog, LoginLoading } from "@onelogin/react-components";
import {
  notify,
  notifyError,
  showMfaNotification,
  clearMfaNotification
} from "../../../actions/notificationsActions";

import {
  resetMfaToken,
  fetchAvailableFactors
} from "../../../actions/mfaActions";

import { SMALL_MODAL_SIZE } from "../../../constants/Env";
import MultiFactorRegistration from "./MultiFactorRegistration";

class MfaRegistrationModal extends Component {
  componentWillMount() {
    this.props.getAvailableFactors();
  }

  componentWillUnmount() {
    this.props.resetMfaToken();
  }

  render = () => {
    const {
      factors,
      onClose,
      mfaToken,
      email,
      showSuccessNotification,
      onSuccess,
      showErrorNotification,
      mfaTokenError,
      showMfaNotification,
      clearMfaNotification,
      mfaNotifications,
      intl,
      trackingId,
      defaultLanguage,
      canManageDevices
    } = this.props;

    return (
      <ModalDialog
        fixedWidth={SMALL_MODAL_SIZE}
        onClose={onClose}
        closeOnEscape
        closeOnClick
      >
        {mfaToken ? (
          <MultiFactorRegistration
            canManageDevices={canManageDevices}
            factors={factors}
            mfaToken={mfaToken}
            username={email}
            trackingId={trackingId}
            defaultLanguage={defaultLanguage}
            onClose={onClose}
            showSuccessNotification={showSuccessNotification}
            showErrorNotification={showErrorNotification}
            onSuccess={onSuccess}
            mfaTokenError={mfaTokenError}
            showMfaNotification={showMfaNotification}
            clearMfaNotification={clearMfaNotification}
            mfaNotifications={mfaNotifications}
            intl={intl}
          />
        ) : (
          <LoginLoading />
        )}
      </ModalDialog>
    );
  };
}

MfaRegistrationModal.propTypes = {
  intl: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  mfaToken: PropTypes.string,
  mfaTokenError: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  mfaNotifications: PropTypes.array.isRequired,
  trackingId: PropTypes.string.isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  isLoginsOnly: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => {
  let factors = state.mfa.factors || [];

  if (ownProps.isLoginsOnly) {
    factors = factors.filter(factor => factor.isLoginFactor === true);
  }

  return {
    canManageDevices: state.user.canManageDevices,
    mfaToken: state.mfa.mfaToken,
    email: state.user.email,
    mfaNotifications: state.notifications.mfaNotificationsQueue,
    trackingId: state.user.trackingId,
    defaultLanguage: state.user.currentLanguageLocale,
    factors
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  showSuccessNotification: () =>
    dispatch(
      notify({
        message: (
          <FormattedMessage
            defaultMessage="You successfully registered the device"
            id="mfaRegistrationSuccess"
          />
        ),
        type: "success"
      })
    ),
  showErrorNotification: notification =>
    dispatch(
      notifyError({
        message: notification ? (
          notification
        ) : (
          <FormattedMessage
            defaultMessage="Verification rejected. Please try again in a moment"
            id="mfaRegistrationFailure"
          />
        )
      })
    ),
  showMfaNotification: (notification, type) =>
    dispatch(
      showMfaNotification({
        message: notification,
        type
      })
    ),
  clearMfaNotification: id => {
    dispatch(clearMfaNotification(id));
  },
  resetMfaToken: () => dispatch(resetMfaToken()),
  getAvailableFactors: () => dispatch(fetchAvailableFactors())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MfaRegistrationModal);
