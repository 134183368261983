import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import ResponsiveTable from "../../common/Table/ResponsiveTable";
import {
  CompositeBrowserCell,
  CompositeLastUsedCell,
  CompositeExpireCell,
  CompositeExpanderCell
} from "./TrustedBrowsersCell.js";
import { commonMessages } from "../../../translations/commonMessages";

const Keys = {
  ID: "ID",
  BROWSER: "BROWSER",
  LAST_USED: "LAST_USED",
  EXPIRE_DATE: "EXPIRE_DATE",
  EXPANDER: "EXPANDER"
};

const getTableStructure = () => ({
  columns: [
    {
      minWidth: 200,
      weight: 33,
      priority: 3,
      content: <FormattedMessage {...commonMessages.browser} />,
      key: Keys.BROWSER
    },
    {
      minWidth: 150,
      weight: 33,
      priority: 2,
      content: <FormattedMessage {...commonMessages.lastUsed} />,
      key: Keys.LAST_USED
    },
    {
      minWidth: 150,
      weight: 33,
      priority: 1,
      content: <FormattedMessage {...commonMessages.expireDate} />,
      key: Keys.EXPIRE_DATE
    },
    {
      minWidth: 32,
      weight: 10,
      priority: 4, // Highest priority to always be displayed
      content: "",
      key: Keys.EXPANDER
    }
  ]
});

const TrustedBrowsersTable = ({
  browserData,
  showDetails,
  locale,
  onClickRemove
}) => {
  const [tableStructure] = useState(getTableStructure());
  const [menuOpenId, setMenuOpen] = useState(null);

  tableStructure.data = [];

  if (browserData) {
    for (const item of browserData) {
      const {
        id,
        browserInfo,
        osInfo,
        lastUsed,
        expirationDate,
        current
      } = item;

      const browserCell = (
        <CompositeBrowserCell browserName={browserInfo.name} os={osInfo} />
      );

      const lastUsedCell = (
        <CompositeLastUsedCell lastUsed={lastUsed} locale={locale} />
      );

      const expireDateCell = (
        <CompositeExpireCell expirationDate={expirationDate} locale={locale} />
      );

      const expanderCell = (
        <CompositeExpanderCell
          browser={item}
          open={menuOpenId === item.id}
          setMenuOpen={setMenuOpen}
          onShowDetails={showDetails}
          onClickRemove={onClickRemove}
        />
      );

      const cells = [
        { key: Keys.ID, content: id },
        { key: Keys.BROWSER, content: browserCell },
        { key: Keys.LAST_USED, content: lastUsedCell },
        { key: Keys.EXPIRE_DATE, content: expireDateCell },
        { key: Keys.EXPANDER, content: expanderCell }
      ];

      const meta = {
        isHighlighted: current,
        rowTitle: current && <FormattedMessage {...commonMessages.current} />
      };

      tableStructure.data.push({ cells, meta });
    }
  }

  return (
    <ResponsiveTable
      tableStructure={tableStructure}
      noDataMessage={
        <FormattedMessage
          id="noTrustedBrowsers"
          defaultMessage="No browsers have been saved"
        />
      }
      hideTopBorderMobile
    />
  );
};

TrustedBrowsersTable.propTypes = {
  browserData: PropTypes.array.isRequired,
  showDetails: PropTypes.func.isRequired,
  locale: PropTypes.string
};

export default TrustedBrowsersTable;
