import React, { Component } from "react";
import PropTypes from "prop-types";

import getMfaApi from "../../../../api/mfaService";
import { binToStr, strToBin } from "../register/mfa/utils/serialization";
import { WebAuthn } from "@onelogin/react-components";
import { defineMessages } from "react-intl";

const messages = defineMessages({
  registrationTimedout: "Registration context timed out"
});
class RegisterWebAuthn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.mfaService = getMfaApi(
      this.props.trackingId,
      this.props.defaultLanguage
    );
  }

  createMakeCredRequest = makeCredReq => {
    const publicKeyCredentials = {
      ...makeCredReq,
      challenge: strToBin(makeCredReq.challenge)
    };
    publicKeyCredentials.user.id = strToBin(makeCredReq.user.id);
    return publicKeyCredentials;
  };

  initRegistration = () => {
    this.setState({
      loading: true
    });
    return this.mfaService
      .initRegistration(this.props.mfaApiToken, this.props.factorId)
      .then(({ id, payload }) => {
        this.registrationId = id;
        const publicKey = this.createMakeCredRequest(payload);
        this.setState({
          loading: false
        });
        return navigator.credentials.create({ publicKey });
      })
      .then(attestation => {
        const publicKeyCredential = {
          origin: window.location.origin
        };

        if ("id" in attestation) {
          publicKeyCredential.id = attestation.id;
        }
        if ("type" in attestation) {
          publicKeyCredential.type = attestation.type;
        }
        if ("rawId" in attestation) {
          publicKeyCredential.rawId = binToStr(attestation.rawId);
        }
        const response = {};

        response.clientDataJSON = binToStr(attestation.response.clientDataJSON);
        response.attestationObject = binToStr(
          attestation.response.attestationObject
        );
        publicKeyCredential.response = response;
        return this.onSubmitOtp(publicKeyCredential);
      });
  };

  handleRegistrationError = err => {
    if (err && err.code === 404) {
      this.props.onError(messages.registrationTimedout);
    } else {
      this.props.onError(err);
    }
  };

  onSubmitOtp = otp => {
    this.setState({
      loading: true
    });
    const { mfaApiToken } = this.props;
    this.mfaService
      .submitRegistrationOtp(mfaApiToken, this.registrationId, otp)
      .then(() =>
        this.mfaService.getRegistration(mfaApiToken, this.registrationId)
      )
      .then(body => {
        switch (body.status) {
          case "accepted":
            this.props.onSuccess();
            break;
          case "rejected":
            this.props.onError();
            break;
          default:
            this.props.onError();
            this.setState({
              loading: false
            });
        }
      })
      .catch(err => err.response.json().then(this.handleRegistrationError));
  };

  render = () => {
    return (
      <WebAuthn
        loading={this.state.loading}
        authenticateUser={this.initRegistration}
      />
    );
  };
}

RegisterWebAuthn.propTypes = {
  mfaApiToken: PropTypes.string.isRequired,
  factorId: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  trackingId: PropTypes.number.isRequired,
  defaultLanguage: PropTypes.string.isRequired
};

export default RegisterWebAuthn;
