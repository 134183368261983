import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Icon, Menu, MenuItem } from "@onelogin/react-components";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";

import { ActionToggle, ActionToggleIcon } from "../../ActionToggle";

const PROFILE_SIZE = 120;
const BORDER_WIDTH = 7;
const MOBILE_CAMERA_SIZE = 48;
const INNER_PROFILE_SIZE = PROFILE_SIZE - 2 * BORDER_WIDTH;

const messages = defineMessages({
  profilePhoto: {
    id: "profilePhoto",
    defaultMessage: "Profile photo"
  },
  profileAvatar: {
    id: "profileAvatar",
    defaultMessage: "Profile avatar"
  },
  profilePhotoAction: {
    id: "profilePhotoAction",
    defaultMessage: "Profile image action"
  }
});

const Container = styled.div`
  position: relative;
`;

const MenuContainer = styled.div`
  height: 0px;
`;

const ImageWrapper = styled.div`
  border-radius: 50%;
  width: ${PROFILE_SIZE}px;
  height: ${PROFILE_SIZE}px;
  border: ${BORDER_WIDTH}px solid #ffffff;
  background-color: #f3f3f4;
  overflow: hidden;
`;

const AvatarIcon = styled.img`
  width: ${INNER_PROFILE_SIZE}px;
  height: ${INNER_PROFILE_SIZE}px;
`;

const ChangePhotoButton = styled.div`
  width: ${MOBILE_CAMERA_SIZE}px;
  height: ${MOBILE_CAMERA_SIZE}px;
  border-radius: 50%;
  position: absolute;
  bottom: 0px;
  right: -${MOBILE_CAMERA_SIZE / 2}px;
  background-color: white;

  display: flex;
  justify-content: center;
  align-items: center;
`;

class ProfileImage extends Component {
  render() {
    const {
      canBeChanged,
      addImageClick,
      removeImageClick,
      profileImageUrl,
      intl
    } = this.props;
    const hasImage = !!profileImageUrl;

    //considering user can still remove the image, even when he has new upload disabled
    //there should be at least one menu item visible, otherwise it makes no sense to display menu button
    const displayMenu = canBeChanged || hasImage;

    return (
      <Container>
        <ImageWrapper>
          {hasImage ? (
            <AvatarIcon
              crossOrigin="anonymous"
              src={profileImageUrl}
              alt={intl.formatMessage(messages.profilePhoto)}
            />
          ) : (
            <Icon
              name="Avatar_24"
              size={`${INNER_PROFILE_SIZE}px`}
              alt={intl.formatMessage(messages.profileAvatar)}
            />
          )}
        </ImageWrapper>
        {displayMenu ? (
          <MenuContainer>
            <Menu
              position="bottom"
              toggle={
                <ChangePhotoButton>
                  <ActionToggle data-testid="profile-image-action-toggle">
                    <ActionToggleIcon aria-label={intl.formatMessage(messages.profilePhotoAction)} />
                  </ActionToggle>
                </ChangePhotoButton>
              }
            >
              {canBeChanged && (
                <div data-testid="profile-image-action-add">
                  <MenuItem onClick={addImageClick} tabIndex={0}>
                    <FormattedMessage
                      id="selectNewImage"
                      defaultMessage="Select new image"
                    />
                  </MenuItem>
                </div>
              )}
              {hasImage && (
                <div data-testid="profile-image-action-remove">
                  <MenuItem onClick={removeImageClick} tabIndex={0}>
                    <FormattedMessage
                      id="removeImage"
                      defaultMessage="Remove image"
                    />
                  </MenuItem>
                </div>
              )}
            </Menu>
          </MenuContainer>
        ) : null}
      </Container>
    );
  }
}

ProfileImage.propTypes = {
  profileImageUrl: PropTypes.string,
  addImageClick: PropTypes.func.isRequired,
  removeImageClick: PropTypes.func.isRequired,
  canBeChanged: PropTypes.bool.isRequired
};

export default injectIntl(ProfileImage);
