import styled from "styled-components";
import { COLORS } from "@onelogin/react-components";

export const TextItem = styled.div`
  margin-bottom: 3px;
  color: ${COLORS.GRAY_COLORS.GRAY_35};
`;

export const SubTextItem = styled.div`
  margin-top: 3px;
  color: ${COLORS.GRAY_COLORS.GRAY_45};
`;

export const HeaderCell = styled.div`
  font-size: 13px;
  font-weight: 500;
`;

export const DetailCell = styled(HeaderCell)`
  font-weight: unset;
`;

export const BoldSpan = styled.span`
  font-weight: 500;
  color: ${COLORS.GRAY_COLORS.GRAY_25};
`;

export const ImgWrapper = styled.span`
  display: flex;

  img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    margin-left: 4px;
  }
`;
