import React, { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";

import {
  AnswerSecurityQuestions,
  LoginLoading
} from "@onelogin/react-components";
import getMfaApi from "../../../../api/mfaService";

const messages = defineMessages({
  registrationTimedout: "Registration context timed out"
});

class RegisterQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      payload: undefined
    };

    this.mfaService = getMfaApi(
      this.props.trackingId,
      this.props.defaultLanguage
    );
    this.registrationId = undefined;
  }

  componentDidMount = () => {
    this.initRegistration();
  };

  initRegistration = () => {
    this.mfaService
      .initRegistration(this.props.mfaApiToken, this.props.factorId)
      .then(({ id, payload }) => {
        this.registrationId = id;

        if (payload && payload.required === 0) {
          // handling situation when 0 answers are required for registration (AU-2318)
          this.onSubmitOtp({});
        } else {
          this.setState({
            loading: false,
            payload
          });
        }
      })
      .catch(err => err.response.json().then(this.props.onError));
  };

  onSubmitOtp = otp => {
    this.setState({
      loading: true
    });
    const { mfaApiToken } = this.props;
    this.mfaService
      .submitRegistrationOtp(mfaApiToken, this.registrationId, { answers: otp })
      .then(() =>
        this.mfaService.getRegistration(mfaApiToken, this.registrationId)
      )
      .then(body => {
        switch (body.status) {
          case "accepted":
            this.props.onSuccess();
            break;
          case "rejected":
            this.props.onError();
            break;
          default:
            this.props.onError();
        }
      })
      .catch(err => err.response.json().then(this.handleRegistrationError));
  };

  handleRegistrationError = err => {
    if (err && err.code === 404) {
      this.props.onError(messages.registrationTimedout);
    } else {
      this.props.onError(err);
    }
  };

  render = () => {
    const { loading, payload } = this.state;

    if (loading) {
      return <LoginLoading />;
    }

    const { questions, required: requiredAnwersCount } = payload;
    return (
      <AnswerSecurityQuestions
        requiredAnwersCount={requiredAnwersCount}
        questions={questions}
        onComplete={this.onSubmitOtp}
      />
    );
  };
}

RegisterQuestions.propTypes = {
  mfaApiToken: PropTypes.string.isRequired,
  factorId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  trackingId: PropTypes.string.isRequired,
  defaultLanguage: PropTypes.string.isRequired
};

export default RegisterQuestions;
