const yn = require("yn");
const isDevelopment =
  process.env.REACT_APP_ENVIRONMENT !== "production" &&
  process.env.REACT_APP_ENVIRONMENT !== "shadow";

let serverUrl = process.env.REACT_APP_SERVER_URL || "";

if (isDevelopment && !yn(process.env.REACT_APP_DEV_HOST)) {
  serverUrl = `http://${window.location.hostname}:6363`;
}

export const STATUS_EXPIRED_PASSWORD = "password_expired";
export const ADMIN_USER_TYPE = "admin";
export const SERVER_URL = serverUrl;
export const UNAUTHORIZED_CODE = 401;
export const UNPROCESSABLE_ENTITY_CODE = 422;
export const LOGIN_URL = "/login";
export const PASSWORD_CHANGE_URL = "/password";
export const LEGACY_SQ_EDIT_URL = "/security_question/answers/edit";

export const ADMIN = "admin";
export const SANDBOX = "sandbox";
export const SUPERADMIN = "superadmin";

export const BRANDING_BASE_URL = process.env.REACT_APP_BRANDING_BASE_URL || "";
export const imageStorage = process.env.IMAGE_STORAGE || "LOCAL";
export const bucketName =
  process.env.S3_BUCKET_NAME || "onelogin-assets-shadow";
export const region = process.env.S3_REGION || "us-east-1";
export const s3StoragePath =
  process.env.S3_STORAGE_PATH || "images/web_profile/icons";
