import localStorage from "../storage/localStorage";

export default ({ email, dataVersion }) => {
  const { saveDataCache } = localStorage({ email, dataVersion });

  if (!email && !dataVersion) {
    return store => next => action => {
      return next(action);
    };
  }

  return store => next => action => {
    const { toSave, ...restOfAction } = action;
    if (toSave) {
      saveDataCache(toSave);
      return next(restOfAction);
    }
    return next(action);
  };
};
