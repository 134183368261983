import React, { useEffect } from "react";
import styled from "styled-components";
import { Button } from "@onelogin/react-components";
import { useIntl } from "react-intl";
// this component houses the OneTrust cookie settings button.
// any potential future styling of this button should go here

const StyledButton = styled(Button)`
  // !important tags are necessary to override OneTrust button styling
  color: rgb(88, 89, 89) !important;
  border: 1px solid rgb(212, 214, 214) !important;
  height: 38px !important;
  padding: 0px 16px !important;
  font-size: 14px !important;
  line-height: 16px !important;

  &:hover {
    color: rgb(8, 145, 191) !important;
    background-color: rgba(0, 0, 0, 0.05) !important;
  }
`;

const CookieSettingsButton = () => {
  const intl = useIntl();
  const OneTrust = window?.OneTrust;

  useEffect(() => {
    if (window?.OneTrust?.initializeCookiePolicyHtml) {
      window.OneTrust.initializeCookiePolicyHtml();
    }
  }, []);

  /**
   * This hook is required since the Cookies button's label is updated by the onetrust cdn after
   * the CDN is loaded, so we are ensuring here to update the label in the language selected by
   * the user.
   */
  useEffect(() => {
    const customizeOneTrustButton = () => {
      const button = document.querySelector(".ot-sdk-show-settings");
      if (button && window?.OneTrust) {
        // Replace the button label with custom label
        button.textContent = intl.formatMessage({
          id: "manageCookies",
          defaultMessage: "Manage Cookies"
        });
      }
    };
    // Call the function to customize the button label
    customizeOneTrustButton();
  }, [OneTrust, intl]);

  return (
    <StyledButton
      id="ot-sdk-btn"
      look="regular"
      className="ot-sdk-show-settings"
    >
      Cookie Settings
    </StyledButton>
  );
};

export default CookieSettingsButton;
