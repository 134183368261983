import React from "react";
import styled from "styled-components";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Menu from "./Menu";

import ProfileScreen from "./screens/Profile/ProfileScreen";
import ChangePasswordScreen from "./screens/ChangePassword/ChangePasswordScreen";
import AuthenticationScreen from "./screens/Authentication/AuthenticationScreen";
import BrowserExtensionScreen from "./screens/BrowserExtension/BrowserExtensionScreen";
import OneLoginDesktopScreen from "./screens/OneLoginDesktop/OneLoginDesktopScreen";
import RecentActivityScreen from "./screens/RecentActivity/RecentActivityScreen";
import TrustedBrowsersScreen from "./screens/TrustedBrowsers/TrustedBrowsersScreen";

import ResponsiveBlock from "./ResponsiveBlock";
import ScreenWrapper from "./screens/ScreenWrapper";

import { PUBLIC_URL } from "../constants/Env";
import { ROOT_MENU_ITEM_ID, RECENT_ACTIVITY_PATH } from "../constants/Menu";

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MainContentWrapper = styled.div`
  background-color: #f7f9fa;
  overflow-y: auto;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

const MainContent = ({ children }) => (
  <MainContentWrapper>
    <ResponsiveBlock horizontal noPaddings fillHeight maxHeight>
      {children}
    </ResponsiveBlock>
  </MainContentWrapper>
);

const filterRoutes = menuData => {
  const menuPaths = menuData
    .filter(item => item.id !== ROOT_MENU_ITEM_ID)
    .map(item => item.linkPath);

  const nonRootRoutes = [
    { href: `${PUBLIC_URL}/password`, component: ChangePasswordScreen },

    { href: `${PUBLIC_URL}/mfa`, component: AuthenticationScreen },
    { href: `${PUBLIC_URL}/mfa/add`, component: AuthenticationScreen },
    { href: `${PUBLIC_URL}/mfa/:id`, component: AuthenticationScreen },
    { href: `${PUBLIC_URL}/mfa/:id/remove`, component: AuthenticationScreen },

    {
      href: `${PUBLIC_URL}/browser_extension`,
      component: BrowserExtensionScreen
    },

    { href: `${PUBLIC_URL}/desktop`, component: OneLoginDesktopScreen },
    { href: `${PUBLIC_URL}/desktop/:id`, component: OneLoginDesktopScreen },

    {
      href: `${PUBLIC_URL}/${RECENT_ACTIVITY_PATH}`,
      component: RecentActivityScreen
    },
    {
      href: `${PUBLIC_URL}/${RECENT_ACTIVITY_PATH}/filter/:name`,
      component: RecentActivityScreen
    },
    {
      href: `${PUBLIC_URL}/${RECENT_ACTIVITY_PATH}/:id`,
      component: RecentActivityScreen
    },

    {
      href: `${PUBLIC_URL}/trusted_browsers`,
      component: TrustedBrowsersScreen
    },
    {
      href: `${PUBLIC_URL}/trusted_browsers/:id`,
      component: TrustedBrowsersScreen
    },
    {
      href: `${PUBLIC_URL}/trusted_browsers/:id/remove`,
      component: TrustedBrowsersScreen
    }
  ].filter(route => {
    return menuPaths.some(menuItem => {
      const value = route.href.startsWith(menuItem);
      return value;
    });
  });

  const routes = [
    // Ensuring the root route is never filtered for whatever reason
    { href: `${PUBLIC_URL}`, component: ProfileScreen },
    { href: `${PUBLIC_URL}/language/:locale`, component: ProfileScreen },
    { href: `${PUBLIC_URL}/image`, component: ProfileScreen },
    {
      href: `${PUBLIC_URL}/password/redirect`,
      component: ChangePasswordScreen
    },
    ...nonRootRoutes
  ];

  return routes;
};

const ProfileContent = ({ filteredRoutes }) => {
  return (
    <ProfileWrapper>
      <MainContent>
        <Menu />
        <ScreenWrapper>
          <Switch>
            {filteredRoutes.map(route => (
              <Route
                exact
                path={route.href}
                key={route.href}
                component={route.component}
              />
            ))}
            <Redirect path={`${PUBLIC_URL}*`} to={`${PUBLIC_URL}`} />
          </Switch>
        </ScreenWrapper>
      </MainContent>
    </ProfileWrapper>
  );
};

const mapStateToProps = state => ({
  filteredRoutes: filterRoutes(state.menu.menuItemsData)
});

ProfileContent.propTypes = {
  filteredRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      component: PropTypes.func
    })
  ).isRequired
};

export default connect(mapStateToProps)(ProfileContent);
