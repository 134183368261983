import React, { Component } from "react";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import { FormattedMessage, defineMessages } from "react-intl";

import {
  EnterRegistrationCode,
  ContactAdmin,
  LoginLoading,
  EnterPhoneNumber
} from "@onelogin/react-components";
import getMfaApi from "../../../../api/mfaService";
import maskPhoneNumber from "../register/mfa/utils/maskPhoneNumber";

const messages = defineMessages({
  invalidCode: "Invalid code",
  registrationTimedout: "Registration context timed out",
  codeResent: "Code has been sent to your phone."
});

const getInitialState = () => ({
  loading: false,
  allowEdit: false,
  enteredNumber: undefined,
  displayNumber: undefined
});

class RegisterSMS extends Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();

    this.mfaService = getMfaApi(
      this.props.trackingId,
      this.props.defaultLanguage
    );
    this.registrationId = undefined;
  }

  componentDidMount = () => {
    this.setState({
      loading: true
    });
    this.initRegistration();
  };

  initRegistration = () => {
    this.mfaService
      .initRegistration(this.props.mfaApiToken, this.props.factorId)
      .then(({ id, payload }) => {
        this.registrationId = id;
        this.setState({
          displayNumber:
            payload.phone_number || payload.sms_number || undefined,
          allowEdit: payload.allow_edit,
          loading: false
        });
      })
      .catch(err => err.response.json().then(this.props.onError));
  };

  resendCode = (phoneNumber = undefined) => {
    if (phoneNumber) {
      this.setState({
        enteredNumber: phoneNumber,
        displayNumber: maskPhoneNumber(phoneNumber)
      });
    }

    let payload = phoneNumber
      ? JSON.stringify({ sms_number: phoneNumber, phone_number: phoneNumber })
      : "";

    this.mfaService
      .resendRegistrationOtp(
        this.props.mfaApiToken,
        this.registrationId,
        payload
      )
      .then(() => {
        this.setState({
          loading: false
        });
        this.props.showMfaNotification(messages.codeResent);
      })
      .catch(err => err.response.json().then(this.handleRegistrationError));
  };

  onTryAgain = () => {
    this.setState({
      loading: true
    });

    if (this.state.allowEdit) {
      this.setState({
        loading: false,
        displayNumber: undefined
      });
    } else {
      this.resendCode();
    }
  };

  onSubmitSMS = phoneNumber => {
    this.setState({
      loading: true
    });
    this.resendCode(phoneNumber);
  };

  handleRegistrationError = err => {
    if (err && err.code === 404) {
      this.setState(getInitialState());
      this.props.onError(messages.registrationTimedout);
    } else {
      this.props.onError(err);
    }
  };

  onSubmitOtp = otp => {
    this.setState({
      loading: true
    });
    const { mfaApiToken } = this.props;
    this.mfaService
      .submitRegistrationOtp(mfaApiToken, this.registrationId, otp)
      .then(() =>
        this.mfaService.getRegistration(mfaApiToken, this.registrationId)
      )
      .then(body => {
        switch (body.status) {
          case "accepted":
            this.props.onSuccess();
            break;
          case "rejected":
            this.props.onError();
            break;
          default:
            this.props.onError(messages.invalidCode);
            this.setState({
              loading: false
            });
        }
      })
      .catch(err => err.response.json().then(this.handleRegistrationError));
  };

  trimAndSubmitSMS = smsNumber => {
    smsNumber = smsNumber.trim().replace(/[{()\-\s}]/g, "");
    this.onSubmitSMS(smsNumber);
  };

  contactAdmin = () => !this.state.allowEdit && isNil(this.state.displayNumber);

  enterSmsNumber = () =>
    this.state.allowEdit && isNil(this.state.displayNumber);

  render = () => {
    if (this.state.loading) {
      return <LoginLoading />;
    }

    if (this.contactAdmin()) {
      return (
        <ContactAdmin
          formattedMessage={
            <FormattedMessage
              defaultMessage="Contact your admin to set up a phone number for your SMS OTP."
              id="contactAdminForSMS"
            />
          }
          onContinue={this.props.onClose}
        />
      );
    }

    if (this.enterSmsNumber()) {
      return (
        <EnterPhoneNumber
          onSubmitSMS={this.trimAndSubmitSMS}
          phoneNumber={this.state.enteredNumber}
        />
      );
    }

    return (
      <EnterRegistrationCode
        onSubmit={this.onSubmitOtp}
        contactInfo={this.state.displayNumber}
        onTryAgain={this.onTryAgain}
      />
    );
  };
}

RegisterSMS.propTypes = {
  mfaApiToken: PropTypes.string.isRequired,
  factorId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  showMfaNotification: PropTypes.func.isRequired,
  trackingId: PropTypes.string.isRequired,
  defaultLanguage: PropTypes.string.isRequired
};

export default RegisterSMS;
