import { ProjectRootProvider } from "@onelogin/react-components";
import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl-redux";
import { createGlobalStyle } from "styled-components";

import App from "./components/App";

const GlobalStyle = createGlobalStyle`
  body,
  html {
    box-sizing: border-box;
  }

  *, :after, :before {
    box-sizing: inherit;
  }
`;
export const renderProfile = ({ store, airbrake }) => {
  ReactDOM.render(
    <>
      <ProjectRootProvider value="profile">
        <GlobalStyle />
        <IntlProvider store={store}>
          <App store={store} airbrake={airbrake} />
        </IntlProvider>
      </ProjectRootProvider>
    </>,
    document.getElementById("root")
  );
};
