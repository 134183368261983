import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Moment from "react-moment";
import moment from "moment";
import "moment-timezone"; // allow moment to use timezone, which is used for testing purpose only

/**
 * As titled, the MomentWrapper wraps the <Moment /> component provided by the react-moment library,
 * which itself wraps the functionality provided by the moment library. All this component ensures is
 * that we can control what error message is shown when a null or invalid datetime string is passed
 * in. Treat this component as if it were the react-moment component, with all the same props.
 */

// the datetime that comes from core-api should comply with ISO8601 format: YYYY-MM-DDTHH:mm:ssZ
const MomentWrapper = ({ children, locale, ...props }) =>
  moment(children, moment.ISO_8601, locale).isValid() ? (
    <Moment parse={moment.ISO_8601} locale={locale} {...props}>
      {children}
    </Moment>
  ) : (
    <FormattedMessage id="unavailableTime" defaultMessage="Unavailable" />
  );

MomentWrapper.propTypes = {
  children: PropTypes.string,
  locale: PropTypes.string
};

export default MomentWrapper;
