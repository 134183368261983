import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { find, propEq, isNil, drop } from "ramda";
import { parse } from "querystring";
import { defineMessages } from "react-intl";
import PropTypes from "prop-types";

import { Loading } from "@onelogin/react-components";

import { fetchAvailableFactors } from "../../../actions/mfaActions";
import { fatalError } from "../../../actions/fatalErrorActions";
import getMfaService from "../../../api/mfaService";
import { OLSQ } from "../../../constants/OtpFactorTypes";
import { LEGACY_SQ_EDIT_URL } from "../../../constants/Api";
import FormRequest from "../../FormRequest";
import SecurityQuestionsPage from "./SecurityQuestionsPage";

const defaultReturn = {
  uri: `${window.location.origin}/portal`,
  method: "get",
  params: {}
};

const messages = defineMessages({
  failedToRegisterAnswers: {
    id: "failedToRegisterAnswers",
    defaultMessage:
      "We couldn’t register your security question answers at this time.{break}You can try using the <link>older page</link>.",
    values: {
      link: (...chunks) => <a href={LEGACY_SQ_EDIT_URL}>{chunks}</a>,
      break: <br />
    }
  },
  failedToRetrieveQuestions: {
    id: "failedToRetrieveQuestions",
    defaultMessage: "We couldn't retrieve your security questions at this time."
  }
});

const SecurityQuestionsContent = ({
  loading,
  factors,
  trackingId,
  defaultLanguage,
  mfaToken,
  getAvailableFactors,
  displayError
}) => {
  const [jwt, setJwt] = useState(null);
  const [sqConfig, setSqConfig] = useState(null);
  const [regId, setRegId] = useState(null);
  const [retrieving, setRetrieving] = useState(false);
  const [submitting, setSubmitting] = useState(null);
  const [redirecting, setRedirecting] = useState(false);
  const mfaService = getMfaService(trackingId, defaultLanguage);

  useEffect(() => {
    getAvailableFactors();
  }, [getAvailableFactors]);

  useEffect(() => {
    // Only want to make the mfa-service call once
    if (isNil(factors) || isNil(mfaToken) || retrieving) {
      return;
    }

    setRetrieving(true);

    (async () => {
      const sqFactor = find(propEq("typeId", OLSQ), factors);
      if (sqFactor === undefined) {
        // TODO: Remove this as soon as:
        // 1. All legacy login accounts have been migrated to login2
        // 2. Those accounts have had the onetime_script:migrate_affected_policies_for_forced_security_question rake task
        // run for them
        // This covers an edge case which we don't want to support anymore, as the "/security_question/answers/edit" page
        // is legacy.
        window.location = "/security_question/answers/edit";
      }

      const { jwt } = await mfaService.getToken(mfaToken);
      const { id, payload } = await mfaService.initRegistration(
        jwt,
        sqFactor.id
      );
      setJwt(jwt);
      setSqConfig(payload);
      setRegId(id);
    })();
  }, [factors, retrieving, mfaService, mfaToken, displayError]);

  const onSubmit = async answers => {
    setSubmitting(true);
    await mfaService
      .submitRegistrationOtp(jwt, regId, { answers })
      .then(() => setRedirecting(true))
      .catch(() => displayError(messages.failedToRegisterAnswers));
  };

  if (redirecting) {
    const hashParams = parse(drop(1, window.location.hash));
    const returnValues = isNil(hashParams.return)
      ? defaultReturn
      : JSON.parse(hashParams.return);
    const { uri, method, params } = returnValues;

    return (
      <FormRequest
        url={uri}
        method={method}
        params={params}
        trackingId={trackingId}
      />
    );
  }

  if (loading || sqConfig === null || submitting) {
    return <Loading />;
  }

  return (
    <SecurityQuestionsPage
      onSubmit={onSubmit}
      questions={sqConfig.questions}
      required={sqConfig.required}
    />
  );
};

const mapStateToProps = state => ({
  factors: state.mfa.factors,
  trackingId: state.user.trackingId,
  defaultLanguage: state.user.currentLanguageLocale,
  mfaToken: state.mfa.mfaToken,
  loading: state.user.loading || !state.branding.loaded || !state.account.loaded
});

const mapDispatchToProps = dispatch => ({
  getAvailableFactors: () => dispatch(fetchAvailableFactors()),
  displayError: message => dispatch(fatalError(message))
});

const factorShape = PropTypes.shape({
  customIconUrl: PropTypes.string,
  id: PropTypes.number.isRequired,
  isAppFactor: PropTypes.bool,
  isLoginFactor: PropTypes.bool.isRequired,
  isPasswordResetFactor: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  typeId: PropTypes.number.isRequired
});

SecurityQuestionsContent.propTypes = {
  defaultLanguage: PropTypes.string,
  displayError: PropTypes.func.isRequired,
  factors: PropTypes.arrayOf(factorShape),
  getAvailableFactors: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  mfaToken: PropTypes.string,
  trackingId: PropTypes.string
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurityQuestionsContent);
