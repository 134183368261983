// https://en.wikipedia.org/wiki/Timeline_of_web_browsers

export const MIN_SUPPORTED_BROWSERS = {
  chrome: ">=54",
  firefox: ">=50",
  safari: ">=10.1",
  ie: ">=11",
  edge: ">=39.14901"
};

export const HTML5_COPY_SUPPORTED_BROWSERS = {
  ie: ">=9",
  edge: ">=12",
  firefox: ">=41",
  chrome: ">=42",
  safari: ">=10"
};

export const DEPRECATED_BROWSERS = {
  ie: "<=10"
};

// this section need to be done in the react-component.
export const SMALL_MODAL_SIZE = 376;
export const MOBILE_VIEW_LIMIT = 480;
export const NARROW_VIEW_LIMIT = 652;
export const MID_VIEW_LIMIT = 856;
export const WIDE_VIEW_LIMIT = 1060;

export const MENU_WIDTH = 264;
export const MAIN_CONTENT_PADDING = 32;

//This breaks things with a cdn
export const PUBLIC_URL = "/profile2";
export const LEGACY_URL = "/profile";
