import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { pick, keys } from "ramda";
import PropTypes from "prop-types";

import { Checkbox, Button, COLORS } from "@onelogin/react-components";
import ScreenHeader from "../ScreenHeader";
import ScreenContentWrapper from "../ScreenContentWrapper";
import FormFooter from "../../FormFooter";
import DownloadTile from "../../common/DownloadTile/DownloadTile";
import ScreenHeaderControl from "../ScreenHeaderControl";
import {
  updateUserProfile,
  setProfileFormData
} from "../../../actions/profileActions";
import { commonMessages } from "../../../translations/commonMessages";
import LogoSvg from "../../../svg/OneLogin-logo-symbol_only.svg"; //TODO: this should be part of RC probably

const formDataProps = ["appAutoDetect"];
export const SAVE_BUTTON_TEST_ID = "browser-extension-screen-save-button";
export const SHOW_ADD_APPS_CHECKBOX_ID = "show-add-app-setting";

const AddAppSettingCheckboxWrapper = styled.div`
  display: inline-block;
  margin-top: 7px;
`;

const AddAppSettingWrapper = styled.div`
  display: flex;
`;

const DetectedExtensionWrapper = styled.div`
  margin-top: 32px;
  background-color: ${COLORS.GRAY_COLORS.GRAY_96};
  border-radius: 4px;
  padding: 24px;
  display: flex;
  flex-direction: row;
`;

const Logo = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 8px;
`;

const ExtensionDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExtensionName = styled.div`
  font-size: 14px;
  color: ${COLORS.GRAY_COLORS.GRAY_35};
  font-weight: 500;
`;

const ExtensionVersion = styled.div`
  font-size: 12px;
  line-height: 1.17;
  letter-spacing: 0.2px;
  color: ${COLORS.GRAY_COLORS.GRAY_25};
`;

export class BrowserExtensionScreen extends Component {
  componentDidMount() {
    document.title = this.props.intl.formatMessage(
      {
        id: "browserExtnesionTitle",
        defaultMessage: "Browser Extensions | {brandName}"
      },
      { brandName: this.props.brandedPageTitle }
    );
  }

  showExtensionDetected = () => {
    //Extension name not translated by purpose;
    return (
      <DetectedExtensionWrapper>
        <Logo
          src={LogoSvg}
          alt={this.props.intl.formatMessage(commonMessages.oneloginLogo)}
        />
        <ExtensionDetails>
          <ExtensionName>
            OneLogin for {this.props.extension.detectedName}
          </ExtensionName>
          <ExtensionVersion>
            <FormattedMessage
              defaultMessage="Version {versionString} installed"
              id="extensionVersionInstalled"
              values={{ versionString: this.props.extension.version }}
            />
          </ExtensionVersion>
        </ExtensionDetails>
      </DetectedExtensionWrapper>
    );
  };

  showExtensionDownload = () => {
    const { isTouchDevice } = this.props.environment;
    const { extensionData } = this.props.extension;

    if (!isTouchDevice && extensionData) {
      return (
        <DownloadTile
          software={"extension"}
          downloadlink={extensionData.link}
          browser={extensionData.name}
        />
      );
    } else {
      return (
        <p>
          <FormattedMessage
            defaultMessage="We currently only support browser extensions on desktop browsers."
            id={"onlyDesktopBrowsersSupported"}
          />
        </p>
      );
    }
  };

  addAppCheckboxChanged = id => {
    this.props.setProfileFormData({ appAutoDetect: !this.props.appAutoDetect });
  };

  handleSubmit = () => {
    this.props.updateUserProfile(this.props.changedFormData);
  };

  render() {
    return (
      <>
        <ScreenHeaderControl>
          <ScreenHeader>
            <FormattedMessage {...commonMessages.browserExtension} />
          </ScreenHeader>
        </ScreenHeaderControl>
        <ScreenContentWrapper column>
          <AddAppSettingWrapper>
            <AddAppSettingCheckboxWrapper>
              <Checkbox
                id={SHOW_ADD_APPS_CHECKBOX_ID}
                onChange={this.addAppCheckboxChanged}
                value={"add-app-checkbox"}
                checked={this.props.appAutoDetect}
                disabled={this.props.isAssumed}
                label={
                  <FormattedMessage
                    defaultMessage="Show {addApp} button when I log in to new websites."
                    values={{ addApp: <b>Add App</b> }}
                    id="addApp"
                  />
                }
              />
            </AddAppSettingCheckboxWrapper>
          </AddAppSettingWrapper>
          {this.props.extension.detected
            ? this.showExtensionDetected()
            : this.showExtensionDownload()}
          <FormFooter>
            <Button
              size="large"
              type="submit"
              look="primary"
              data-testid={SAVE_BUTTON_TEST_ID}
              disabled={this.props.saving || !this.props.settingsChanged}
              onClick={this.handleSubmit}
            >
              <FormattedMessage {...commonMessages.save} />
            </Button>
          </FormFooter>
        </ScreenContentWrapper>
      </>
    );
  }
}

const mapStateToProps = state => {
  const changedFormData = pick(formDataProps, state.user.changedFormData || {});

  return {
    environment: state.environment,
    appAutoDetect: changedFormData.hasOwnProperty("appAutoDetect")
      ? changedFormData.appAutoDetect
      : state.user.appAutoDetect,
    isAssumed: state.user.isAssumed,
    brandedPageTitle: state.account.brandedPageTitle,
    extension: state.extension,
    changedFormData,
    settingsChanged: keys(changedFormData).length > 0,
    saving: state.user.saving
  };
};

const mapDispatchToProps = dispatch => ({
  updateUserProfile: userData => {
    dispatch(updateUserProfile(userData));
  },
  setProfileFormData: formData => {
    dispatch(setProfileFormData(formData));
  }
});

BrowserExtensionScreen.propTypes = {
  appAutoDetect: PropTypes.bool.isRequired,
  brandedPageTitle: PropTypes.string.isRequired,
  changedFormData: PropTypes.shape({}).isRequired,
  environment: PropTypes.shape({
    isTouchDevice: PropTypes.bool.isRequired,
  }).isRequired,
  extension: PropTypes.shape({
    detected: PropTypes.bool.isRequired,
    extensionData: PropTypes.shape({
      link: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  isAssumed: PropTypes.bool,
  saving: PropTypes.bool,
  setProfileFormData: PropTypes.func.isRequired,
  settingsChanged: PropTypes.bool.isRequired,
  updateUserProfile: PropTypes.func.isRequired
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BrowserExtensionScreen)
);
