import { find, equals, pathSatisfies } from "ramda";

export const getDesktopByIdSelector = (state, desktopId) => {
  if (!state.desktop.data || !state.desktop.data.devices) {
    return undefined;
  }

  return find(
    pathSatisfies(equals(desktopId), ["deviceDetails", "identifier"]),
    state.desktop.data.devices
  );
};
