import * as types from "../constants/ActionTypes";

export const initialState = {
  secureNotes: false,
  brand: {},
  loaded: false,
  localeSettings: {}
};

function account(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ACCOUNT_RECEIVED:
      const {
        name,
        secureNotes,
        brand,
        tabs,
        companyTabName,
        brandedPageTitle,
        localeSettings,
        cookieManagementEnabled
      } = action.data;
      return {
        ...state,
        secureNotes,
        helpEnabled: brand.custom_support_enabled,
        helpInstruction: brand.support_instructions,
        name,
        tabs,
        companyTabName,
        brandedPageTitle: brandedPageTitle || "OneLogin",
        loaded: true,
        localeEnabled: localeSettings && localeSettings.enabled,
        locales: localeSettings && localeSettings.options,
        cookieManagementEnabled
      };

    case types.FETCH_ACCOUNT_ERROR:
      return {
        ...state,
        loaded: true
      };

    default:
      return state;
  }
}

export default account;
