import React, { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";
import { DuoFrame, LoginLoading } from "@onelogin/react-components";
import { RedirectionNotice } from "../../../RedirectionNotice";

import getMfaApi from "../../../../api/mfaService";

const messages = defineMessages({
  registrationTimedout: "Registration context timed out",
  registrationRejected: "Registration unsuccessful, please try again."
});

class RegisterDuo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      payload: undefined
    };

    this.mfaService = getMfaApi(
      this.props.trackingId,
      this.props.defaultLanguage
    );
    this.registrationId = undefined;
  }

  componentDidMount = () => {
    this.initRegistration();
  };

  initRegistration = () => {
    this.mfaService
      .initRegistration(this.props.mfaApiToken, this.props.factorId)
      .then(({ id, payload }) => {
        this.registrationId = id;
        this.setState({
          loading: false,
          payload
        });
      })
      .catch(err => err.response.json().then(this.props.onError));
  };

  onSubmitOtp = otp => {
    this.setState({
      loading: true
    });

    const { mfaApiToken } = this.props;
    this.mfaService
      .submitRegistrationOtp(mfaApiToken, this.registrationId, otp)
      .then(() =>
        this.mfaService.getRegistration(mfaApiToken, this.registrationId)
      )
      .then(body => {
        switch (body.status) {
          case "accepted":
            this.props.onSuccess();
            break;
          case "rejected":
            this.props.onError();
            break;
          default:
            this.props.onError();
        }
      })
      .catch(err => err.response.json().then(this.handleRegistrationError));
  };

  handleRegistrationError = err => {
    if (err && err.code === 404) {
      this.props.onError(messages.registrationTimedout);
    } else {
      this.props.onError(err);
    }
  };

  render = () => {
    const { loading, payload } = this.state;

    if (loading) {
      return <LoginLoading />;
    }

    const {
      api_hostname: apiHost,
      sig_request: sigRequest,
      universal_prompt: isUniversalPrompt,
      redirect_url: redirectUrl,
      error
    } = payload;

    if (isUniversalPrompt) {
      if (error || !redirectUrl) {
        this.props.onError(messages.registrationRejected);
        return <LoginLoading />;
      }

      window.location.href = redirectUrl;
      return <RedirectionNotice />;
    }

    return (
      <DuoFrame
        sigRequest={sigRequest}
        host={apiHost}
        callback={this.onSubmitOtp}
      />
    );
  };
}

RegisterDuo.propTypes = {
  mfaApiToken: PropTypes.string.isRequired,
  factorId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  trackingId: PropTypes.string.isRequired,
  defaultLanguage: PropTypes.string.isRequired
};

export default RegisterDuo;
