import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import { Button } from "@onelogin/react-components";

import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import ScreenContentWrapper from "../ScreenContentWrapper";
import { isEmpty } from "ramda";

import { MOBILE_VIEW_LIMIT, PUBLIC_URL } from "../../../constants/Env";
import {
  errorTypes,
  getErrorMessage,
  fetchMfaToken,
  fetchDevices,
  deleteDevice,
  updateDefaultDevice,
  unsetDefaultDevice,
  renameAuthenticationFactor,
  deleteDeviceAfterNewFactor,
  deletePrimaryDevice
} from "../../../actions/mfaActions";

import { notifyError } from "../../../actions/notificationsActions";

import { getDeviceByIdSelector } from "../../../selectors/mfaSelector";
import MfaDeviceTable from "./MfaDeviceTable";
import MfaRegistrationModal from "./MfaRegistrationModal";
import RemoveWarning from "./RemoveWarning";
import DeviceDetailsDialog from "./DeviceDetailsDialog";

const LOGINS_ONLY = "logins";

const ButtonWrapper = styled.div`
  margin-left: 32px;
  margin-bottom: 12px;

  @media screen and (max-width: ${MOBILE_VIEW_LIMIT}px) {
    margin-left: 20px;
  }
`;

class AuthenticationFactors extends Component {
  componentDidMount() {
    this.props.getDevices();
    document.title = this.props.intl.formatMessage(
      {
        id: "securityFactorsTitle",
        defaultMessage: "Security Factors | {brandName}"
      },
      { brandName: this.props.brandedPageTitle }
    );
  }

  onRemoveFactor = () => {
    const { selectedDevice } = this.props;
    if (!this.props.isAssumed && selectedDevice) {
      this.props.deleteDevice(selectedDevice.id, selectedDevice.factorName);
    }
  };

  navigateToMfa = () => {
    this.props.history.push(`${PUBLIC_URL}/mfa`);
  };

  onClose = () => {
    this.navigateToMfa();
  };

  onSetPrimary = (id, factorName) => {
    if (!this.props.isAssumed) {
      this.props.updatePrimary(id, factorName);
    }
  };

  onUnsetPrimary = (id, factorName) => {
    if (!this.props.isAssumed) {
      this.props.unsetPrimary(id, factorName);
    }
  }

  onAddFactor = () => {
    this.gotoAddFactor();
  };

  onTokenError = () => {
    this.navigateToMfa();
    this.props.mfaTokenError();
  };

  renameFactor = (id, newName) => {
    if (!this.props.isAssumed) {
      this.props.renameFactor(id, newName);
    }
  };

  gotoAddFactor = ({ onlyLogins } = {}) => {
    this.props.history.push(
      `${PUBLIC_URL}/mfa/add${onlyLogins ? `#${LOGINS_ONLY}` : ""}`
    ); //TODO: navigation actions?
  };

  removeDevice = id => {
    this.props.history.push(`${PUBLIC_URL}/mfa/${id}/remove`); //TODO: navigation actions?
  };

  onAddNewFactor = () => {
    this.props.deleteDeviceAfterNewFactor(this.props.selectedDevice.id);
    this.gotoAddFactor({ onlyLogins: true });
  };

  openDetails = id => {
    this.props.history.push(`${PUBLIC_URL}/mfa/${id}`); //TODO: navigation actions?
  };

  onSuccess = () => {
    this.removeDevice(this.props.removeIdAfterNewFactor);
  };

  onSecondaryFactorSelect = id => {
    const { selectedDevice, isAssumed } = this.props;

    if (isAssumed) {
      return;
    }

    if (!isEmpty(selectedDevice)) {
      const newPrimaryDevice = this.props.devices.find(
        device => device.id === id
      );

      this.props.deletePrimaryDevice(
        id,
        newPrimaryDevice.factorName,
        this.props.selectedDevice.id,
        this.props.selectedDevice.factorName
      );
    }

    this.navigateToMfa();
  };

  render() {
    const {
      isAssumed,
      devices,
      selectedDevice,
      deviceRemoveSuccess,
      setPrimarySuccess,
      unsetPrimarySuccess,
      noMfaTokenLoaded,
      devicesLoaded,
      intl,
      locale,
      location,
      canManageDevices
    } = this.props;

    if (
      location.pathname !== `${PUBLIC_URL}/mfa` &&
      (deviceRemoveSuccess || setPrimarySuccess || noMfaTokenLoaded || unsetPrimarySuccess)
    ) {
      //TODO: navigation actions/routes
      return <Redirect push to={`${PUBLIC_URL}/mfa`} />;
    }

    return (
      <>
        {canManageDevices && (
          <ButtonWrapper>
            <Button
              look="regular"
              data-testid="add-factor-button"
              onClick={this.onAddFactor}
              disabled={isAssumed}
            >
              <FormattedMessage defaultMessage="Add Factor" id="addFactor" />
            </Button>
          </ButtonWrapper>
        )}
        {devicesLoaded && (
          <ScreenContentWrapper column isMobileFullWidth>
            <MfaDeviceTable
              devices={devices}
              onSetPrimary={this.onSetPrimary}
              onUnsetPrimary={this.onUnsetPrimary}
              renameFactor={this.renameFactor}
              removeFactor={this.removeDevice}
              showDetails={this.openDetails}
              locale={locale}
              isAssumed={isAssumed}
              canManageDevices={canManageDevices}
            />
          </ScreenContentWrapper>
        )}

        <Switch>
          <Route exact path={`${PUBLIC_URL}/mfa/add`}>
            <MfaRegistrationModal
              intl={intl}
              onClose={this.onClose}
              onSuccess={
                this.props.location.hash === `#${LOGINS_ONLY}`
                  ? this.onSuccess
                  : undefined
              }
              mfaTokenError={this.onTokenError}
              isLoginsOnly={this.props.location.hash === `#${LOGINS_ONLY}`}
            />
          </Route>
          <Route
            exact
            path={`${PUBLIC_URL}/mfa/:id`}
            render={() => (
              <DeviceDetailsDialog
                device={selectedDevice}
                onClose={this.onClose}
                locale={locale}
              />
            )}
          ></Route>
          <Route exact path={`${PUBLIC_URL}/mfa/:id/remove`}>
            {!isEmpty(selectedDevice) && (
              <RemoveWarning
                deviceToRemove={selectedDevice}
                devices={devices}
                onClose={this.onClose}
                onRemove={this.onRemoveFactor}
                onSecondaryFactorSelect={this.onSecondaryFactorSelect}
                onAddNewFactor={this.onAddNewFactor}
                isMfaRequired={this.props.isMfaRequired}
              />
            )}
          </Route>
        </Switch>
      </>
    );
  }
}

AuthenticationFactors.propTypes = {
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      factorTypeId: PropTypes.number.isRequired,
      factorName: PropTypes.string.isRequired,
      primary: PropTypes.bool.isRequired,
      createdAt: PropTypes.string.isRequired,
      behavior: PropTypes.string,
      lastUsed: PropTypes.string,
      details: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    })
  ),
  selectedDevice: PropTypes.object,
  devicesLoaded: PropTypes.bool,
  isAssumed: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => ({
  devices: state.mfa.devices,
  locale: state.user.currentLanguageLocale,
  selectedDevice: getDeviceByIdSelector(state, ownProps),
  deviceRemoveSuccess: state.mfa.deviceRemoveSuccess,
  setPrimarySuccess: state.mfa.setPrimarySuccess,
  unsetPrimarySuccess: state.mfa.unsetPrimarySuccess,
  removeIdAfterNewFactor: state.mfa.removeIdAfterNewFactor,
  isMfaRequired: state.user.isMfaRequired,
  noMfaTokenLoaded:
    state.mfa.mfaTokenLoading === false && state.mfa.mfaToken === undefined,
  devicesLoaded: state.mfa.devicesLoaded, //TODO: reducer should be updated to handle also failure states
  mfaToken: state.mfa.mfaToken,
  intl: ownProps.intl,
  isAssumed: state.user.isAssumed,
  brandedPageTitle: state.account.brandedPageTitle,
  trackingId: state.user.trackingId,
  canManageDevices: state.user.canManageDevices
});

const mapDispatchToProps = dispatch => ({
  fetchMfaToken: trackingId => dispatch(fetchMfaToken(trackingId)),
  getDevices: () => dispatch(fetchDevices()),
  deleteDevice: (id, factorName) => {
    dispatch(deleteDevice(id, factorName));
  },
  updatePrimary: (id, factorName) =>
    dispatch(updateDefaultDevice(id, factorName)),
  unsetPrimary: (id, factorName) =>
    dispatch(unsetDefaultDevice(id, factorName)),
  renameFactor: (id, newName) =>
    dispatch(renameAuthenticationFactor(id, newName)),
  mfaTokenError: () => {
    dispatch(
      notifyError({
        message: getErrorMessage(errorTypes.FETCH_FACTORS)
      })
    );
  },
  deletePrimaryDevice: (
    newPrimaryId,
    newPrimaryFactorName,
    deleteId,
    deleteFactorName
  ) => {
    dispatch(
      deletePrimaryDevice(
        newPrimaryId,
        newPrimaryFactorName,
        deleteId,
        deleteFactorName
      )
    );
  },
  deleteDeviceAfterNewFactor: id => {
    dispatch(deleteDeviceAfterNewFactor(id));
  }
});

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(AuthenticationFactors)
  )
);
