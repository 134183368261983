import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { TrustedDevice, UntrustedDevice } from "./OneLoginDesktopIcon";
import Moment from "../../MomentWrapper";

export const CompositeDeviceCell = props => {
  return (
    <>
      <span>{props.name}</span>
      <br />
      <span>{props.osInShort}</span>
    </>
  );
};

CompositeDeviceCell.propTypes = {
  name: PropTypes.string.isRequired,
  osInShort: PropTypes.string.isRequired
};

export const CompositeVersionCell = props => {
  return (
    <>
      <span>{`${props.nameInShort} ${props.type}`}</span>
      <br />
      <span>
        <FormattedMessage
          id="version"
          defaultMessage={`Version: {versionString}`}
          values={{ versionString: props.version }}
        />
      </span>
    </>
  );
};

CompositeVersionCell.propTypes = {
  version: PropTypes.string.isRequired
};

CompositeVersionCell.propTypes = {
  type: PropTypes.string.isRequired,
  nameInShort: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

export const CompositeTimeCell = props => {
  const { time, locale, tz } = props;
  return (
    <Moment fromNow locale={locale} tz={tz}>
      {time}
    </Moment>
  );
};

CompositeTimeCell.propTypes = {
  locale: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  tz: PropTypes.string
};

export const CompositeTrustedCell = props => {
  if (props.trustDevice) {
    return <TrustedDevice size="16px" />;
  } else {
    return <UntrustedDevice size="16px" />;
  }
};

CompositeTrustedCell.propTypes = {
  trustDevice: PropTypes.bool.isRequired
};
