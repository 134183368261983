import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import ScreenHeader from "../ScreenHeader";
import ProfileImage from "./ProfileImage";
import ProfileDetails from "./ProfileDetails";
import ProfileNameEmailComponent from "./ProfileNameEmail";
import ScreenContentWrapper from "../ScreenContentWrapper";
import ScreenHeaderControl from "../ScreenHeaderControl";
import { NARROW_VIEW_LIMIT } from "../../../constants/Env";
import styled from "styled-components";
import {
  uploadProfilePhoto,
  deleteProfilePhoto,
  setProfileImageData,
  notifyImageParseFail
} from "../../../actions/profileActions";

import PhotoCropDialog from "./PhotoCropDialog";
import { getCroppedImageBlob, getOrientedImageData } from "./imageManipulation";
import { commonMessages } from "../../../translations/commonMessages";
import { PUBLIC_URL } from "../../../constants/Env";

const ProfileWrapper = styled.div`
  -webkit-tap-highlight-color: transparent;
`;

const ProfileImageContainer = styled.div`
  display: flex;
  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    justify-content: center;
  }

  margin-bottom: 16px;
`;

const TryHorizontalContentBlock = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    flex-direction: column;
    margin-bottom: 40px;
  }
`;

const HiddenFileInput = styled.input.attrs({
  type: "file",
  accept: "image/*"
})`
  position: absolute;
  visibility: hidden;
  height: 0px;
`;

class ProfileScreen extends React.Component {
  resetState = () => {
    return {
      crop: { x: 0, y: 0 },
      zoom: 1,
      croppedAreaPixels: null
    };
  };

  state = this.resetState();

  photoSave = async () => {
    this.props.uploadProfilePhoto(
      await getCroppedImageBlob(
        this.props.imageToCrop,
        this.state.croppedAreaPixels
      )
    );
    this.imageUploadClose();
  };

  imageUploadClose = () => {
    this.setState(this.resetState());
    this.fileUploadRef.value = "";
    this.props.history.push(`${PUBLIC_URL}`);
  };

  setFileUploadRef = node => {
    this.fileUploadRef = node;
  };

  addImageClick = () => {
    if (!this.props.isAssumed) {
      this.fileUploadRef.click();
    }
  };

  removeImageClick = () => {
    if (!this.props.isAssumed) {
      this.props.deleteProfilePhoto();
    }
  };

  setProfileImageData = async event => {
    try {
      const imageData = await getOrientedImageData(event.target.files[0]);
      this.props.setProfileImageData(imageData);
      this.props.history.push(`${PUBLIC_URL}/image`);
    } catch (error) {
      this.props.notifyImageParseFail();
    }
  };

  render() {
    const {
      isTouchDevice,
      profilePicture,
      profilePictureUploadEnabled,
      imageToCrop,
      location
    } = this.props;

    return (
      <ProfileWrapper>
        <ScreenHeaderControl>
          <ScreenHeader>
            <FormattedMessage {...commonMessages.profile} />
          </ScreenHeader>
        </ScreenHeaderControl>
        <ScreenContentWrapper column>
          <TryHorizontalContentBlock>
            <ProfileImageContainer>
              <ProfileImage
                isTouchDevice={isTouchDevice}
                profileImageUrl={profilePicture}
                canBeChanged={profilePictureUploadEnabled}
                removeImageClick={this.removeImageClick}
                addImageClick={this.addImageClick}
              />
            </ProfileImageContainer>
            <ProfileNameEmailComponent />
          </TryHorizontalContentBlock>
          <ProfileDetails />

          <HiddenFileInput
            id="ProfileImageFile"
            ref={this.setFileUploadRef}
            onChange={this.setProfileImageData}
          />

          {imageToCrop && (
            <Switch>
              <Route exact path={`${PUBLIC_URL}/image`}>
                <PhotoCropDialog
                  crop={this.state.crop}
                  zoom={this.state.zoom}
                  croppedAreaPixels={this.state.croppedAreaPixels}
                  imageToCrop={imageToCrop}
                  onCropChange={crop => {
                    this.setState({ crop });
                  }}
                  onCropComplete={(croppedArea, croppedAreaPixels) =>
                    this.setState({ croppedAreaPixels })
                  }
                  onZoomChange={zoom => {
                    this.setState({ zoom });
                  }}
                  onClose={this.imageUploadClose}
                  onSave={this.photoSave}
                />
              </Route>
            </Switch>
          )}

          {/* when user reloads on `/profile2/image` or imageToCrop is empty (reading failed) page there's no image to crop, so redirect him back to home */}
          {!imageToCrop && location.pathname === `${PUBLIC_URL}/image` && (
            <Redirect to={`${PUBLIC_URL}`} />
          )}
        </ScreenContentWrapper>
      </ProfileWrapper>
    );
  }
}

const mapStateToProps = state => ({
  imageToCrop: state.user.imageData,
  profilePicture: state.user.profilePicture,
  isAssumed: state.user.isAssumed,
  isTouchDevice: state.environment.isTouchDevice,
  profilePictureUploadEnabled: state.user.profilePictureUploadEnabled
});

const mapDispatchToProps = dispatch => ({
  setProfileImageData: imageData => {
    dispatch(setProfileImageData(imageData));
  },
  uploadProfilePhoto: data => {
    dispatch(uploadProfilePhoto(data));
  },
  deleteProfilePhoto: () => {
    dispatch(deleteProfilePhoto());
  },
  notifyImageParseFail: () => {
    dispatch(notifyImageParseFail());
  }
});

const locationShape = PropTypes.shape({
  pathname: PropTypes.string.isRequired
});

ProfileScreen.propTypes = {
  deleteProfilePhoto: PropTypes.func.isRequired,
  imageToCrop: PropTypes.string,
  isAssumed: PropTypes.bool.isRequired,
  isTouchDevice: PropTypes.bool.isRequired,
  location: locationShape,
  notifyImageParseFail: PropTypes.func.isRequired,
  profilePicture: PropTypes.string.isRequired,
  profilePictureUploadEnabled: PropTypes.bool.isRequired,
  setProfileImageData: PropTypes.func.isRequired,
  uploadProfilePhoto: PropTypes.func.isRequired
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProfileScreen)
);
