import React from "react";
import PropTypes from "prop-types";

class AirbrakeProvider extends React.Component {
  getChildContext() {
    return {
      airbrake: this.props.airbrake
    };
  }

  render() {
    return this.props.children;
  }
}

AirbrakeProvider.childContextTypes = {
  airbrake: PropTypes.object
};

AirbrakeProvider.propTypes = {
  airbrake: PropTypes.object
};

export default AirbrakeProvider;
