import React, { forwardRef, useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { defineMessages, useIntl } from "react-intl";

import { Icon } from "@onelogin/react-components";

const messages = defineMessages({
  profileMenuToggle: {
    id: "profileMenuToggle",
    defaultMessage: "Open profile menu"
  }
});

const ToggleContent = styled.div`
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #e8eaeb;
  position: relative;
  font-size: 22px;
  background-color: #ffffff;
  box-sizing: border-box;
`;

const ChevronWrap = styled.button`
  margin: auto 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
`;

const DropdownToggle = forwardRef(
  ({ isOpen, children, onClick, shouldFocus, onKeyDown }, ref) => {
    const intl = useIntl();
    const chevronEl = useRef();

    useLayoutEffect(() => {
      if (shouldFocus) {
        chevronEl.current.focus();
      }
    }, [shouldFocus]);

    return (
      <ToggleContent ref={ref} onClick={onClick}>
        <ChevronWrap
          ref={chevronEl}
          tabIndex={0}
          aria-label={intl.formatMessage(messages.profileMenuToggle)}
          aria-expanded={isOpen}
          onKeyDown={onKeyDown}
        >
          <Icon
            size={"16px"}
            icon={isOpen ? "Chevron_up-16" : "Chevron_down-16"}
          />
        </ChevronWrap>
        {children}
      </ToggleContent>
    );
  }
);

DropdownToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  shouldFocus: PropTypes.bool,
  isOpen: PropTypes.bool
};

export default DropdownToggle;
