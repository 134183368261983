import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { NARROW_VIEW_LIMIT, MID_VIEW_LIMIT } from "../../../constants/Env";

const InfoContainer = styled.div`
  padding: 32px 0 32px 22px;
  max-width: 400px;

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    padding-left: 0;
  }

  @media screen and (max-width: ${MID_VIEW_LIMIT}px) {
    margin: 0 auto;
  }
`;

const ListItem = styled.li`
  padding-top: 3px;
`;

const SecurityQuestionsInfo = ({ required }) => (
  <InfoContainer>
    <FormattedMessage
      id="sqInfoHeader"
      defaultMessage="If there is a problem with your account, this information will make it easier for you to log back in. <b>Tips for your answers to the security questions:</b>"
      values={{
        b: chunks => <b>{chunks}</b>
      }}
    />

    <ul>
      <ListItem>
        <FormattedMessage
          defaultMessage="Do not use the same answer for multiple questions."
          id="sqTipSameAnswer"
        />
      </ListItem>
      <ListItem>
        <FormattedMessage
          defaultMessage="Do not use a password as an answer."
          id="sqTipNoUsePassword"
        />
      </ListItem>
      <ListItem>
        <FormattedMessage
          defaultMessage="Answer a minimum of {required} questions."
          id="sqTipMinimum"
          values={{ required }}
        />
      </ListItem>
      <ListItem>
        <FormattedMessage
          defaultMessage="Answers are not case-sensitive."
          id="sqTipNotCaseSensitive"
        />
      </ListItem>
      <ListItem>
        <FormattedMessage
          defaultMessage="Special characters such as @#&amp;*. will be ignored"
          id="sqTipSpecialChars"
        />
      </ListItem>
      <ListItem>
        <FormattedMessage
          defaultMessage="Do not share your questions and answers with anyone."
          id="sqTipDoNotShare"
        />
      </ListItem>
      <ListItem>
        <FormattedMessage
          defaultMessage="Blank answers will not be accepted"
          id="sqTipBlankAnswers"
        />
      </ListItem>
    </ul>
  </InfoContainer>
);

SecurityQuestionsInfo.propTypes = {
  required: PropTypes.number.isRequired
};

export default SecurityQuestionsInfo;
