import React from "react";
import { ContentBlock } from "@onelogin/react-components";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { COLORS } from "@onelogin/react-components";
import DownloadSvg from "../../../svg/download-16.svg";
import LogoSvg from "../../../svg/OneLogin-logo-symbol_only.svg";
import { commonMessages } from "../../../translations/commonMessages";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
`;

const DownloadLink = styled.a`
  border-radius: 2px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #e8eaeb;
  background-color: #ffffff;
  text-decoration: none;
  &:hover {
    border: 1px solid ${COLORS.GRAY_COLORS.GRAY_68};
  }
`;

const Content = styled.div`
  margin: 20px;
  display: flex;
`;

const Label = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${COLORS.GRAY_COLORS.GRAY_35};
  white-space: nowrap;
`;

const Logo = styled.img`
  width: 48px;
  height: 48px;
`;

const DownloadTextWrapper = styled.div`
  display: flex;
  padding-top: 8px;
  text-decoration: none;
`;

const DownloadIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const DownloadTitle = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00779e;
  margin-left: 8px;
`;

const LeftContent = styled.div`
  margin-left: 16px;
`;

const SoftwareTitle = props => {
  //titles not translated by purpose
  switch (props.software) {
    case "macDesktop":
      return "OneLogin Desktop for Mac";
    case "macProDesktop":
      return "OneLogin Desktop Pro for Mac";
    case "windowsDesktop":
      return "OneLogin Desktop for Windows";
    case "windowsProDesktop":
      return "OneLogin Desktop Pro for Windows";
    case "extension":
      return `OneLogin for ${props.browser}`;
    default:
      return null;
  }
};

const DownloadLinkTitle = props => {
  switch (props.software) {
    case "macDesktop":
    case "macProDesktop":
    case "windowsDesktop":
    case "windowsProDesktop":
      return (
        <FormattedMessage
          defaultMessage="Version {version}"
          values={{ version: props.version }}
          id={"version"}
        />
      );
    case "extension":
      return <FormattedMessage defaultMessage="Install" id={"install"} />;
    default:
      return null;
  }
};

const DownloadTile = props => {
  const intl = useIntl();
  return (
    <ContentBlock>
      <Wrapper>
        <DownloadLink href={props.downloadlink} target="_blank">
          <Content>
            <Logo
              src={LogoSvg}
              alt={intl.formatMessage(commonMessages.oneloginLogo)}
            />
            <LeftContent>
              <Label>
                <SoftwareTitle
                  software={props.software}
                  browser={props.browser}
                />
              </Label>
              <DownloadTextWrapper>
                <DownloadIcon src={DownloadSvg} alt="" />
                <DownloadTitle>
                  <DownloadLinkTitle
                    software={props.software}
                    browser={props.browser}
                    version={props.version}
                  />
                </DownloadTitle>
              </DownloadTextWrapper>
            </LeftContent>
          </Content>
        </DownloadLink>
      </Wrapper>
    </ContentBlock>
  );
};

export default DownloadTile;
