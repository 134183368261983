// import { initialState as featureFlagsInitialState } from "../reducers/featureFlagsReducer";

const getInitialState = () => {
  // if (!featureFlagsInitialState.persistStore) {
  //   return {};
  // }

  return {
    // intl: {
    //   defaultLocale: "en",
    //   locale: "en"
    // }
  };
};

export default getInitialState;
