import thunk from "redux-thunk";

import { fetchMe, fetchAccount } from "./actions/profileActions";
import { createReduxStore } from "./utils/store";
import dynamicMiddlewares from "redux-dynamic-middlewares";
import { setupExtensionDetection } from "./utils/extensionDetection";

import { renderProfile } from "./Profile";

export const initProfile = ({ isDevelopment, dataVersion, errorReporting }) => {
  const store = createReduxStore({
    isDevelopment,
    middlewares: [
      errorReporting.getAirbrakeMiddleware(),
      dynamicMiddlewares,
      thunk
    ]
  });

  //render profile as soon as possible - show loading spinner
  renderProfile({
    store,
    airbrake: errorReporting.getAirbrakeClient()
  });

  setupExtensionDetection({ store });

  //fetchMe ensures redirection in case of invalid session
  store.dispatch(fetchMe(dataVersion));
  store.dispatch(fetchAccount());
};
