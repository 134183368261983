import { find, propEq } from "ramda";

export const getTrustedBrowserByIdSelector = (browserId, state) => {
  const browserData = state.trustedBrowsers.browsers;

  if (!browserData) {
    return undefined;
  }

  return find(propEq("id", parseInt(browserId)), browserData);
};
