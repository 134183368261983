import React from "react";
import { FormattedMessage } from "react-intl";
import { PUBLIC_URL } from "../constants/Env";
import { commonMessages } from "../translations/commonMessages";

const userTabLinks = [
  {
    title: (
      <FormattedMessage id={"secureNotes"} defaultMessage="Secure Notes" />
    ),
    href: "/notes",
    visible: state =>
      state.account.secureNotes &&
      !(
        state.user.assumedByAdmin ||
        state.user.assumedBySuperadmin ||
        state.user.assumedBySandbox
      )
  },
  {
    title: <FormattedMessage id={"appPortal"} defaultMessage="App Portal" />,
    href: "/portal"
  },
  {
    title: <FormattedMessage id={"support"} defaultMessage="Support" />,
    href: `${PUBLIC_URL}/help`,
    visible: state => state.account.helpEnabled
  },
  {
    title: <FormattedMessage {...commonMessages.revertToAdmin} />,
    href: `/sessions/revert`,
    visible: state => state.user.assumedByAdmin
  },
  {
    title: <FormattedMessage {...commonMessages.revertToSuperadmin} />,
    href: `/admin/accounts/revert`,
    visible: state => state.user.assumedBySuperadmin
  },
  {
    title: <FormattedMessage {...commonMessages.revertToAdmin} />,
    href: `/sandboxes/revert`,
    visible: state => state.user.assumedBySandbox
  },
  {
    title: <FormattedMessage {...commonMessages.logOut} />,
    href: "/logout"
  }
];

const userTabLinksSelector = state =>
  userTabLinks
    .filter(link => (link.visible ? link.visible(state) : true))
    .map(({ title, href, divider }) => {
      return { title, href, divider };
    });

export default userTabLinksSelector;
