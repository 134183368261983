import styled from "styled-components";
import PropTypes from "prop-types";

import { NARROW_VIEW_LIMIT, MAIN_CONTENT_PADDING } from "../../constants/Env";

const ScreenContentWrapper = styled.div`
  display: flex;
  padding: 0 ${MAIN_CONTENT_PADDING}px;
  padding-bottom: 0;
  flex-direction: ${props => (props.column ? "column" : "row")};

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    padding-top: ${props => (props.isMobileFullWidth ? 0 : "24px")};
    padding-left: ${props => (props.isMobileFullWidth ? 0 : "20px")};
    padding-right: ${props => (props.isMobileFullWidth ? 0 : "20px")};
  }
`;

ScreenContentWrapper.propTypes = {
  column: PropTypes.bool,
  isMobileFullWidth: PropTypes.bool
};

export default ScreenContentWrapper;
