import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { NARROW_VIEW_LIMIT } from "../../../constants/Env";
import { COLORS } from "@onelogin/react-components";
import PropTypes from "prop-types";

const ProfileUserDetailsWrapper = styled.div`
  margin-left: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 144px);
  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    width: 100%;
    align-items: center;
    margin-left: unset;
  }
`;

const InfoTopLine = styled.p`
  font-size: 21px;
  color: #3f4040;
  margin: 0;

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    text-align: center;
  }
`;

const InfoMiddleLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  font-size: 14px;
  color: ${COLORS.GRAY_COLORS.GRAY_45};
  margin-top: 8px;
`;

const DividedPart = styled.span`
  margin-right: 8px;
  padding-right: 8px;
  border-right: 1px solid ${COLORS.GRAY_COLORS.GRAY_45};

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    display: none;
  }

  &:last-child {
    border: none;
    padding: 0;
    margin: 0;
  }
`;

const InfoBottomLine = styled.p`
  font-size: 14px;
  color: #3f4040;
  margin-top: 16px;
`;

const ProfileNameEmailComponent = ({ fullName, email, username, title }) => {
  return (
    <ProfileUserDetailsWrapper>
      <InfoTopLine>{fullName}</InfoTopLine>
      <InfoMiddleLine>
        <DividedPart>{email}</DividedPart>
        <DividedPart>{username}</DividedPart>
      </InfoMiddleLine>
      {title && <InfoBottomLine>{title}</InfoBottomLine>}
    </ProfileUserDetailsWrapper>
  );
};

const mapStateToProps = state => ({
  fullName: state.user.fullName,
  email: state.user.email,
  username: state.user.username,
  title: state.user.title
});

ProfileNameEmailComponent.propTypes = {
  email: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  title: PropTypes.string,
  username: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(ProfileNameEmailComponent);
