import { partition, sortBy, toLower } from "ramda";
import * as types from "../constants/ActionTypes";

const initialState = {
  factors: null,
  factorsLoaded: undefined,
  devices: null,
  devicesLoaded: undefined,
  deviceRemoveSuccess: undefined,
  setPrimarySuccess: undefined,
  unsetPrimarySuccess: undefined,
  mfaTokenLoading: undefined
};

const sortByPrimary = devices => {
  const [primary, nonPrimary] = partition(device => device.primary, devices);
  const [rest, inactive] = partition(device => device.isActive, nonPrimary);
  const inactiveSorted = sortBy(device => toLower(device.factorName), inactive);
  const restSorted = sortBy(device => toLower(device.factorName), rest);

  const sortedDevice = primary.concat(restSorted).concat(inactiveSorted);
  return sortedDevice;
};

function mfa(state = initialState, action) {
  switch (action.type) {
    //TODO: missing failure states handling for both factors and devices
    case types.RECEIVE_MFA_FACTORS:
      return {
        ...state,
        factors: action.factors,
        factorsLoaded: true
      };
    case types.RECEIVE_MFA_DEVICES:
      return {
        ...state,
        devices: sortByPrimary(action.devices),
        devicesLoaded: true
      };
    case types.FETCH_MFA_TOKEN_REQUEST:
      return {
        ...state,
        mfaToken: undefined,
        mfaTokenLoading: true
      };
    case types.FETCH_MFA_TOKEN_RECEIVED:
      return {
        ...state,
        mfaToken: action.token,
        mfaTokenLoading: false
      };
    case types.FETCH_MFA_TOKEN_FAILED:
      return {
        ...state,
        mfaToken: undefined,
        mfaTokenLoading: false
      };

    case types.UPDATE_DEFAULT_MFA_DEVICE_SUCCESS: {
      const newPrimaryId = action.id;
      const newDevices = state.devices.map(device => ({
        ...device,
        primary: device.id === newPrimaryId
      }));
      return {
        ...state,
        devices: sortByPrimary(newDevices),
        setPrimarySuccess: true
      };
    }
    case types.UNSET_DEFAULT_MFA_DEVICE_SUCCESS: {
      const newDevices = state.devices.map(device => ({
        ...device,
        primary: false
      }));
      return {
        ...state,
        devices: newDevices,
        unsetPrimarySuccess: true
      };
    }
    case types.UPDATE_DEFAULT_MFA_DEVICE_RESET: {
      return {
        ...state,
        setPrimarySuccess: undefined
      };
    }
    case types.UNSET_DEFAULT_MFA_DEVICE_RESET: {
      return {
        ...state,
        unsetPrimarySuccess: undefined
      };
    }
    case types.REMOVE_MFA_DEVICE_SUCCESS:
      const deletedId = action.id;
      const newDevices = state.devices.filter(
        device => device.id !== deletedId
      );
      return {
        ...state,
        devices: newDevices,
        deviceRemoveSuccess: true
      };
    case types.REMOVE_MFA_DEVICE_RESET:
      return {
        ...state,
        deviceRemoveSuccess: undefined
      };

    case types.UPDATED_DEVICE_NAME: {
      const newDevices = state.devices.map(device => {
        if (device.id === action.id) {
          device.factorName = action.newName;
          device.updatingFactorName = null;
        }
        return device;
      });
      return {
        ...state,
        devices: sortByPrimary(newDevices)
      };
    }
    case types.UPDATING_DEVICE_NAME: {
      const newDevices = state.devices.map(device => {
        if (device.id === action.id) {
          device.updatingFactorName = action.newName || "";
        }
        return device;
      });
      return {
        ...state,
        devices: sortByPrimary(newDevices)
      };
    }
    case types.UPDATE_DEVICE_NAME_FAILED: {
      const newDevices = state.devices.map(device => {
        if (device.id === action.id) {
          device.updatingFactorName = null;
        }
        return device;
      });
      return {
        ...state,
        devices: sortByPrimary(newDevices)
      };
    }
    case types.REMOVE_MFA_DEVICE_AFTER_NEW_FACTOR: {
      return {
        ...state,
        removeIdAfterNewFactor: action.id
      };
    }
    default:
      return state;
  }
}

export default mfa;
