export const Languages = [
  { locale: "cs-CZ", name: "Czech" },
  { locale: "da", name: "Dansk" },
  { locale: "de", name: "Deutsch" },
  { locale: "en", name: "English" },
  { locale: "es", name: "Español" },
  { locale: "fi", name: "Suomi" },
  { locale: "fr", name: "Français" },
  { locale: "hi-IN", name: "हिन्दी (भारत)" },
  { locale: "hu-HU", name: "Magyar (Magyarország)" },
  { locale: "it", name: "Italiano" },
  { locale: "ja", name: "日本語" },
  { locale: "ko", name: "한국어" },
  { locale: "mr-IN", name: "Marathi (India)" },
  { locale: "ms-MY", name: "Melayu" },
  { locale: "nb-NO", name: "Norsk" },
  { locale: "nl", name: "Nederlands" },
  { locale: "no", name: "Norsk" },
  { locale: "pl-PL", name: "Polski" },
  { locale: "pt", name: "Português" },
  { locale: "ru-RU", name: "Русский" },
  { locale: "sk", name: "Slovakian" },
  { locale: "sv", name: "Svenska" },
  { locale: "th", name: "Thai" },
  { locale: "tr-TR", name: "Türkçe" },
  { locale: "uk-UA", name: "Ukrainian (Ukraine)" },
  { locale: "vi-VN", name: "Tiếng Việt" },
  { locale: "zh", name: "中文" },
  { locale: "zh-TW", name: "簡體中文" },
  { locale: "lv", name: "Latvian" }
];
