import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import closeNotification from "../svg/close-notification.svg";
import styled from "styled-components";
import ResponsiveBlock from "./ResponsiveBlock";
import { commonMessages } from "../translations/commonMessages";

const NotificationContainer = styled.div`
  background-color: #016B91;
  min-height: 50px;
`;

const NotificationContent = styled.div`
  line-height: 1;
  display: flex;
  justify-content: space-between;
  color: white;
  width: 100%;
`;

const NotificationText = styled.div`
  align-self: center;
  font-size: 16px;
  padding: 17px 10px;
  text-align: center;
  width: calc(100% - 20px);
`;

const NotificationDismiss = styled.a`
  width: 20px;
  height: 20px;
  padding: 15px 0;
  cursor: pointer;
  display: block;
`;

export class AssumedUserNotificationBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClosed: false
    };
  }

  onClearNotificationClick = () => {
    this.setState({
      isClosed: true
    });
  };

  render() {
    const { intl } = this.props;
    if (this.state.isClosed) {
      return null;
    }
    return (
      <NotificationContainer data-testid="assumed-user-notification-banner">
        <ResponsiveBlock>
          <NotificationContent>
            <NotificationText>
              <FormattedMessage
                defaultMessage="You cannot edit personal information while assuming a user."
                id="assumedUserNotification"
              />
            </NotificationText>

            <NotificationDismiss>
              <img
                onClick={this.onClearNotificationClick}
                src={closeNotification}
                alt={intl.formatMessage(commonMessages.close)}
              />
            </NotificationDismiss>
          </NotificationContent>
        </ResponsiveBlock>
      </NotificationContainer>
    );
  }
}

export default injectIntl(AssumedUserNotificationBanner);
