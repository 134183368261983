import React, { Component } from "react";
import PropTypes from "prop-types";
import { find, propEq, isNil, isEmpty } from "ramda";
import { FormattedMessage } from "react-intl";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

import {
  FactorList,
  Button,
  DialogFooter,
  Icon,
  IconProvider as getIcon,
  ModalDialog,
  ContactAdmin,
  LoginLoading
} from "@onelogin/react-components";

import RegisterAuthenticator from "./register/RegisterAuthenticator";
import RegisterSMS from "./register/RegisterSMS";
import RegisterProtect from "./register/RegisterProtect";
import RegisterYubiKey from "./register/RegisterYubiKey";
import RegisterDuo from "./register/RegisterDuo";
import RegisterVip from "./register/RegisterVip";
import RegisterQuestions from "./register/RegisterQuestions";
import RegisterSecureId from "./register/RegisterSecureId";
import RegisterVoice from "./register/RegisterVoice";
import RegisterWebAuthn from "./register/RegisterWebAuthn";
import RegisterEmailOtp from "./register/RegisterEmailOtp";
import RegisterTrustedIdp from "./register/RegisterTrustedIdp";
import RegisterOath from "./register/RegisterOath";
import getMfaApi, { FACTOR_TYPES } from "../../../api/mfaService";

const ICON_IMAGE_SIZE = 24;
const ICON_MARGIN = 16;

const IconWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex: 0 0 ${ICON_IMAGE_SIZE + ICON_MARGIN}px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalContentBlock = styled.div`
  position: relative;
  /** Hotfix for notifications bar to not take the empty space */
  /** Content of registration dialog should be enclosed within <modalDialog.Content> which contains all the needed paddings */
  margin-top: -48px;
`;

const CenterText = styled.div`
  text-align: center;
`;

class MultiFactorRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      factorId: undefined,
      mfaApiJwt: undefined
    };

    this.registrationId = undefined;
    this.mfaService = getMfaApi(
      this.props.trackingId,
      this.props.defaultLanguage
    );
  }

  componentDidMount = () => {
    this.setState({
      loading: true
    });
    //action creator and redux avoided because of possible future dividing this registration into separate package
    this.mfaService
      .getToken(this.props.mfaToken)
      .then(({ jwt }) => {
        this.setState({
          mfaApiJwt: jwt,
          loading: false
        });
        return jwt;
      })
      .catch(this.props.mfaTokenError);

    const { factors } = this.props;
    const factorId = factors.length === 1 ? factors[0].id : undefined;
    this.setState({ factorId });
  };

  onChangeFactor = () => {
    // restart registration
    this.setState({
      factorId: null
    });
  };

  selectFactor = factorId => this.setState({ factorId });

  renderDialogFooter = () => {
    if (this.props.factors.length > 1) {
      return (
        <DialogFooter horizontalCenter>
          <Button onClick={this.onChangeFactor}>
            <FormattedMessage
              defaultMessage="Change Security Factor"
              id="changeSecurityFactor"
            />
          </Button>
        </DialogFooter>
      );
    }
  };

  onSuccess = () => {
    this.props.onClose();
    this.props.showSuccessNotification();
    this.props.onSuccess && this.props.onSuccess();
  };

  extractErrorResponse = response => {
    if (isNil(response) || !response.errors) {
      return null;
    } else if (response.errors && response.errors.length > 0) {
      return response.errors.map(err => err.message).join(" ");
    } else if (!isEmpty(response.message)) {
      return response.message;
    }
  };

  onError = errors => {
    this.props.onClose();
    const errorResponse = this.extractErrorResponse(errors);
    this.props.showErrorNotification(errorResponse);
  };

  renderClientRegistration = (factorId, typeId) => {
    switch (typeId) {
      case FACTOR_TYPES.AUTHENTICATOR: {
        return (
          <RegisterAuthenticator
            mfaApiToken={this.state.mfaApiJwt}
            factorId={this.state.factorId}
            onError={this.onError}
            onSuccess={this.onSuccess}
            username={this.props.username}
            trackingId={this.props.trackingId}
            defaultLanguage={this.props.defaultLanguage}
          />
        );
      }
      case FACTOR_TYPES.SMS: {
        return (
          <RegisterSMS
            mfaApiToken={this.state.mfaApiJwt}
            factorId={this.state.factorId}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.props.onClose}
            showMfaNotification={this.props.showMfaNotification}
            trackingId={this.props.trackingId}
            defaultLanguage={this.props.defaultLanguage}
          />
        );
      }
      case FACTOR_TYPES.VOICE: {
        return (
          <RegisterVoice
            mfaApiToken={this.state.mfaApiJwt}
            factorId={this.state.factorId}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.props.onClose}
            showMfaNotification={this.props.showMfaNotification}
            trackingId={this.props.trackingId}
            defaultLanguage={this.props.defaultLanguage}
          />
        );
      }
      case FACTOR_TYPES.YUBIKEY: {
        return (
          <RegisterYubiKey
            mfaApiToken={this.state.mfaApiJwt}
            factorId={this.state.factorId}
            onError={this.onError}
            onSuccess={this.onSuccess}
            showMfaNotification={this.props.showMfaNotification}
            trackingId={this.props.trackingId}
            defaultLanguage={this.props.defaultLanguage}
          />
        );
      }
      case FACTOR_TYPES.DUOSECURITY: {
        return (
          <RegisterDuo
            mfaApiToken={this.state.mfaApiJwt}
            factorId={this.state.factorId}
            onError={this.onError}
            onSuccess={this.onSuccess}
            trackingId={this.props.trackingId}
            defaultLanguage={this.props.defaultLanguage}
          />
        );
      }
      case FACTOR_TYPES.VIPACCESS: {
        return (
          <RegisterVip
            mfaApiToken={this.state.mfaApiJwt}
            factorId={this.state.factorId}
            onError={this.onError}
            onSuccess={this.onSuccess}
            trackingId={this.props.trackingId}
            defaultLanguage={this.props.defaultLanguage}
          />
        );
      }
      case FACTOR_TYPES.SECURITYQUESTIONS: {
        return (
          <RegisterQuestions
            mfaApiToken={this.state.mfaApiJwt}
            factorId={this.state.factorId}
            onError={this.onError}
            onSuccess={this.onSuccess}
            trackingId={this.props.trackingId}
            defaultLanguage={this.props.defaultLanguage}
          />
        );
      }
      case FACTOR_TYPES.ONELOGIN: {
        return (
          <RegisterProtect
            mfaApiToken={this.state.mfaApiJwt}
            factorId={this.state.factorId}
            onError={this.onError}
            onSuccess={this.onSuccess}
            username={this.props.username}
            trackingId={this.props.trackingId}
            defaultLanguage={this.props.defaultLanguage}
          />
        );
      }
      case FACTOR_TYPES.SECURID: {
        return (
          <RegisterSecureId
            mfaApiToken={this.state.mfaApiJwt}
            factorId={this.state.factorId}
            onError={this.onError}
            onSuccess={this.onSuccess}
            trackingId={this.props.trackingId}
            defaultLanguage={this.props.defaultLanguage}
          />
        );
      }
      case FACTOR_TYPES.WEBAUTHN_OTP: {
        return (
          <RegisterWebAuthn
            mfaApiToken={this.state.mfaApiJwt}
            factorId={this.state.factorId}
            onError={this.onError}
            onSuccess={this.onSuccess}
            trackingId={this.props.trackingId}
            defaultLanguage={this.props.defaultLanguage}
          />
        );
      }
      case FACTOR_TYPES.EMAIL_OTP: {
        return (
          <RegisterEmailOtp
            mfaApiToken={this.state.mfaApiJwt}
            factorId={this.state.factorId}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.props.onClose}
            showMfaNotification={this.props.showMfaNotification}
            trackingId={this.props.trackingId}
            defaultLanguage={this.props.defaultLanguage}
          />
        );
      }
      case FACTOR_TYPES.TRUSTED_IDP: {
        return (
          <RegisterTrustedIdp
            mfaApiToken={this.state.mfaApiJwt}
            factorId={this.state.factorId}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.props.onClose}
            showMfaNotification={this.props.showMfaNotification}
            trackingId={this.props.trackingId}
            defaultLanguage={this.props.defaultLanguage}
          />
        );
      }
      case FACTOR_TYPES.OATH: {
        return (
          <RegisterOath
            mfaApiToken={this.state.mfaApiJwt}
            factorId={this.state.factorId}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.props.onClose}
            showMfaNotification={this.props.showMfaNotification}
            trackingId={this.props.trackingId}
            defaultLanguage={this.props.defaultLanguage}
          />
        );
      }
      default: {
        return <div>Not supported factor type</div>;
      }
    }
  };

  render = () => {
    if (this.state.loading) {
      return <LoginLoading />;
    }

    if (this.props.canManageDevices === false) {
      return (
          <CenterText>
            <ContactAdmin
                warningMessage={
                  <FormattedMessage
                      defaultMessage="Admin has disabled this feature."
                      id="noAccessSecurityFactors"
                  />
                }
                onContinue={this.props.onClose}
            />
          </CenterText>
      );
    }

    if (this.props.factors.length === 0) {
      return (
        <CenterText>
          <ContactAdmin
            warningMessage={
              <FormattedMessage
                defaultMessage="There are no security factors available for registration."
                id="noMoreSecurityFactors"
              />
            }
            onContinue={this.props.onClose}
          />
        </CenterText>
      );
    }

    if (isNil(this.state.factorId)) {
      return (
        <>
          <ModalDialog.Header>
            <FormattedMessage
              defaultMessage="Select Security Factor"
              id="selectSecurityFactor"
            />
          </ModalDialog.Header>
          <FactorList
            factors={this.props.factors}
            onSelect={factorId => this.selectFactor(factorId)}
          />
        </>
      );
    }

    const { factorId } = this.state;
    const { factors } = this.props;
    // typeId is derived value (factorId, factors) => typeId, lets compute ¬it
    const { typeId, name, customIconUrl } = find(propEq("id", factorId))(
      factors
    );

    const icon = customIconUrl || getIcon(typeId);
    return (
      <>
        <ModalDialog.Header onClose={this.props.onClose}>
          <HeaderWrapper>
            {icon && (
              <IconWrapper>
                <Icon src={icon} alt="" />
              </IconWrapper>
            )}
            <ReactMarkdown source={name} renderers={{ paragraph: "span" }} />
          </HeaderWrapper>
        </ModalDialog.Header>
        <ModalDialog.Content
          clearDisplayedNotification={this.props.clearMfaNotification}
          notifications={this.props.mfaNotifications.map(notification => {
            const { message } = notification;
            return {
              ...notification,
              // messages from server are already translated => string
              // client messages are definition for translationg => object
              message:
                typeof message === "object"
                  ? this.props.intl.formatMessage({
                      id: "mfaNotificationMesage",
                      defaultMessage: message
                    })
                  : message
            };
          })}
        ></ModalDialog.Content>
        <ModalContentBlock>
          {this.renderClientRegistration(factorId, typeId)}
        </ModalContentBlock>
        {this.renderDialogFooter()}
      </>
    );
  };
}

MultiFactorRegistration.displayName = "MultiFactorRegistration";

MultiFactorRegistration.propTypes = {
  mfaToken: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  trackingId: PropTypes.string.isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  showSuccessNotification: PropTypes.func.isRequired,
  showErrorNotification: PropTypes.func.isRequired,
  mfaTokenError: PropTypes.func.isRequired,
  showMfaNotification: PropTypes.func.isRequired,
  clearMfaNotification: PropTypes.func.isRequired,
  mfaNotifications: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired
};

export default MultiFactorRegistration;
