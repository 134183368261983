import ky from "../lib/ky";
import { getCsrfCookie } from "./csrfTokenMiddleware";

export default ky.extend({
  // https://github.com/sindresorhus/ky#retry
  retry: 2,
  // https://github.com/sindresorhus/ky#timeout
  timeout: 30000,
  // set default request headers
  headers: {
    Accept: "application/json"
  },
  hooks: {
    beforeRequest: [
      async request => {
        if (request.method !== "get") {
          request.headers.set("csrf-token", await getCsrfCookie());
        }
      }
    ]
  }
});
