import userAgentParser from "ua-parser-js";

const BAD_OSX_NAME = "Mac OS";
const GOOD_OSX_NAME = "macOS";

export default userAgentString => {
  const agent = userAgentParser(userAgentString);
  const name = agent.os.name;
  agent.os.name = name === BAD_OSX_NAME ? GOOD_OSX_NAME : name;
  return agent;
};
