import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";

import {
  Button,
  COLORS,
  Icon,
  InlineTextEdit,
  Loading,
  ToggleTooltip,
  Tooltip,
  IconProvider as getIcon
} from "@onelogin/react-components";
import { notifyError } from "../../../actions/notificationsActions";
import {
  APP_ICON,
  FORBID_ICON,
  LOGIN_ICON,
  PW_RESET_ICON
} from "../../../constants/IconConstants";
import { OLSQ } from "../../../constants/OtpFactorTypes";
import { commonMessages } from "../../../translations/commonMessages";
import { ActionItem, ActionMenu } from "../../ActionToggle";
import Moment from "../../MomentWrapper";
import ResponsiveTable from "../../common/Table/ResponsiveTable";
import {
  DeviceColumn,
  FactorColumn,
  LastUsedColumn,
  UsedForColumn
} from "./MfaDeviceColumns";
import MfaDetails from "./details/MfaDetails";

const messages = defineMessages({
  usedForLogin: {
    id: "usedForLogin",
    defaultMessage: "This factor is used for login."
  },
  usedForPasswordReset: {
    id: "usedForPasswordReset",
    defaultMessage: "This factor is used for password reset."
  },
  usedForApp: {
    id: "usedForApp",
    defaultMessage: "This factor is used for app."
  },
  loginDevice: {
    id: "loginDevice",
    defaultMessage: "Login device"
  },
  pwResetDevice: {
    id: "pwResetDevice",
    defaultMessage: "Password reset device"
  },
  appDevice: {
    id: "appDevice",
    defaultMessage: "App device"
  },
  inactiveDevice: {
    id: "inactiveDevice",
    defaultMessage: "Inactive device"
  },
  icon: {
    id: "icon",
    defaultMessage: "Icon"
  },
  factorOptions: {
    id: "factorOptions",
    defaultMessage: "Factor options"
  }
});

const CUSTOM_NAME_LENGTH_LIMIT = 20;

const FactorNameWrap = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const FactorDetails = styled.div`
  position: relative;
`;

const MfaIcon = styled.img`
  height: 32px;
  width: 32px;
  min-width: 32px; //needed for IE11
  margin-right: 16px;

  filter: ${props => (props.isActive ? "none" : "gray")};
  -webkit-filter: grayscale(
    ${props => (props.isActive ? "0%" : "100%")}
  ); /* Chrome 19+ & Safari 6+ */
  opacity: ${props => (props.isActive ? "1" : "0.5")};
`;

const LoadingWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
`;

const UpdatingDeviceNameLoading = styled.div`
  display: flex;
  & > div {
    top: 0;
    position: unset;
  }
  min-width: 22px;
  width: 22px;
  margin-left: 4px;
`;

const InputWrapper = styled.div`
  max-width: 250px;
`;

const FactorIcons = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  height: 100%;
`;

const ForbidIconContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  transform: translate(90%, 45%);
  background-color: white;
  z-index: 1;
  border-radius: 50%;
`;

const EditSQInfoWrapper = styled.div`
  min-width: 240px;
  border-bottom: 1px solid ${COLORS.GRAY_COLORS.GRAY_90};
  color: ${COLORS.GRAY_COLORS.GRAY_35};
  display: flex;
  padding: 12px;
  padding-top: 6px;
  margin-bottom: 6px;
`;

const EditSQInfoText = styled.div`
  margin-left: 8px;
  line-height: 1.5;
`;

const getTableStructure = () => {
  return {
    columns: [
      {
        minWidth: 150,
        weight: 35,
        priority: 3,
        content: <FormattedMessage defaultMessage="Factor" id="factor" />,
        key: "factor"
      },
      {
        minWidth: 150,
        weight: 25,
        priority: 1,
        content: <FormattedMessage {...commonMessages.details} />,
        key: "device"
      },
      {
        minWidth: 100,
        weight: 20,
        priority: 2,
        content: <FormattedMessage defaultMessage="Last Used" id="lastUsed" />,
        key: "lastUsed"
      },
      {
        minWidth: 100,
        weight: 20,
        priority: 4,
        content: <FormattedMessage defaultMessage="Used For" id="usedFor" />,
        key: "usedFor"
      },
      {
        minWidth: 88,
        width: "88px",
        priority: 5, //highest priority to be always displayed
        content: "",
        key: "expander"
      }
    ]
  };
};

const MfaDeviceTable = props => {
  const [tableStructure] = useState(getTableStructure(props));
  const [editId, setEditMode] = useState(null);
  const [menuOpenId, setMenuOpen] = useState(null);

  tableStructure.data = [];

  const {
    locale,
    renameFactor,
    showDetails,
    onSetPrimary,
    onUnsetPrimary,
    removeFactor,
    intl,
    canManageDevices
  } = props;

  let devices = props.devices;

  if (!devices) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  if (devices) {
    for (let [index, device] of devices.entries()) {
      const {
        factorTypeId,
        factorName,
        customIconUrl,
        primary,
        lastUsed,
        id,
        updatingFactorName,
        defaultFactorName,
        isActive,
        isLoginDevice,
        isPasswordResetDevice,
        isAppDevice
      } = device;

      const isEdited = editId !== null && id === editId;
      const isSQ = factorTypeId === OLSQ;
      // make sure the options boxes for items with index higher than 5 while its last 4 items face top
      const isTooltipDirTop = index >= devices.length - 4 && index > 5;
      // make sure the last item's icon description face top
      const isLastItem = index === devices.length - 1;

      const icon = customIconUrl || getIcon(factorTypeId);

      const onSaveFactorNameEdit = name => {
        if (name.length === 0) {
          props.blankNameError();
          return;
        }

        if (factorName !== name) {
          renameFactor(editId, name);
        }

        setEditMode(null);
      };

      const onCancelFactorNameEdit = () => setEditMode(null);

      const onRevertFactorNameToDefault = () => {
        renameFactor(editId, defaultFactorName);
        setEditMode(null);
      };

      const FactorItem = (
        <>
          {!isActive && (
            <ForbidIconContainer>
              <Icon
                name={FORBID_ICON}
                size="16px"
                alt={intl.formatMessage(messages.inactiveDevice)}
              />
            </ForbidIconContainer>
          )}

          {icon && (
            <MfaIcon
              src={icon}
              alt={intl.formatMessage(messages.icon)}
              isActive={isActive}
            />
          )}

          <InputWrapper>
            <InlineTextEdit
              id={`${id}`}
              value={
                updatingFactorName !== undefined && updatingFactorName !== null
                  ? updatingFactorName
                  : factorName
              }
              editMode={isEdited}
              render={value => (
                <span
                  onDoubleClick={() =>
                    !isSQ && canManageDevices && setEditMode(id)
                  }
                >
                  {value}
                </span>
              )}
              onSave={onSaveFactorNameEdit}
              onCancel={onCancelFactorNameEdit}
              onRevert={
                defaultFactorName && defaultFactorName !== factorName
                  ? onRevertFactorNameToDefault
                  : null
              }
              maxLength={CUSTOM_NAME_LENGTH_LIMIT}
              saveOnBlur={false}
              autoFocus={true}
            />
          </InputWrapper>
          {updatingFactorName !== undefined && updatingFactorName !== null && (
            <UpdatingDeviceNameLoading>
              <Loading />
            </UpdatingDeviceNameLoading>
          )}
        </>
      );

      const factor = (
        <FactorDetails>
          <FactorColumn title={factorName}>
            <FactorNameWrap>{FactorItem}</FactorNameWrap>
          </FactorColumn>
        </FactorDetails>
      );

      const deviceCol = (
        <DeviceColumn>
          <MfaDetails device={device} />
        </DeviceColumn>
      );

      const lastUsedCol = (
        <LastUsedColumn>
          <span title={lastUsed}>
            <Moment locale={locale} fromNow>
              {lastUsed}
            </Moment>
          </span>
        </LastUsedColumn>
      );

      const usedFor = (
        <UsedForColumn>
          <FactorIcons>
            {isLoginDevice && (
              <ToggleTooltip
                dir={isLastItem ? "top" : "bottom"}
                lean="center"
                info={intl.formatMessage(messages.usedForLogin)}
              >
                <Icon
                  name={LOGIN_ICON}
                  size="24px"
                  alt={intl.formatMessage(messages.loginDevice)}
                />
              </ToggleTooltip>
            )}
            {isPasswordResetDevice && (
              <ToggleTooltip
                dir={isLastItem ? "top" : "bottom"}
                lean="center"
                info={intl.formatMessage(messages.usedForPasswordReset)}
              >
                <Icon
                  name={PW_RESET_ICON}
                  size="24px"
                  alt={intl.formatMessage(messages.pwResetDevice)}
                />
              </ToggleTooltip>
            )}
            {isAppDevice && (
              <ToggleTooltip
                dir={isLastItem ? "top" : "bottom"}
                lean="center"
                info={intl.formatMessage(messages.usedForApp)}
              >
                <Icon
                  name={APP_ICON}
                  size="24px"
                  alt={intl.formatMessage(messages.appDevice)}
                />
              </ToggleTooltip>
            )}
          </FactorIcons>
        </UsedForColumn>
      );

      const menuOpen = menuOpenId === id;

      const cells = [
        {
          key: "id",
          content: id
        },
        {
          key: "factor",
          content: factor
        },
        {
          key: "device",
          content: deviceCol,
          hidden: isEdited
        },
        {
          key: "lastUsed",
          content: lastUsedCol,
          hidden: isEdited
        },
        {
          key: "usedFor",
          content: usedFor,
          hidden: isEdited
        },
        {
          key: "expander",
          content: (
            <div data-testid="tooltip">
              <Tooltip
                open={menuOpen}
                dir={isTooltipDirTop ? "top" : "bottom"}
                lean="left"
                onFocusLeave={() => setMenuOpen()}
                tooltip={() => (
                  <ActionMenu>
                    {isSQ && canManageDevices && (
                      <EditSQInfoWrapper data-testid="change-sq-info">
                        <Icon name="Alert" size="24px" alt="" />
                        <EditSQInfoText>
                          <FormattedMessage
                            defaultMessage="To edit your security questions, please delete this factor and setup a new one."
                            id="editSQinfo"
                          />
                        </EditSQInfoText>
                      </EditSQInfoWrapper>
                    )}
                    {!primary && isLoginDevice && canManageDevices && (
                      <ActionItem
                        data-testid="set-as-primary-button"
                        tabIndex={0}
                        onClick={() => {
                          setMenuOpen();
                          onSetPrimary(id, factorName);
                        }}
                      >
                        <FormattedMessage
                          id="setPrimaryDevice"
                          defaultMessage="Set as primary"
                        />
                      </ActionItem>
                    )}
                    {primary && isLoginDevice && canManageDevices && (
                      <ActionItem
                        data-testid="unset-as-primary-button"
                        tabIndex={0}
                        onClick={() => {
                          setMenuOpen();
                          onUnsetPrimary(id, factorName);
                        }}
                      >
                        <FormattedMessage
                          id="unsetPrimaryDevice"
                          defaultMessage="Unset as primary"
                        />
                      </ActionItem>
                    )}
                    {isActive && canManageDevices && (
                      <ActionItem
                        tabIndex={0}
                        onClick={() => {
                          setMenuOpen();
                          setEditMode(id);
                        }}
                        disabled={isSQ}
                      >
                        <FormattedMessage
                          id="editDeviceName"
                          defaultMessage="Edit name"
                        />
                      </ActionItem>
                    )}
                    {canManageDevices && (
                      <ActionItem
                        data-testid="remove-factor-button"
                        tabIndex={0}
                        onClick={() => {
                          setMenuOpen();
                          removeFactor(id);
                        }}
                      >
                        <FormattedMessage {...commonMessages.remove} />
                      </ActionItem>
                    )}
                    <ActionItem
                      data-testid="show-details-button"
                      tabIndex={0}
                      onClick={() => {
                        setMenuOpen();
                        showDetails(id);
                      }}
                    >
                      <FormattedMessage {...commonMessages.showDetails} />
                    </ActionItem>
                  </ActionMenu>
                )}
              >
                <Button
                  look="regular"
                  data-testid="factor-options"
                  aria-expanded={menuOpen}
                  aria-label={intl.formatMessage(messages.factorOptions)}
                  onClick={() => setMenuOpen(menuOpen ? null : id)}
                >
                  <FormattedMessage
                    defaultMessage="Options"
                    id="factorOptions"
                  />
                </Button>
              </Tooltip>
            </div>
          )
        }
      ];

      const meta = {
        isHighlighted: primary,
        rowTitle: primary ? (
          <FormattedMessage defaultMessage="Primary" id="primary" />
        ) : (
          ""
        )
      };

      tableStructure.data.push({ cells, meta });
    }
  }

  return (
    <ResponsiveTable
      tableStructure={tableStructure}
      noDataMessage={
        <FormattedMessage {...commonMessages.noDevicesConfigured} />
      }
    />
  );
};

const mapDispatchToProps = (dispatch, props) => ({
  blankNameError: () =>
    dispatch(
      notifyError({
        message: (
          <FormattedMessage
            id="deviceNameCannotBeBlank"
            defaultMessage="Authentication device name cannot be blank."
          />
        )
      })
    )
});

MfaDeviceTable.propTypes = {
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      factorTypeId: PropTypes.number.isRequired,
      factorName: PropTypes.string.isRequired,
      primary: PropTypes.bool.isRequired,
      createdAt: PropTypes.string.isRequired,
      customIconUrl: PropTypes.string,
      behavior: PropTypes.string,
      lastUsed: PropTypes.string,
      details: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    })
  ),
  onSetPrimary: PropTypes.func.isRequired,
  onUnsetPrimary: PropTypes.func.isRequired,
  renameFactor: PropTypes.func.isRequired,
  removeFactor: PropTypes.func.isRequired,
  showDetails: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  blankNameError: PropTypes.func.isRequired
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(MfaDeviceTable)
);
