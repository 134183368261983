import React from "react";
import {
  DetailCell,
  TextItem,
  BoldSpan,
  SubTextItem
} from "../../common/Table/ResponsiveTableStyles";
import Moment from "../../MomentWrapper";
import { getEventByType } from "./events";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

export const CompositeOtpDeviceDetailsCells = props => {
  return (
    <DetailCell>
      <TextItem>
        {props.deviceCurrentName ||
          props.deviceName ||
          props.productDisplayName}
      </TextItem>
      <SubTextItem>
        {props.showPrevious && (
          <FormattedMessage
            defaultMessage="Previously {previousName}"
            id="previousDeviceName"
            values={{ previousName: props.deviceName }}
          />
        )}
      </SubTextItem>
    </DetailCell>
  );
};

const requiredPropsCheck = (props, propName, componentName) => {
  if (!(props.deviceCurrentName || props.deviceName || props.productDisplayName)) {
    return new Error(`One of 'deviceCurrentName', 'deviceName', or 'productDisplayName' is required by ${componentName} component.`);
  }
  const prop = props[propName];
  if (prop) return typeof prop === "string" ? null : new Error(`Invalid prop '${propName}' of type '${typeof prop}' supplied to '${componentName}', expected 'string'.`);
};

CompositeOtpDeviceDetailsCells.propTypes = {
  deviceCurrentName: requiredPropsCheck,
  deviceName: requiredPropsCheck,
  productDisplayName: requiredPropsCheck,
  showPrevious: PropTypes.bool.isRequired
};

export const CompositeUserAgentDeviceDetailsCell = props => {
  return (
    <DetailCell>
      <TextItem>{props.client}</TextItem>
      <SubTextItem>
        {props.osName} {props.osVersion}
      </SubTextItem>
    </DetailCell>
  );
};

CompositeUserAgentDeviceDetailsCell.propTypes = {
  client: PropTypes.string.isRequired,
  osName: PropTypes.string.isRequired,
  osVersion: PropTypes.string.isRequired
};

export const CompositeEventCell = props => {
  return (
    <DetailCell>
      <TextItem>
        <BoldSpan>{props.event}</BoldSpan>
      </TextItem>
    </DetailCell>
  );
};

export const CompositeLocationTime = props => {
  return (
    <DetailCell>
      <TextItem>{props.location}</TextItem>
      <SubTextItem>
        <Moment fromNow locale={props.locale}>
          {props.time}
        </Moment>
      </SubTextItem>
    </DetailCell>
  );
};

CompositeLocationTime.propTypes = {
  locale: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired
};

export const EventCellMap = ({ eventType }) => {
  const displayDetails = getEventByType(eventType);
  let eventDescription = eventType;
  if (displayDetails) {
    eventDescription = displayDetails.displayName;
  }

  return <CompositeEventCell event={eventDescription} />;
};

EventCellMap.propTypes = {
  eventType: PropTypes.string.isRequired
};

export const DeviceCellMap = ({ eventType, userAgentDevice, otpDevice }) => {
  let showDetails = false;
  let showPrevious = false;

  const displayDetails = getEventByType(eventType);

  if (displayDetails) {
    showDetails = displayDetails.showDevice;

    if (displayDetails.showPrevious) {
      showPrevious = displayDetails.showPrevious;
    }
  }

  if (!showDetails) {
    return (
      <DetailCell>
        <FormattedMessage defaultMessage="Unavailable" id={"unavailable"} />
      </DetailCell>
    );
  } else if (
    otpDevice &&
    (otpDevice.deviceCurrentName || otpDevice.deviceName)
  ) {
    return (
      <CompositeOtpDeviceDetailsCells
        {...otpDevice}
        showPrevious={showPrevious}
      />
    );
  } else {
    return <CompositeUserAgentDeviceDetailsCell {...userAgentDevice} />;
  }
};

const otpDeviceShape = PropTypes.shape({
  deviceCurrentName: PropTypes.string,
  deviceName: PropTypes.string,
  productDisplayName: PropTypes.string
});

const userAgentDeviceShape = PropTypes.shape({
  client: PropTypes.string.isRequired,
  osName: PropTypes.string.isRequired,
  osVersion: PropTypes.string.isRequired
});

DeviceCellMap.propTypes = {
  eventType: PropTypes.string.isRequired,
  otpDevice: otpDeviceShape,
  userAgentDevice: userAgentDeviceShape
};
