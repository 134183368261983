import React from "react";
import { FormattedMessage } from "react-intl";
import * as profileServiceApi from "../api/profileServiceApi";
import * as types from "../constants/ActionTypes";
import { notify, notifyError } from "./notificationsActions";

export const fetchTrustedBrowsers = () => dispatch => {
  return profileServiceApi
    .fetchTrustedBrowsers()
    .then(({ browsers }) => {
      dispatch({
        type: types.RECEIVE_TRUSTED_BROWSERS_SUCCESS,
        browsers
      });
    })
    .catch(() => {
      dispatch({ type: types.RECEIVE_TRUSTED_BROWSERS_FAILURE });
      dispatch(
        notifyError({
          message: (
            <FormattedMessage
              defaultMessage="Failed to fetch browsers"
              id="browserFetchFailure"
            />
          )
        })
      );
    });
};

export const deleteTrustedBrowser = id => dispatch => {
  return profileServiceApi
    .deleteTrustedBrowser(id)
    .then(({ destroyed_session }) => {
      if (destroyed_session) {
        dispatch({ type: types.REMOVE_CURRENT_BROWSER });
      } else {
        dispatch({ type: types.REMOVE_TRUSTED_BROWSER_SUCCESS, id });
        dispatch(
          notify({
            message: (
              <FormattedMessage
                defaultMessage="Trusted browser was successfully deleted"
                id="browserDeleteSuccess"
              />
            )
          })
        );
        dispatch({ type: types.REMOVE_TRUSTED_BROWSER_RESET });
      }
    })
    .catch(() => {
      dispatch(
        notifyError({
          message: (
            <FormattedMessage
              defaultMessage="Failed to delete trusted browser"
              id="browserDeleteFailure"
            />
          )
        })
      );
    });
};
