import client from "./httpClient";
import * as apiConstants from "../constants/Api";
import { getErrorHandler } from "./apiErrorHandling";

const errorHandler = getErrorHandler({});

//TODO: similar code as in profileServiceApi (handleProfileServiceError) -> make it a shared code(?)
const handleCoreApiServiceError = async error => {
  const { response } = error;

  let message = "Network error";

  // when response is not present => networking error => no response from server
  if (response) {
    const jsonResponse = await response.json();

    message = jsonResponse.status.message;

    //handle 401 and 422 errors
    errorHandler({
      normalized: {
        status: jsonResponse.status.code,
        body: {
          name: jsonResponse.status.name,
          message
        }
      }
    });
  }

  // we can't stop execution even if error was handled so we rethrow
  throw new Error(message);
};

export const postHelpRequest = async helpRequest => {
  const response = await client
    .post(`${apiConstants.SERVER_URL}/api/v3/send_help_email`, {
      json: helpRequest
    })
    .json()
    .catch(handleCoreApiServiceError);

  return response;
};
