import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import ResponsiveTable from "../../common/Table/ResponsiveTable";
import {
  CompositeDeviceCell,
  CompositeVersionCell,
  CompositeTimeCell,
  CompositeTrustedCell
} from "./CompositeTableCell";
import { commonMessages } from "../../../translations/commonMessages";

const tableStructure = {
  columns: [
    {
      minWidth: 300,
      weight: 27,
      priority: 4,
      content: <FormattedMessage {...commonMessages.application} />,
      key: "application"
    },
    {
      minWidth: 130,
      weight: 23,
      priority: 3,
      content: <FormattedMessage {...commonMessages.hostname} />,
      key: "hostname"
    },
    {
      minWidth: 130,
      weight: 23,
      priority: 2,
      content: <FormattedMessage {...commonMessages.lastAuthentication} />,
      key: "time"
    },
    {
      minWidth: 130,
      weight: 23,
      priority: 1,
      content: (
        <FormattedMessage defaultMessage="Trust Device" id={"trustDevice"} />
      ),
      key: "trustedDevice"
    }
  ]
};

const OneLoginDesktopTable = props => {
  const { desktopData, locale, tz } = props;
  tableStructure.data = [];

  if (desktopData) {
    for (let item of desktopData) {
      const application = <CompositeVersionCell {...item.desktopInstall} />;
      const hostname = <CompositeDeviceCell {...item.deviceDetails} />;
      const time = (
        <CompositeTimeCell
          time={item.lastAuthentication.time}
          locale={locale}
          tz={tz}
        />
      );
      const trusted = <CompositeTrustedCell trustDevice={item.trustedDevice} />;

      const cells = [
        {
          key: "id",
          content: item.deviceDetails.identifier
        },
        {
          key: "application",
          content: application
        },
        {
          key: "hostname",
          content: hostname
        },
        {
          key: "time",
          content: time
        },
        {
          key: "trustedDevice",
          content: trusted
        }
      ];

      tableStructure.data.push({ cells, meta: {} });
    }

    tableStructure.expander = {
      onClick: data => {
        props.showDetails(data);
      },
      idKey: "id"
    };
  }

  return (
    <ResponsiveTable
      tableStructure={tableStructure}
      noDataMessage={
        <FormattedMessage {...commonMessages.noDevicesConfigured} />
      }
      hideTopBorderMobile
    />
  );
};

OneLoginDesktopTable.propTypes = {
  desktopData: PropTypes.array.isRequired,
  showDetails: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  tz: PropTypes.string
};

export default OneLoginDesktopTable;
