import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter, Switch, Route } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";

import TrustedBrowsersTable from "./TrustedBrowsersTable";
import ScreenHeader from "../ScreenHeader";
import { DesktopViewControl } from "../ViewControl";
import LoadingDiv from "../LoadingDiv";
import ScreenContentWrapper from "../ScreenContentWrapper";
import {
  fetchTrustedBrowsers,
  deleteTrustedBrowser
} from "../../../actions/browserActions";
import { getTrustedBrowserByIdSelector } from "../../../selectors/trustedBrowserSelector";
import { commonMessages } from "../../../translations/commonMessages";
import { PUBLIC_URL, NARROW_VIEW_LIMIT } from "../../../constants/Env";
import RemoveWarning from "./RemoveWarning";
import BrowserDetailsDialog from "./BrowserDetailsDialog";

const TRUSTED_BROWSER_URL = `${PUBLIC_URL}/trusted_browsers`;

const ScreenDescription = styled.div`
  padding: 0 32px;
  margin-bottom: 24px;

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    padding: 0 20px;
  }
`;

class TrustedBrowsersScreen extends Component {
  componentDidMount() {
    this.props.browsersLoaded || this.props.fetchBrowsers();

    document.title = this.props.intl.formatMessage(
      {
        id: "trustedBrowsersTitle",
        defaultMessage: "Trusted Browsers | {brandName}"
      },
      { brandName: this.props.brandedPageTitle }
    );
  }

  showTable = () => this.props.history.push(TRUSTED_BROWSER_URL);

  showDetails = dataIndex =>
    this.props.history.push(`${TRUSTED_BROWSER_URL}/${dataIndex}`);

  onClickRemove = browser => {
    if (browser.current) {
      this.props.history.push(`${TRUSTED_BROWSER_URL}/${browser.id}/remove`);
    } else {
      this.removeBrowser(browser);
    }
  };

  removeBrowser = browser => {
    const { deleteTrustedBrowser } = this.props;
    deleteTrustedBrowser(browser.id);
    this.showTable();
  };

  render() {
    const { browsersLoaded, browsers, locale, selectedBrowser } = this.props;

    return (
      <>
        <DesktopViewControl>
          <ScreenHeader>
            <FormattedMessage {...commonMessages.trustedBrowsers} />
          </ScreenHeader>
          <ScreenDescription>
            <FormattedMessage
              id="trustedBrowsersDescription"
              defaultMessage="You will not have to use your security factor when logging in from these browsers."
            />
          </ScreenDescription>
        </DesktopViewControl>
        {browsersLoaded ? (
          <ScreenContentWrapper column isMobileFullWidth>
            <TrustedBrowsersTable
              browserData={browsers}
              showDetails={this.showDetails}
              locale={locale}
              onClickRemove={this.onClickRemove}
            />
          </ScreenContentWrapper>
        ) : (
          <LoadingDiv>
            <FormattedMessage
              defaultMessage="Loading trusted browsers, please wait..."
              id="loadingTrustedBrowsers"
            />
          </LoadingDiv>
        )}
        {browsersLoaded && (
          <Switch>
            <Route exact path={`${TRUSTED_BROWSER_URL}/:id/remove`}>
              <RemoveWarning
                browser={selectedBrowser}
                onRemove={this.removeBrowser}
                onClose={this.showTable}
              />
            </Route>
            <Route exact path={`${TRUSTED_BROWSER_URL}/:id`}>
              <BrowserDetailsDialog
                browser={selectedBrowser}
                onRemove={this.onClickRemove}
                onClose={this.showTable}
              />
            </Route>
          </Switch>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  browsers: state.trustedBrowsers.browsers,
  browsersLoaded: state.trustedBrowsers.browsersLoaded,
  browsersError: state.trustedBrowsers.browsersError,
  selectedBrowser: getTrustedBrowserByIdSelector(
    ownProps.match.params && ownProps.match.params.id,
    state
  ),
  brandedPageTitle: state.account.brandedPageTitle
});

const mapDispatchToProps = dispatch => ({
  fetchBrowsers: () => dispatch(fetchTrustedBrowsers()),
  deleteTrustedBrowser: id => dispatch(deleteTrustedBrowser(id))
});

TrustedBrowsersScreen.propTypes = {
  brandedPageTitle: PropTypes.string.isRequired,
  browsers: PropTypes.array,
  browsersLoaded: PropTypes.bool.isRequired,
  fetchBrowsers: PropTypes.func.isRequired,
  locale: PropTypes.string,
  selectedBrowser: PropTypes.object
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(TrustedBrowsersScreen)
  )
);
