const maskFn = (match, offset, whole) => {
  if (whole.length > offset + 4) {
    return "*";
  } else {
    return match;
  }
};

const maskPhoneNumber = phoneNumber => {
  return phoneNumber ? phoneNumber.replace(/\d/g, maskFn) : null;
};

export default maskPhoneNumber;
