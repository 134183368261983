import React, { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";
import { LoginLoading } from "@onelogin/react-components";
import { RedirectionNotice } from "../../../RedirectionNotice";

import getMfaApi from "../../../../api/mfaService";

const messages = defineMessages({
  registrationRejected: "Registration unsuccessful, please try again."
});

class RegisterTrustedIdp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      payload: undefined
    };

    this.mfaService = getMfaApi(
      this.props.trackingId,
      this.props.defaultLanguage
    );
    this.registrationId = undefined;
  }

  componentDidMount = () => {
    this.initRegistration();
  };

  initRegistration = () => {
    this.mfaService
      .initRegistration(this.props.mfaApiToken, this.props.factorId)
      .then(({ id, payload }) => {
        this.registrationId = id;
        this.setState({
          loading: false,
          payload
        });
      })
      .catch(err => err.response.json().then(this.props.onError));
  };

  render = () => {
    const { loading, payload } = this.state;

    if (loading) {
      return <LoginLoading />;
    }

    const { redirect_url: redirectUrl, error } = payload;

    if (error || !redirectUrl) {
      this.props.onError(messages.registrationRejected);
      return <LoginLoading />;
    }

    window.location.href = redirectUrl;
    return <RedirectionNotice />;
  };
}

RegisterTrustedIdp.propTypes = {
  mfaApiToken: PropTypes.string.isRequired,
  factorId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  trackingId: PropTypes.string.isRequired,
  defaultLanguage: PropTypes.string.isRequired
};

export default RegisterTrustedIdp;
