import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import * as coreApi from "../api/coreApi.js";
import * as types from "../constants/ActionTypes";
import { notify, notifyError } from "./notificationsActions";

const messages = defineMessages({
  helpRequestFailed: {
    id: "helpRequestFailed",
    defaultMessage: `We couldn't submit your help request.`
  }
});

const NOTIFICATION_TIME = 2000;

export const submitHelpRequest = supportRequest => (dispatch, getState) => {
  dispatch({
    type: types.HELP_SUBMIT_REQUEST
  });
  return coreApi
    .postHelpRequest({
      support_request: supportRequest.supportRequest
    })
    .then(
      result => {
        dispatch({
          type: types.HELP_SUBMIT_SUCCESS
        });
        // dispatch(gotoApps());
        dispatch(
          notify({
            message: (
              <FormattedMessage
                id="helpRequestSuccessful"
                defaultMessage="Your support request has been sent to your OneLogin Admin."
              />
            ),
            type: "success",
            dismissDelay: NOTIFICATION_TIME
          })
        );

        dispatch({
          type: types.HELP_SUBMIT_REQUEST_RESET
        });
      },
      error => {
        dispatch({
          type: types.HELP_SUBMIT_FAILED
        });

        dispatch(
          notifyError({
            message: error.message,
            //TODO: default message is not taken into account
            defaultMessage: {
              ...messages.helpRequestFailed
            }
          })
        );
      }
    );
};

export const setHelpRequestRedirectTo = redirectTo => dispatch => {
  dispatch({
    type: types.HELP_SUBMIT_REDIRECT_TO,
    redirectTo
  });
};

export const resetHelpRequestRedirectTo = () => dispatch => {
  dispatch({
    type: types.HELP_SUBMIT_REDIRECT_TO_RESET
  });
};
