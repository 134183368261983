import * as types from "../../constants/ActionTypes";
import * as profileServiceApi from "../../api/profileServiceApi";
import { notifyError } from "../notificationsActions";
import { getErrorMessage } from "./errorMessages";

export const resetMfaToken = () => dispatch => {
  dispatch({
    type: types.FETCH_MFA_TOKEN_REQUEST
  });
};

//token is used ie for factor adding, device removal, set as primary etc
//to make error message usefull and non-generic (like 'failed to fetch token' which is useless to user),
//use `type` (param defined in errorMessages)
export const fetchMfaToken = type => async (dispatch, getState) => {
  const { user } = getState();

  dispatch({
    type: types.FETCH_MFA_TOKEN_REQUEST
  });

  return profileServiceApi
    .fetchMfaToken(user.trackingId)
    .then(({ token }) => {
      dispatch({
        type: types.FETCH_MFA_TOKEN_RECEIVED,
        token
      });

      return token;
    })
    .catch(() => {
      dispatch({
        type: types.FETCH_MFA_TOKEN_FAILED
      });

      dispatch(
        notifyError({
          message: getErrorMessage(type)
        })
      );
    });
};
