import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { COLORS } from "@onelogin/react-components";

const DetailItemWrapper = styled.div`
  padding: 12px 20px;
  background-color: #ffffff;
  border-bottom: 1px solid ${COLORS.GRAY_COLORS.GRAY_94};
  line-height: 1.71;

  &:last-child {
    border-bottom: 0;
  }
`;

const DetailTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.GRAY_COLORS.GRAY_25};
`;

const ProfileDialogRow = props => {
  return (
    <DetailItemWrapper>
      <DetailTitle>{props.row.title}</DetailTitle>
      {props.row.info}
    </DetailItemWrapper>
  );
};

ProfileDialogRow.propTypes = {
  row: PropTypes.object.isRequired
};

export default ProfileDialogRow;
