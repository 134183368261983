import styled from "styled-components";
import { COLORS } from "@onelogin/react-components";

import VerticalEllipsis from "../svg/vertical-ellipsis.svg";
import VerticalEllipsisHover from "../svg/vertical-ellipsis-hover.svg";
import VerticalEllipsisActive from "../svg/vertical-ellipsis-active.svg";

export const ActionToggle = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 2px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.GRAY_COLORS.GRAY_96};
  }
`;

export const ActionMenu = styled.div`
  padding: 6px 0;
`;

//button so the menu item is navigable by Tab key and clickable by enter
export const ActionItem = styled.button`
  width: 100%;
  background: none;
  border: none;
  font-size: 13px;
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0 20px;
  cursor: pointer;
  white-space: nowrap;
  color: ${COLORS.GRAY_COLORS.GRAY_35};

  &:hover {
    background-color: ${COLORS.ONELOGIN_GRAY_COLORS.ONELOGIN_GRAY_LIGHT};
    color: ${COLORS.GRAY_COLORS.GRAY_25};
  }

  &[disabled] {
    color: ${COLORS.GRAY_COLORS.GRAY_78};
  }
`;

export const ActionToggleIcon = styled.div`
  min-height: 24px;
  min-width: 24px;

  background-color: white;
  background: no-repeat url(${VerticalEllipsis});

  &:hover {
    background: no-repeat url(${VerticalEllipsisHover});
  }
`;

export const ActionToggleIconActive = styled(ActionToggleIcon)`
  background: no-repeat url(${VerticalEllipsisActive});
`;
