import React, { Component } from "react";
import styled from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import NewPassword from "./NewPassword";
import PasswordRedirect from "./PasswordRedirect";
import ScreenHeader from "../ScreenHeader";
import ScreenHeaderControl from "../ScreenHeaderControl";
import LoadingDiv from "../LoadingDiv";
import * as types from "../../../constants/ActionTypes";
import { NARROW_VIEW_LIMIT, PUBLIC_URL } from "../../../constants/Env";
import { updatePassword } from "../../../actions/passwordActions";
import { commonMessages } from "../../../translations/commonMessages";

const ResponsiveSpacer = styled.div`
  height: 20px;
`;

class ChangePasswordScreen extends Component {
  componentDidMount() {
    document.title = this.props.intl.formatMessage(
      {
        id: "passwordTitle",
        defaultMessage: "Password | {brandName}"
      },
      { brandName: this.props.brandedPageTitle }
    );
  }

  onClickChangePassword = () => {
    this.props.history.push(`${PUBLIC_URL}/password/redirect`);
  };

  onCancelChangePassword = () => {
    this.props.history.push(`${PUBLIC_URL}/password`);
  };

  renderChangePasswordPage = () => {
    const {
      loaded,
      rules,
      passwordRedirectInfo,
      saving,
      successfullySaved
    } = this.props.passwordPolicy;

    if (loaded) {
      if (
        passwordRedirectInfo &&
        passwordRedirectInfo.password_redirect_enabled
      ) {
        return (
          <PasswordRedirect
            passwordRedirectUrl={passwordRedirectInfo.password_redirect_url}
            passwordRedirectMessage={
              passwordRedirectInfo.password_redirect_message
            }
            onClickChangePassword={this.onClickChangePassword}
            onCancelChangePassword={this.onCancelChangePassword}
          />
        );
      } else {
        return (
          <NewPassword
            onSubmit={this.props.submitNewPassword}
            rules={rules}
            isAssumed={this.props.isAssumed}
            isDirectoryUser={this.props.isDirectoryUser}
            passwordExpirationDays={this.props.passwordExpirationDays}
            passwordExpires={this.props.passwordExpires}
            passwordChangedDays={this.props.passwordChangedDays}
            saving={saving}
            successfullySaved={successfullySaved}
          />
        );
      }
    } else {
      return (
        <LoadingDiv>
          <FormattedMessage {...commonMessages.changePassword} />
        </LoadingDiv>
      );
    }
  };

  render = () => {
    return (
      <>
        <ScreenHeaderControl>
          <ScreenHeader>
            <FormattedMessage {...commonMessages.changePassword} />
          </ScreenHeader>
        </ScreenHeaderControl>

        <Responsive maxWidth={NARROW_VIEW_LIMIT}>
          <ResponsiveSpacer />
        </Responsive>
        {this.renderChangePasswordPage()}
      </>
    );
  };
}

ChangePasswordScreen.propTypes = {
  submitNewPassword: PropTypes.func.isRequired,
  fetchPasswordRulesError: PropTypes.func.isRequired,
  passwordPolicy: PropTypes.shape({
    rules: PropTypes.shape({
      length: PropTypes.number,
      complexity: PropTypes.shape({
        min: PropTypes.number,
        required: PropTypes.array,
        challenges: PropTypes.array
      })
    }),
    passwordRedirectInfo: PropTypes.shape({
      password_redirect_enabled: PropTypes.bool,
      password_redirect_url: PropTypes.string,
      password_redirect_message: PropTypes.string
    })
  }),
  isAssumed: PropTypes.bool,
  brandedPageTitle: PropTypes.string,
  passwordExpirationDays: PropTypes.number,
  passwordExpires: PropTypes.bool,
  passwordChangedDays: PropTypes.number,
  isDirectoryUser: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  passwordPolicy: state.passwordPolicy,
  isAssumed: state.user.isAssumed,
  isDirectoryUser: state.user.isDirectoryUser,
  brandedPageTitle: state.account.brandedPageTitle,
  passwordExpirationDays: state.user.passwordExpirationDays,
  passwordExpires: state.user.passwordExpires,
  passwordChangedDays: state.user.passwordChangedDays
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchPasswordRulesError: error => {
    dispatch({
      type: types.FAILED_FETCH_PASSWORD_RULES,
      messageDescriptor: error
    });
  },
  submitNewPassword: passwordInfo => {
    dispatch(updatePassword(passwordInfo, ownProps.history));
  }
});

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ChangePasswordScreen)
  )
);
