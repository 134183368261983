import React from "react";
import { FormattedMessage } from "react-intl";
import * as types from "../constants/ActionTypes";
import * as profileService from "../api/profileServiceApi";
import { notifyError } from "./notificationsActions";

export const fetchDesktopDetails = dispatch => {
  return profileService
    .fetchDesktopDetails()
    .then(response => {
      dispatch({
        type: types.FETCH_DESKTOP_DETAILS,
        desktop: response
      });
    })
    .catch(() => {
      dispatch(
        notifyError({
          message: (
            <FormattedMessage
              defaultMessage="Failed to fetch OneLogin Desktop data"
              id="oneloginDesktopFetchFailure"
            />
          )
        })
      );
    });
};
