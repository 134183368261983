export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const SET_PASSWORD_RECEIVED = "SET_PASSWORD_RECEIVED";
export const SET_PASSWORD_ERROR = "SET_PASSWORD_ERROR";
export const SET_PASSWORD_RESET = "SET_PASSWORD_RESET";

export const SET_ACTIVE_MENU_ITEM = "SET_ACTIVE_MENU_ITEM";
export const FILTER_MENU_ITEMS = "FILTER_MENU_ITEMS";

export const FETCH_BRANDING_RECEIVED = "FETCH_BRANDING_RECEIVED";
export const FETCH_BRANDING_ERROR = "FETCH_BRANDING_ERROR";

export const FETCH_POLICY_RECEIVED = "FETCH_POLICY_RECEIVED";
export const FETCH_POLICY_ERROR = "FETCH_POLICY_ERROR";

export const FETCH_MFA_TOKEN_REQUEST = "FETCH_MFA_TOKEN_REQUEST";
export const FETCH_MFA_TOKEN_RECEIVED = "FETCH_MFA_TOKEN_RECEIVED";
export const FETCH_MFA_TOKEN_FAILED = "FETCH_MFA_TOKEN_FAILED";

export const MISSING_AUTHORIZATION = "MISSING_AUTHORIZATION";

export const EXTENSION_DETECTED = "EXTENSION_DETECTED";
export const EXTENSION_NOT_DETECTED = "EXTENSION_NOT_DETECTED";
// export const EXTENSION_SKIP_INSTALL = "EXTENSION_SKIP_INSTALL";
// export const EXTENSION_INSTALL_SKIPPED = "EXTENSION_INSTALL_SKIPPED";
export const PROCEED_UNSUPPORTED_BROWSER = "PROCEED_UNSUPPORTED_BROWSER";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_RECEIVED = "FETCH_USER_RECEIVED";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_RECEIVED = "UPDATE_USER_RECEIVED";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const UPDATE_USER_RECEIVED_LANGUAGE_CHANGED =
  "UPDATE_USER_RECEIVED_LANGUAGE_CHANGED";
export const FETCH_TRACKING_ID = "FETCH_TRACKING_ID";

export const FETCH_ACCOUNT_RECEIVED = "FETCH_ACCOUNT_RECEIVED";
export const FETCH_ACCOUNT_ERROR = "FETCH_ACCOUNT_ERROR";

export const FETCH_TRANSLATIONS_REQUEST = "FETCH_TRANSLATIONS_REQUEST";
export const FETCH_TRANSLATIONS_RECEIVED = "FETCH_TRANSLATIONS_RECEIVED";
export const FETCH_TRANSLATIONS_ERROR = "FETCH_TRANSLATIONS_ERROR";

export const PUSH_NOTIFICATION_TO_QUEUE = "PUSH_NOTIFICATION_TO_QUEUE";
export const REMOVE_NOTIFICATION_FROM_QUEUE = "REMOVE_NOTIFICATION_FROM_QUEUE";
export const PUSH_MFA_NOTIFICATION_TO_QUEUE = "PUSH_MFA_NOTIFICATION_TO_QUEUE";
export const REMOVE_MFA_NOTIFICATION_FROM_QUEUE =
  "REMOVE_MFA_NOTIFICATION_FROM_QUEUE";

export const FATAL_ERROR = "FATAL_ERROR";

export const RELOAD_PROFILE_PHOTO = "RELOAD_PROFILE_PHOTO";

export const FAILED_FETCH_PASSWORD_RULES = "FAILED_FETCH_PASSWORD_RULES";

export const FETCH_RECENT_ACTIVITY = "FETCH_RECENT_ACTIVITY";
export const REPORT_ACTIVITY = "REPORT_ACTIVITY";

export const RECEIVE_MFA_FACTORS = "RECEIVE_MFA_FACTORS";
export const RECEIVE_MFA_DEVICES = "RECEIVE_MFA_DEVICES";

export const UPDATE_DEFAULT_MFA_DEVICE_SUCCESS =
  "UPDATE_DEFAULT_MFA_DEVICE_SUCCESS";
export const UNSET_DEFAULT_MFA_DEVICE_SUCCESS =
  "UNSET_DEFAULT_MFA_DEVICE_SUCCESS";
export const UPDATE_DEFAULT_MFA_DEVICE_RESET =
  "UPDATE_DEFAULT_MFA_DEVICE_RESET";
export const UNSET_DEFAULT_MFA_DEVICE_RESET =
  "UNSET_DEFAULT_MFA_DEVICE_RESET";
export const REMOVE_MFA_DEVICE_SUCCESS = "REMOVE_MFA_DEVICE_SUCCESS";
export const REMOVE_MFA_DEVICE_RESET = "REMOVE_MFA_DEVICE_RESET";
export const REMOVE_MFA_DEVICE_AFTER_NEW_FACTOR =
  "REMOVE_MFA_DEVICE_AFTER_NEW_FACTOR";
export const UPDATED_DEVICE_NAME = "UPDATED_DEVICE_NAME";
export const UPDATING_DEVICE_NAME = "UPDATING_DEVICE_NAME";
export const UPDATE_DEVICE_NAME_FAILED = "UPDATE_DEVICE_NAME_FAILED";

export const FETCH_DESKTOP_DETAILS = "FETCH_DESKTOP_DETAILS";
export const HELP_SUBMIT_REQUEST = "HELP_SUBMIT_REQUEST";
export const HELP_SUBMIT_SUCCESS = "HELP_SUBMIT_SUCCESS";
export const HELP_SUBMIT_FAILED = "HELP_SUBMIT_FAILED";
export const HELP_SUBMIT_REQUEST_RESET = "HELP_SUBMIT_REQUEST_RESET";
export const HELP_SUBMIT_REDIRECT_TO = "HELP_SUBMIT_REDIRECT_TO";
export const HELP_SUBMIT_REDIRECT_TO_RESET = "HELP_SUBMIT_REDIRECT_TO_RESET";

export const SET_RECENT_ACTIVITY_FILTER = "SET_RECENT_ACTIVITY_FILTER";

export const RECEIVE_TRUSTED_BROWSERS_SUCCESS =
  "RECEIVE_TRUSTED_BROWSERS_SUCCESS";
export const RECEIVE_TRUSTED_BROWSERS_FAILURE =
  "RECEIVE_TRUSTED_BROWSERS_FAILURE";
export const REMOVE_TRUSTED_BROWSER_SUCCESS = "REMOVE_TRUSTED_BROWSER_SUCCESS";
export const REMOVE_TRUSTED_BROWSER_RESET = "REMOVE_TRUSTED_BROWSER_RESET";
export const REMOVE_CURRENT_BROWSER = "REMOVE_CURRENT_BROWSER";
export const SET_PROFILE_IMAGE_DATA = "SET_PROFILE_IMAGE_DATA";
export const SET_PROFILE_FORM_DATA = "SET_PROFILE_FORM_DATA";
