import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import FormFooter from "../../FormFooter";
import {
  COLORS,
  Label,
  PasswordInput,
  ToDoList,
  Button,
  PasswordComplexity
} from "@onelogin/react-components";

import PasswordInfo from "./PasswordInfo";
import ScreenContentWrapper from "../ScreenContentWrapper";

import {
  NARROW_VIEW_LIMIT,
  MENU_WIDTH,
  MAIN_CONTENT_PADDING
} from "../../../constants/Env";

const INPUT_WIDTH = 354;
const INPUT_RIGHT_MARGIN = 48;

const PasswordWrapper = styled.div`
  width: 100%;

  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 24px;

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    flex-direction: column;
    flex-grow: 0;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 ${INPUT_WIDTH}px;
  max-width: ${INPUT_WIDTH}px;
  margin-right: ${INPUT_RIGHT_MARGIN}px;

  @media screen and (max-width: ${MENU_WIDTH +
      INPUT_WIDTH +
      INPUT_RIGHT_MARGIN +
      2 * MAIN_CONTENT_PADDING}px) {
    margin-right: auto;
  }

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    flex: 1 0 auto;
    min-width: auto;
    max-width: 100%;
    margin-right: 0;
  }
`;

const InputWrapperVertical = styled(InputWrapper)`
  flex-basis: auto;
`;

const ToDoListHeader = styled.div`
  background-color: ${COLORS.GRAY_COLORS.GRAY_96};
  padding: 16px;
  padding-bottom: 0;
`;

const challengeMap = length => {
  return {
    length: (
      <FormattedMessage
        defaultMessage="Minimum {length} characters"
        values={{ length }}
        id={"length"}
      />
    ),
    letters: <FormattedMessage defaultMessage="1 Letter" id={"letter"} />,
    upper: <FormattedMessage defaultMessage="1 Uppercase" id={"upper"} />,
    lower: <FormattedMessage defaultMessage="1 Lowercase" id={"lower"} />,
    numbers: <FormattedMessage defaultMessage="1 Number" id={"numbers"} />,
    specials: (
      <FormattedMessage defaultMessage="1 Special character" id={"specials"} />
    )
  };
};

class NewPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    currentPassword: "",
    newPassword: ""
  });

  componentWillReceiveProps(newProps) {
    if (newProps.successfullySaved) {
      this.setState(this.getInitialState());
    }
  }

  setCurrentPassword = value => {
    this.setState({ currentPassword: value });
  };

  setNewPassword = value => {
    this.setState({ newPassword: value });
  };

  getToDoList = (challenges, completedChallenges, rules) => {
    const { min } = rules.complexity;

    // challenge will include the length of password, but is not included in min, so +1
    if (min + 1 < challenges.length) {
      return (
        <div>
          <ToDoList
            list={challenges.slice(0, 1).map(challenge => ({
              text: challengeMap(rules.length)[challenge],
              complete: completedChallenges.includes(challenge)
            }))}
          />
          <ToDoListHeader>
            <FormattedMessage
              defaultMessage="At least {number} of the following requirements:"
              values={{ number: min }}
              id="conditionalRequirement"
            />
          </ToDoListHeader>
          <ToDoList
            list={challenges.slice(1).map(challenge => ({
              text: challengeMap(rules.length)[challenge],
              complete: completedChallenges.includes(challenge)
            }))}
          />
        </div>
      );
    } else {
      return (
        <div>
          <ToDoList
            list={challenges.map(challenge => ({
              text: challengeMap(rules.length)[challenge],
              complete: completedChallenges.includes(challenge)
            }))}
          />
        </div>
      );
    }
  };

  render() {
    const {
      onSubmit,
      rules,
      isAssumed,
      isDirectoryUser,
      saving,
      successfullySaved,
      passwordExpirationDays,
      passwordExpires,
      passwordChangedDays
    } = this.props;

    const { currentPassword, newPassword } = this.state;

    return (
      <form
        onSubmit={event => {
          event.preventDefault();
          onSubmit({ currentPassword, newPassword });
        }}
      >
        <PasswordComplexity
          rules={rules}
          value={successfullySaved ? "" : newPassword}
          render={({ invalid, completedChallenges, challenges }) => (
            <>
              <ScreenContentWrapper column>
                <PasswordWrapper data-testid="current-password-content">
                  <InputWrapper>
                    <Label forId="current-password-input">
                      <FormattedMessage
                        defaultMessage={"Current password"}
                        id={"currentPassword"}
                      />
                    </Label>
                    <PasswordInput
                      data-testid="current-password-input"
                      id="current-password-input"
                      onChange={event =>
                        this.setCurrentPassword(event.target.value)
                      }
                      autoFocus
                      value={successfullySaved ? "" : currentPassword}
                      disabled={isAssumed}
                    />
                  </InputWrapper>
                  <PasswordInfo
                    passwordExpirationDays={passwordExpirationDays}
                    passwordExpires={passwordExpires}
                    passwordChangedDays={passwordChangedDays}
                    isDirectoryUser={isDirectoryUser}
                  />
                </PasswordWrapper>
                <PasswordWrapper data-testid="new-password-content">
                  <InputWrapper>
                    <Label forId="new-password-input">
                      <FormattedMessage
                        defaultMessage={"New password"}
                        id={"newPassword"}
                      />
                    </Label>
                    <PasswordInput
                      data-testid="new-password-input"
                      id="new-password-input"
                      onChange={event =>
                        this.setNewPassword(event.target.value)
                      }
                      value={successfullySaved ? "" : newPassword}
                      disabled={isAssumed}
                    />
                  </InputWrapper>
                </PasswordWrapper>
                {challenges.length > 0 && (
                  <InputWrapperVertical>
                    <ToDoListHeader>
                      <FormattedMessage
                        defaultMessage="Strong passwords or passphrases are important for security. Your password must use:"
                        id="passwordHint"
                      />
                    </ToDoListHeader>
                    {this.getToDoList(challenges, completedChallenges, rules)}
                  </InputWrapperVertical>
                )}

                <FormFooter>
                  <Button
                    size="large"
                    type="submit"
                    look="primary"
                    data-testid="password-change-save-button"
                    disabled={
                      saving || isAssumed || invalid || !currentPassword
                    }
                  >
                    <FormattedMessage
                      defaultMessage="Update Password"
                      id={"updatePassword"}
                    />
                  </Button>
                </FormFooter>
              </ScreenContentWrapper>
            </>
          )}
        />
      </form>
    );
  }
}

NewPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  rules: PropTypes.object.isRequired,
  isAssumed: PropTypes.bool,
  isDirectoryUser: PropTypes.bool.isRequired,
  saving: PropTypes.bool,
  passwordExpirationDays: PropTypes.number,
  passwordExpires: PropTypes.bool,
  passwordChangedDays: PropTypes.number,
  successfullySaved: PropTypes.bool
};

export default NewPassword;
