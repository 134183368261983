import Bowser from "bowser";
import moment from "moment";

import {
  MIN_SUPPORTED_BROWSERS,
  HTML5_COPY_SUPPORTED_BROWSERS,
  DEPRECATED_BROWSERS
} from "../constants/Env";
import * as types from "../constants/ActionTypes";
import {
  getProceedUnsupportedBrowser,
  getNextDeprecatedWarningDate
} from "../storage/localStorage";
//https://www.npmjs.com/package/bowser/v/1.9.4#bowserisunsupportedbrowserminversions-object--strictmode-boolean--ua-string--boolean

const browser = Bowser.getParser(window.navigator.userAgent);
const browserInfo = browser.getBrowser();

const isDeprecatedBrowser = !!browser.satisfies(DEPRECATED_BROWSERS);

const browserPlatform = browser.getPlatformType();

const forceUnsupportedBrowserWarning = () => {
  const nextDeprecatedWarningDate = getNextDeprecatedWarningDate();

  return (
    isDeprecatedBrowser &&
    (nextDeprecatedWarningDate === null ||
      moment(parseInt(nextDeprecatedWarningDate, 10)).isBefore(new Date()))
  );
};

export const initialState = {
  isTouchDevice: !!(browserPlatform.mobile || browserPlatform.tablet),
  isSupportedBrowser: browser.satisfies(MIN_SUPPORTED_BROWSERS),
  copyUnsupportedBrowser: !browser.satisfies(HTML5_COPY_SUPPORTED_BROWSERS),
  isDeprecatedBrowser,
  myBrowser: `${browserInfo.name} ${
    browserInfo.version
      ? browserInfo.version.split(".", 2).join(".")
      : browserInfo.version
  }`,
  proceedUnsupportedBrowser:
    !forceUnsupportedBrowserWarning() && !!getProceedUnsupportedBrowser(),
  isNavbarless: window.navigator.userAgent.includes("OneLogin")
};

function environment(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ACCOUNT_RECEIVED:
      return {
        ...state,
        proceedUnsupportedBrowser:
          action.data.ignoreUnsupportedBrowser ||
          initialState.proceedUnsupportedBrowser
      };
    case types.PROCEED_UNSUPPORTED_BROWSER:
      return {
        ...state,
        proceedUnsupportedBrowser: true
      };
    default:
      return state;
  }
}

export default environment;
