import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import Bowser from "bowser";
import styled from "styled-components";

import { ContentBlock, BREAKPOINTS } from "@onelogin/react-components";
import ScreenContentWrapper from "../ScreenContentWrapper";
import ScreenHeader from "../ScreenHeader";
import OneLoginDesktopDialog from "./OneLoginDesktopDialog";
import OneLoginDesktopTable from "./OneLoginDesktopTable";
import DownloadTile from "../../common/DownloadTile/DownloadTile";
import LoadingDiv from "../LoadingDiv";
import { getDesktopByIdSelector } from "../../../selectors/oneloginDesktopSelector";
import { PUBLIC_URL } from "../../../constants/Env";
import { commonMessages } from "../../../translations/commonMessages";

const browser = Bowser.getParser(window.navigator.userAgent);
const osName = browser.getOSName();
const platformType = browser.getPlatformType();

const LargeViewControl = styled.div`
  @media screen and (max-width: ${BREAKPOINTS.PORTAL_MIDDLE}) {
    display: none;
  }
`;

const HorizontalContentBlock = styled.div`
  display: flex;
  flex-direction: row;
`;

const DownloadWrapper = styled.div`
  margin-right: 20px;
  margin-bottom: 16px;
`;

class OneLoginDesktopScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = { dataIndex: null };
  }

  componentDidMount() {
    document.title = this.props.intl.formatMessage(
      {
        id: "desktopTitle",
        defaultMessage: "OneLogin Desktop | {brandName}"
      },
      { brandName: this.props.brandedPageTitle }
    );
  }

  showDetails = dataIndex => {
    this.props.history.push(`${PUBLIC_URL}/desktop/${dataIndex}`);
  };

  onDesktopDetailClose = () => {
    this.props.history.push(`${PUBLIC_URL}/desktop`);
  };

  getDownloadTile = () => {
    if (!this.props.desktop.data.oneloginDesktopInstallers) {
      return null;
    }

    const {
      mac_desktop: macDesktop,
      mac_pro_desktop: macProDesktop,
      windows_desktop: windowsDesktop,
      windows_pro_desktop: windowsProDesktop
    } = this.props.desktop.data.oneloginDesktopInstallers;

    if (osName === "macOS") {
      return (
        <HorizontalContentBlock>
          {macDesktop && (
            <DownloadWrapper>
              <DownloadTile
                software={"macDesktop"}
                version={macDesktop.version}
                downloadlink={macDesktop.installer}
              />
            </DownloadWrapper>
          )}
          {macProDesktop && (
            <DownloadWrapper>
              <DownloadTile
                software={"macProDesktop"}
                version={macProDesktop.version}
                downloadlink={macProDesktop.installer}
              />
            </DownloadWrapper>
          )}
        </HorizontalContentBlock>
      );
    } else {
      return (
        <HorizontalContentBlock>
          {windowsDesktop && (
            <DownloadWrapper>
              <DownloadTile
                software={"windowsDesktop"}
                version={windowsDesktop.version}
                downloadlink={windowsDesktop.installer}
              />
            </DownloadWrapper>
          )}
          {windowsProDesktop && (
            <DownloadWrapper>
              <DownloadTile
                software={"windowsProDesktop"}
                version={windowsProDesktop.version}
                downloadlink={windowsProDesktop.installer}
              />
            </DownloadWrapper>
          )}
        </HorizontalContentBlock>
      );
    }
  };

  render() {
    return (
      <>
        <LargeViewControl>
          <ScreenHeader>
            <FormattedMessage {...commonMessages.oneloginDesktop} />
          </ScreenHeader>
        </LargeViewControl>
        {this.props.desktop.loaded ? (
          <ScreenContentWrapper column isMobileFullWidth>
            <OneLoginDesktopTable
              desktopData={this.props.desktop.data.devices}
              showDetails={this.showDetails}
              locale={this.props.locale}
              tz={this.props.tz}
            />
            <ContentBlock>
              {!(platformType === "mobile" || platformType === "tablet") &&
                this.getDownloadTile()}
            </ContentBlock>
          </ScreenContentWrapper>
        ) : (
          <LoadingDiv>
            <FormattedMessage {...commonMessages.oneloginDesktop} />
          </LoadingDiv>
        )}
        <Switch>
          <Route exact path={`${PUBLIC_URL}/desktop/:id`}>
            {this.props.desktopDetails && (
              <OneLoginDesktopDialog
                data={this.props.desktopDetails}
                onClose={this.onDesktopDetailClose}
                locale={this.props.locale}
              />
            )}
          </Route>
        </Switch>
      </>
    );
  }
}

OneLoginDesktopScreen.propTypes = {
  desktop: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  brandedPageTitle: PropTypes.string.isRequired,
  tz: PropTypes.string
};

const mapStateToProps = (state, ownProps) => ({
  desktop: state.desktop,
  locale: state.user.currentLanguageLocale,
  brandedPageTitle: state.account.brandedPageTitle,
  desktopDetails: getDesktopByIdSelector(state, ownProps.match.params.id)
});

export default withRouter(
  injectIntl(connect(mapStateToProps)(OneLoginDesktopScreen))
);
