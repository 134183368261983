import React from "react";
import PropTypes from "prop-types";

const GenericDetails = ({ details }) => {
  return <span title={details}>{details}</span>;
};

GenericDetails.propTypes = {
  details: PropTypes.string.isRequired
};

export default GenericDetails;
