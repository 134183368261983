import * as types from "../constants/ActionTypes";

export const initialState = {
  rules: {
    length: undefined,
    complexity: {
      min: undefined,
      required: [],
      challenges: []
    }
  },
  passwordRedirectInfo: {
    password_redirect_enabled: undefined,
    password_redirect_url: undefined,
    password_redirect_message: undefined
  },
  loaded: undefined,
  saving: undefined,
  successfullySaved: undefined
};

function passwordPolicy(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_POLICY_RECEIVED: {
      return {
        ...state,
        rules: action.passwordPolicy,
        passwordRedirectInfo: action.passwordRedirectInfo,
        loaded: true
      };
    }
    case types.FETCH_POLICY_ERROR:
      return {
        ...state,
        loaded: false
      };
    case types.SET_PASSWORD_REQUEST:
      return {
        ...state,
        saving: true
      };
    case types.SET_PASSWORD_RECEIVED:
      return {
        ...state,
        successfullySaved: true,
        saving: false
      };
    case types.SET_PASSWORD_ERROR:
      return {
        ...state,
        saving: false,
        successfullySaved: false
      };
    case types.SET_PASSWORD_RESET:
      return {
        ...state,
        successfullySaved: undefined
      };
    default:
      return state;
  }
}

export default passwordPolicy;
