import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { Provider, connect } from "react-redux";
import styled, { ThemeProvider } from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { FatalError } from "@onelogin/react-components";

import AirbrakeProvider from "./AirbrakeProvider";
import ErrorBoundary from "./ErrorBoundary";
import AppContent from "./AppContent";
import LegacyRedirect from "./LegacyRedirect";
import SecurityQuestionsContent from "./screens/ForcedSecurityQuestions/SecurityQuestionsContent";
import OneTrust from "./OneTrust";

import { PUBLIC_URL, LEGACY_URL } from "../constants/Env";

const FatalErrorWrapper = styled.div`
  width: 100%;
`;

const App = ({ airbrake, store, theme, fatalErrorMessage }) => {
  const account = store?.getState()?.account;
  return (
    <AirbrakeProvider airbrake={airbrake}>
      <ErrorBoundary>
        <OneTrust cookieManagementEnabled={account.cookieManagementEnabled}>
          <ThemeProvider theme={theme}>
            <Provider store={store}>
              {fatalErrorMessage ? (
                <FatalErrorWrapper>
                  <FatalError
                    message={<FormattedMessage {...fatalErrorMessage} />}
                  />
                </FatalErrorWrapper>
              ) : (
                <Router>
                  <Switch>
                    <Route
                      path={`${PUBLIC_URL}/security_questions`}
                      component={SecurityQuestionsContent}
                    />
                    <Route path={`${LEGACY_URL}`} component={LegacyRedirect} />
                    <Route path={`${PUBLIC_URL}`} component={AppContent} />
                    <Redirect path="*" to={`${PUBLIC_URL}`} />
                  </Switch>
                </Router>
              )}
            </Provider>
          </ThemeProvider>
        </OneTrust>
      </ErrorBoundary>
    </AirbrakeProvider>
  );
};

const mapStateToProps = state => ({
  theme: state.branding,
  fatalErrorMessage: state.fatalError.fatalErrorMessage
});

App.propTypes = {
  theme: PropTypes.shape({
    accentColor: PropTypes.string.isRequired,
    loaded: PropTypes.bool,
    logoUrl: PropTypes.string,
    primaryColor: PropTypes.string.isRequired,
    primaryFontColor: PropTypes.string.isRequired
  })
};

export default connect(mapStateToProps)(App);
