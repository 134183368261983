import * as types from "../constants/ActionTypes";
import { filter } from "ramda";

const initialState = {
  currentNotification: undefined,
  notificationsQueue: [],
  mfaNotificationsQueue: []
};

function notifications(state = initialState, action) {
  switch (action.type) {
    case types.PUSH_NOTIFICATION_TO_QUEUE:
      return {
        ...state,
        notificationsQueue: [...state.notificationsQueue, action.notification]
      };

    case types.REMOVE_NOTIFICATION_FROM_QUEUE:
      return {
        ...state,
        notificationsQueue: filter(
          notification => notification.id !== action.id,
          state.notificationsQueue
        )
      };

    case types.PUSH_MFA_NOTIFICATION_TO_QUEUE:
      return {
        ...state,
        mfaNotificationsQueue: [
          ...state.mfaNotificationsQueue,
          action.mfaNotification
        ]
      };

    case types.REMOVE_MFA_NOTIFICATION_FROM_QUEUE:
      return {
        ...state,
        mfaNotificationsQueue: filter(
          mfaNotification => mfaNotification.id !== action.id,
          state.mfaNotificationsQueue
        )
      };

    default:
      return state;
  }
}

export default notifications;
