import * as types from "../constants/ActionTypes";

const initialActivity = {
  loaded: false,
  reportActivity: undefined,
  selectedFilter: null
};

function recentActivity(state = initialActivity, action) {
  switch (action.type) {
    case types.FETCH_RECENT_ACTIVITY:
      return {
        ...state,
        data: action.activity,
        loaded: true
      };
    case types.REPORT_ACTIVITY:
      return {
        ...state,
        reportActivity: action.activity
      };
    case types.SET_RECENT_ACTIVITY_FILTER:
      return {
        ...state,
        selectedFilter: action.filter
      };
    case types.HELP_SUBMIT_REQUEST_RESET:
      return {
        ...state,
        reportActivity: undefined
      };
    default:
      return state;
  }
}

export default recentActivity;
