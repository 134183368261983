/**
 * This is a copy of the FormRequest component from web-login.
 * This performs a redirect to a page given three values:
 * - url: The base url
 * - method: The HTTP method
 * - params: An object containing additional params consumed by the endpoint, which are
 *   added to the request
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { keys, map } from "ramda";

import { Loading } from "@onelogin/react-components";

class FormRequest extends Component {
  componentDidMount() {
    this.form.submit();
  }

  // check performed before form render that ensures the URL is safe (AKA not a
  // `javascript:...` string), which parses the URL and ensures it has the
  // correct protocol.
  safeURL = () => {
    const { url, method, params } = this.props;
    const { protocol } = new URL(url);
    if (protocol === "http:" || protocol === "https:") {
      return { url, method, params };
    } else {
      return {
        url: "/",
        method: "get",
        params: {}
      };
    }
  };

  render() {
    const { target, trackingId } = this.props;

    const { url, method, params } = this.safeURL();

    return (
      <div>
        <form
          action={url}
          method={method}
          target={target}
          ref={form => {
            if (form) {
              this.form = form;
            }
          }}
        >
          {map(
            key => (
              <input key={key} type="hidden" name={key} value={params[key]} />
            ),
            keys(params)
          )}
          {trackingId && (
            <input
              key="trackingId"
              type="hidden"
              name="trackingId"
              value={trackingId}
            />
          )}
        </form>
        <Loading />
      </div>
    );
  }
}

FormRequest.defaultProps = {
  target: "_self"
};

FormRequest.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  params: PropTypes.object,
  target: PropTypes.string,
  trackingId: PropTypes.string
};

export default FormRequest;

// http://localhost:3502/profile2/security_questions#return={%22uri%22:%22https://gilbert-schmitt-us-customer.onelogin-shadow01.com/%22,%22method%22:%22get%22,%22params%22:{%22redirect_to%22:%22profile2/%22}}
