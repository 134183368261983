import React, { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";

import {
  PairProtectOrAuthenticator,
  LoginSecurityCode,
  LoginLoading
} from "@onelogin/react-components";

import getMfaApi, { QR_ROTATION_INTERVAL } from "../../../../api/mfaService";

const messages = defineMessages({
  invalidCode: "Invalid code",
  registrationTimedout: "Registration context timed out"
});

class RegisterAuthenticator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barCodeScanned: false,
      loading: true,
      verificationToken: undefined
    };

    this.mfaService = getMfaApi(
      this.props.trackingId,
      this.props.defaultLanguage
    );
    this.registrationId = undefined;
  }

  componentDidMount = () => {
    this.initRegistration();
    this.timer = setInterval(this.initRegistration, QR_ROTATION_INTERVAL);
  };

  componentWillUnmount = () => {
    this.clearUpdateTimer();
  };

  clearUpdateTimer = () => {
    clearInterval(this.timer);
  };

  initRegistration = () => {
    this.mfaService
      .initRegistration(this.props.mfaApiToken, this.props.factorId)
      .then(({ id, verificationToken }) => {
        this.registrationId = id;
        this.setState({
          verificationToken,
          loading: false
        });
      })
      .catch(err => err.response.json().then(this.props.onError));
  };

  onContinue = () => {
    this.clearUpdateTimer();
    this.setState({
      barCodeScanned: true
    });
  };

  onSubmitOtp = otp => {
    this.setState({
      loading: true
    });

    const { mfaApiToken } = this.props;
    this.mfaService
      .submitRegistrationOtp(mfaApiToken, this.registrationId, otp)
      .then(() =>
        this.mfaService.getRegistration(mfaApiToken, this.registrationId)
      )
      .then(body => {
        switch (body.status) {
          case "accepted":
            this.props.onSuccess();
            break;
          case "rejected":
            this.props.onError();
            break;
          default:
            this.props.onError(messages.invalidCode);
            this.setState({
              loading: false
            });
        }
      })
      .catch(err => err.response.json().then(this.handleRegistrationError));
  };

  handleRegistrationError = err => {
    if (err && err.code === 404) {
      this.props.onError(messages.registrationTimedout);
    } else {
      this.props.onError(err);
    }
  };

  render = () => {
    if (this.state.loading) {
      return <LoginLoading />;
    }

    if (!this.state.barCodeScanned) {
      return (
        <PairProtectOrAuthenticator
          appName="Authenticator"
          qrLink={`otpauth://totp/${this.props.username}?secret=${this.state.verificationToken}&issuer=OneLogin`}
          code={this.state.verificationToken}
          onContinue={this.onContinue}
          condensedCode={true}
        />
      );
    }

    return (
      <LoginSecurityCode
        deviceName="Authenticator"
        onSubmit={this.onSubmitOtp}
      />
    );
  };
}

RegisterAuthenticator.propTypes = {
  mfaApiToken: PropTypes.string.isRequired,
  factorId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  trackingId: PropTypes.string.isRequired,
  defaultLanguage: PropTypes.string.isRequired
};

export default RegisterAuthenticator;
