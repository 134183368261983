import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import DropdownToggle from "./DropdownToggle";
import { Dimmer } from "@onelogin/react-components";

const ENTER_KEY_CODE = 13;
const SPACE_KEY_CODE = 32;

const Overlay = styled.div`
  width: 100%;
  bottom: 0;
  position: absolute;
  top: ${props => props.top}px;
  text-align: left;
  line-height: 1.4285em;
`;

const Wrapper = styled.div`
  margin-top: ${props => props.margin}px;
`;

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      top: undefined
    };

    this.dropdownToggleEl = React.createRef();
  }

  toggleDropdown = () => {
    const { isOpen } = this.state;

    //when dropdown is going to open, count the top position
    //it can change in time, e.g. navBar menu will be open or password expiration bar is displayed etc
    if (!isOpen && this.dropdownToggleEl) {
      const size = this.dropdownToggleEl.current.getBoundingClientRect();
      const dropdownHeight = this.dropdownToggleEl.current.offsetHeight;
      this.setState({ top: size.top, dropdownHeight });
    }

    this.setState({ isOpen: !isOpen });
  };

  onToggleClick = () => {
    this.toggleDropdown();
    this.setState({ shouldFocus: false });
  };

  onToggleKeyDown = event => {
    const isSpaceOrEnter = [ENTER_KEY_CODE, SPACE_KEY_CODE].includes(event.keyCode);
    if (isSpaceOrEnter) {
      event.preventDefault();
      this.toggleDropdown(event);
    }
    this.setState({ shouldFocus: isSpaceOrEnter });
  };

  onDimmerClick = event => {
    this.setState({ isOpen: false, shouldFocus: false });
  };

  render() {
    const { isOpen, top, dropdownHeight, shouldFocus } = this.state;
    const dropdownToggle = (
      <DropdownToggle
        onClick={this.onToggleClick}
        onKeyDown={this.onToggleKeyDown}
        isOpen={isOpen}
        ref={this.dropdownToggleEl}
        shouldFocus={shouldFocus}
      >
        {this.props.activeItemTitle}
      </DropdownToggle>
    );

    if (isOpen) {
      return (
        <Wrapper margin={dropdownHeight}>
          <Dimmer onClick={this.onDimmerClick}>
            <Overlay
              ref={menuWrap => {
                if (menuWrap) {
                  this.menuWrap = menuWrap;
                }
              }}
              top={top}
            >
              {dropdownToggle}
              {this.props.children}
            </Overlay>
          </Dimmer>
        </Wrapper>
      );
    } else {
      return dropdownToggle;
    }
  }
}

Dropdown.propTypes = {
  activeItemTitle: PropTypes.node.isRequired
};

export default Dropdown;
