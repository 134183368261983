import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { COLORS } from "@onelogin/react-components";

const PasswordInfoWrapper = styled.div`
  display: flex;
  margin-top: 24px;
`;

const InfoWrapper = styled.div`
  padding-right: 48px;
  &:last-child {
    padding-right: 0;
  }
`;

const PasswordInfoTitle = styled.div`
  font-size: 13px;
  color: ${COLORS.GRAY_COLORS.GRAY_45};
  margin-bottom: 4px;
`;

const PasswordInfoContent = styled.div`
  font-size: 14px;
  color: #3f4040;
`;

const PasswordInfo = ({
  isDirectoryUser,
  passwordExpirationDays,
  passwordExpires,
  passwordChangedDays
}) => (
  <PasswordInfoWrapper>
    <InfoWrapper>
      <PasswordInfoTitle>
        <FormattedMessage defaultMessage="Last changed" id={"lastChanged"} />
      </PasswordInfoTitle>
      <PasswordInfoContent>
        <FormattedMessage
          defaultMessage="{numberOfDays, plural,
                                  =0 {today}
                                  one {{numberOfDays} day}
                                  other {{numberOfDays} days}
                              }"
          id={"passwordChangedDays"}
          values={{ numberOfDays: passwordChangedDays }}
        />
      </PasswordInfoContent>
    </InfoWrapper>
    {!isDirectoryUser && (
      <InfoWrapper>
        <>
          <PasswordInfoTitle>
            <FormattedMessage defaultMessage="Expires" id={"expires"} />
          </PasswordInfoTitle>
          <PasswordInfoContent>
            {passwordExpires ? (
              <FormattedMessage
                defaultMessage="{numberOfDays, plural,
                                      =0 {today}
                                      one {{numberOfDays} day}
                                      other {{numberOfDays} days}
                                  }"
                id={"expirationDays"}
                values={{ numberOfDays: passwordExpirationDays }}
              />
            ) : (
              <FormattedMessage defaultMessage="Never" id="never" />
            )}
          </PasswordInfoContent>
        </>
      </InfoWrapper>
    )}
  </PasswordInfoWrapper>
);

PasswordInfo.propTypes = {
  isDirectoryUser: PropTypes.bool.isRequired,
  passwordExpirationDays: PropTypes.number.isRequired,
  passwordExpires: PropTypes.bool.isRequired,
  passwordChangedDays: PropTypes.number.isRequired
};

export default PasswordInfo;
