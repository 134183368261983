import styled from "styled-components";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";

const COLUMN_FONT_SIZE = 13;
const COLUMN_LINE_HEIGHT = 1.23;
const COLUMN_VISIBLE_ROWS = 2;

const TableColumn = styled(Responsive)`
  font-size: ${COLUMN_FONT_SIZE}px;
  letter-spacing: 0.7px;
  overflow: hidden;
  padding: 0 16px 0 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: left;
  line-height: ${COLUMN_LINE_HEIGHT};

  span {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${COLUMN_VISIBLE_ROWS};
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: ${COLUMN_FONT_SIZE *
      COLUMN_LINE_HEIGHT *
      COLUMN_VISIBLE_ROWS}px; //fallback for browsers not supporting -webkit-line-clamp (i.e. IE)
  }
`;

const FactorColumn = styled(TableColumn)`
  & > span {
    padding-left: 12px;
  }
`;

const DeviceColumn = styled(TableColumn)`
  flex-direction: column;
`;

const LastUsedColumn = styled(TableColumn)``;

const UsedForColumn = styled(TableColumn)`
  overflow: visible;
`;

export { FactorColumn, DeviceColumn, LastUsedColumn, UsedForColumn };
