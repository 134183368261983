import React from "react";
import { withRouter } from "react-router-dom";

export const loadAnalytics = (mainCode, secondaryCode) => {
  if (!mainCode) {
    return;
  }

  let script = document.createElement("script");
  script.src = `//www.google-analytics.com/analytics.js`;
  document.head.appendChild(script);

  window.ga =
    window.ga ||
    function() {
      (window.ga.q = window.ga.q || []).push(arguments);
    };
  window.ga.l = +new Date();

  window.ga("create", mainCode, "auto");
  window.ga("send", "pageview");

  if (secondaryCode) {
    window.ga("create", secondaryCode, "auto", "secondaryTracker");
    window.ga("secondaryTracker.send", "pageview");
  }
};

export const sendGAEvent = (category, action, label = null, value = null) => {
  if (typeof window.ga === "function") {
    window.ga("send", {
      hitType: "event",
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value
    });
  }
};

class GoogleAnalyticsComponent extends React.Component {
  // inspired by https://stackoverflow.com/questions/34836500/how-to-set-up-google-analytics-for-react-router
  // especially by the answer: https://stackoverflow.com/a/47385875
  componentWillUpdate({ location, history }) {
    if (
      typeof window.ga !== "function" ||
      location.pathname === this.props.location.pathname
    ) {
      // don't log identical link clicks (nav links likely)
      return;
    }

    if (history.action === "PUSH") {
      window.ga("send", "pageview", location.pathname);
    }
  }

  render() {
    return null;
  }
}

export const GoogleAnalytics = withRouter(GoogleAnalyticsComponent);
