import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";

import ScreenHeader from "../ScreenHeader";
import ScreenHeaderControl from "../ScreenHeaderControl";

import AuthenticationFactors from "./AuthenticationFactors";
import { NARROW_VIEW_LIMIT } from "../../../constants/Env";
import { commonMessages } from "../../../translations/commonMessages";

const AuthenticationWrapper = styled.div`
  -webkit-tap-highlight-color: transparent;
`;

const ScreenDescription = styled.div`
  padding: 0 32px;
  margin-bottom: 24px;

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    padding: 0 20px;
  }
`;

const ResponsiveSpacer = styled.div`
  height: 20px;
`;

const AuthenticationScreen = () => (
  <AuthenticationWrapper>
    <Responsive minWidth={NARROW_VIEW_LIMIT + 1}>
      <ScreenHeader>
        <ScreenHeaderControl>
          <FormattedMessage {...commonMessages.securityFactors} />
        </ScreenHeaderControl>
      </ScreenHeader>
    </Responsive>

    <Responsive maxWidth={NARROW_VIEW_LIMIT}>
      <ResponsiveSpacer />
    </Responsive>

    <ScreenDescription>
      <FormattedMessage
        defaultMessage="Manage security factors to verify your identity when you sign in or reset your password."
        id="securityFactorsDescription"
      />
    </ScreenDescription>

    <AuthenticationFactors />
  </AuthenticationWrapper>
);

export default AuthenticationScreen;
