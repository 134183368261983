import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";

import { COLORS } from "@onelogin/react-components";
import { NARROW_VIEW_LIMIT } from "../constants/Env";

const MenuItemWrapper = styled(NavLink)`
  display: block;
  text-decoration: none;
  padding: 14px 14px 14px 0px;
  position: relative;

  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${COLORS.GRAY_COLORS.GRAY_35};

  &.${props => props.activeClassName} {
    background: #fafcfc;
  }

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    padding: 19px 14px 19px 0px;
    border-bottom: 1px solid #edeff0;
    background: #ffffff;
    font-size: 16px;
    &.${props => props.activeClassName} {
      background: #ffffff;
    }
  }

  &:hover {
    background: #f3f3f4;
  }
`;

const MenuItemContent = styled.div`
  padding-left: 10px;
  border-left: ${props =>
    props.active ? "solid 2px #016B91" : "solid 2px transparent"};
  height: 16px;
  margin-left: 32px;
  font-weight: ${props => (props.active ? "bold" : "")};

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    height: 20px;
    margin-left: 10px;
  }
`;

const MenuItem = ({ active, linkPath, children, onClick }) => {
  return (
    <MenuItemWrapper
      to={linkPath}
      onClick={onClick}
      activeClassName={`active`}
      exact
    >
      <MenuItemContent active={active}>{children}</MenuItemContent>
    </MenuItemWrapper>
  );
};

MenuItem.propTypes = {
  active: PropTypes.bool,
  linkPath: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default withRouter(MenuItem);
