import React from "react";
import { FormattedMessage } from "react-intl";

export const errorTypes = {
  FETCH_FACTORS: "FETCH_FACTORS"
};

export const getErrorMessage = type => {
  switch (type) {
    case errorTypes.FETCH_FACTORS:
      return (
        <FormattedMessage
          defaultMessage="Failed to fetch factors, please try again later"
          id="factorsFetchFailure"
        />
      );
    default:
      return (
        <FormattedMessage
          defaultMessage="Failed to fetch mfa token"
          id="factorMfaTokenFailure"
        />
      );
  }
};
