const readAsArrayBuffer = data =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = event => {
      resolve(fileReader.result);
    };

    fileReader.onerror = event => {
      fileReader.abort();
      reject(event);
    };

    fileReader.readAsArrayBuffer(data);
  });

const readAsDataURL = data =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = event => {
      resolve(fileReader.result);
    };

    fileReader.onerror = event => {
      fileReader.abort();
      reject(event);
    };

    fileReader.readAsDataURL(data);
  });

//This method looks for the exif data in the jpeg that contains
//the image orientation data. For example if an image is taken
//with a camera phone, while the phone is held in the portrait
//orientation, the photo data will need to be rotated to display
//correctly in a browser.
// Exif Spec http://www.exif.org/Exif2-2.PDF
// Jpeg Markers http://www.digicamsoft.com/itu/itu-t81-36.html
const getImageOrientation = async fileData => {
  // retrieve the jpeg header from the file
  const result = await readAsArrayBuffer(fileData.slice(0, 64 * 1024));

  var view = new DataView(result);

  //0xffd8 indicates the start of an image in the jpeg spec
  if (view.getUint16(0, false) !== 0xffd8) return -2;

  const length = view.byteLength;
  let offset = 2;

  while (offset < length) {
    const marker = view.getUint16(offset, false);
    offset += 2;
    //Application Segments, the APP1 segment marker 0xFFE1
    if (marker === 0xffe1) {
      //verify that we have a valid Exif header signature
      //The header will contain 'Exif' or the hex bytes [0x45,0x78,0x69,0x66]
      if (view.getUint32((offset += 2), false) !== 0x45786966) {
        return -1;
      }
      //Determine the byte order, 0x4949 ascii 'II' for little endian and 0x4D4D ascii 'MM' for big endian
      const little = view.getUint16((offset += 6), false) === 0x4949;

      //read the off set for the exif data
      offset += view.getUint32(offset + 4, little);

      //read the number of tags available
      const tags = view.getUint16(offset, little);
      offset += 2;

      //Search the tags for for the "Orientation of image" tag (0x0112) and return the value stored
      for (var i = 0; i < tags; i++)
        if (view.getUint16(offset + i * 12, little) === 0x0112)
          return view.getUint16(offset + i * 12 + 8, little);
    }
    //all jpeg segment markers start with 0xff, followed by a byte of data,
    //if that is not found then we have gone beyond the segment markers in the jpeg file
    //and we should stop searching
    else if ((marker & 0xff00) !== 0xff00) break;
    else offset += view.getUint16(offset, false);
  }
  return -1;
};

const ImagePromise = src =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };

    img.onerror = event => {
      reject(event);
    };

    img.src = src;
  });

export const getOrientedImageData = async fileData => {
  const promiseResults = await Promise.all([
    ImagePromise(await readAsDataURL(fileData)),
    getImageOrientation(fileData)
  ]);

  const img = promiseResults[0];
  const srcOrientation = promiseResults[1];

  const width = img.width,
    height = img.height,
    canvas = document.createElement("canvas"),
    ctx = canvas.getContext("2d");

  // set proper canvas dimensions before transform & export
  if (4 < srcOrientation && srcOrientation < 9) {
    canvas.width = height;
    canvas.height = width;
  } else {
    canvas.width = width;
    canvas.height = height;
  }

  // transform context before drawing image
  switch (srcOrientation) {
    case 2:
      ctx.transform(-1, 0, 0, 1, width, 0);
      break;
    case 3:
      ctx.transform(-1, 0, 0, -1, width, height);
      break;
    case 4:
      ctx.transform(1, 0, 0, -1, 0, height);
      break;
    case 5:
      ctx.transform(0, 1, 1, 0, 0, 0);
      break;
    case 6:
      ctx.transform(0, 1, -1, 0, height, 0);
      break;
    case 7:
      ctx.transform(0, -1, -1, 0, height, width);
      break;
    case 8:
      ctx.transform(0, -1, 1, 0, 0, width);
      break;
    default:
      break;
  }

  // draw image
  ctx.drawImage(img, 0, 0);

  // export base64
  return canvas.toDataURL();
};

export const getCroppedImageBlob = async (src, croppedAreaPixels) => {
  const image = await ImagePromise(src);

  const canvas = document.createElement("canvas");
  canvas.width = croppedAreaPixels.width;
  canvas.height = croppedAreaPixels.height;
  const ctx = canvas.getContext("2d");

  //when image is smaller than predefined crop area (either width or height) do some limiting here to avoid accessing negative positions in original image (which fails in IE)
  let targetY = 0;
  let sourceY = croppedAreaPixels.y;
  let sourceHeight = croppedAreaPixels.height;
  if (image.height < croppedAreaPixels.height) {
    targetY = Math.floor((croppedAreaPixels.height - image.height) / 2);
    sourceY = 0;
    sourceHeight = image.height;
  }

  let targetX = 0;
  let sourceX = croppedAreaPixels.x;
  let sourceWidth = croppedAreaPixels.width;
  if (image.width < croppedAreaPixels.width) {
    targetX = Math.floor((croppedAreaPixels.width - image.width) / 2);
    sourceX = 0;
    sourceWidth = image.width;
  }

  ctx.drawImage(
    image,
    sourceX,
    sourceY,
    sourceWidth,
    sourceHeight,
    targetX,
    targetY,
    Math.min(croppedAreaPixels.width, sourceWidth),
    Math.min(croppedAreaPixels.height, sourceHeight)
  );

  return new Promise(resolve => {
    canvas.toBlob(blob => {
      resolve(blob);
    }, "image/jpeg");
  });
};
