import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  ModalDialog,
  Icon,
  Button,
  FactorList
} from "@onelogin/react-components";
import { SMALL_MODAL_SIZE, MOBILE_VIEW_LIMIT } from "../../../constants/Env";
import { commonMessages } from "../../../translations/commonMessages";

const WarningIcon = styled.div`
  margin-right: 16px;

  @media screen and (max-width: ${MOBILE_VIEW_LIMIT}) {
    margin-right: 0;
    margin-bottom: 21px;
  }
`;

const WarningMessage = styled.div`
  width: 100%;
  font-size: 14px;

  @media screen and (max-width: ${MOBILE_VIEW_LIMIT}) {
    font-size: 16px;
  }
`;

const RemoveWarning = ({
  deviceToRemove,
  devices,
  onClose,
  onRemove,
  onSecondaryFactorSelect,
  onAddNewFactor,
  isMfaRequired,
  isAssumed
}) => {
  const factors = devices
    .filter(device => device.primary !== true)
    .filter(device => device.isLoginDevice)
    .map(device => ({
      name: device.factorName,
      id: device.id,
      typeId: device.factorTypeId,
      customIconUrl: device.customIconUrl
    }));

  const needsNewPrimaryFromExisting =
    deviceToRemove.primary === true && factors.length > 0;
  const needsAddNewPrimary =
    isMfaRequired && deviceToRemove.primary === true && factors.length === 0;

  let header = deviceToRemove.factorName;
  let warningMessage = (
    <FormattedMessage
      id="removeFactorWarning"
      defaultMessage={`Are you sure you want to remove {factor}?`}
      values={{ factor: <b>{deviceToRemove.factorName}</b> }}
    />
  );
  let actions = (
    <ModalDialog.Actions>
      <Button
        size="medium"
        onClick={onClose}
        data-testid="confirm-cancel-button"
      >
        <FormattedMessage {...commonMessages.cancel} />
      </Button>
      <Button
        size="medium"
        look="primary"
        onClick={onRemove}
        data-testid="confirm-remove-factor-button"
      >
        <FormattedMessage {...commonMessages.remove} />
      </Button>
    </ModalDialog.Actions>
  );

  if (needsAddNewPrimary) {
    header = (
      <FormattedMessage
        defaultMessage="New primary factor"
        id="newPrimaryFactor"
      />
    );
    warningMessage = (
      <FormattedMessage
        id="addNewPrimaryBeforeRemove"
        defaultMessage="Before you remove {deviceName}, you need to enroll a new primary factor."
        values={{
          deviceName: <b>{deviceToRemove.factorName}</b>
        }}
      />
    );
    actions = (
      <ModalDialog.Actions>
        <Button
          size="medium"
          onClick={onClose}
          data-testid="confirm-cancel-button"
        >
          <FormattedMessage {...commonMessages.cancel} />
        </Button>
        <Button
          size="medium"
          look="primary"
          onClick={onAddNewFactor}
          data-testid="confirm-enroll-factor-button"
          disabled={isAssumed}
        >
          <FormattedMessage
            defaultMessage="Enroll new primary factor"
            id="enrollNewFactor"
          />
        </Button>
      </ModalDialog.Actions>
    );
  } else if (needsNewPrimaryFromExisting) {
    header = (
      <FormattedMessage
        defaultMessage="New primary factor"
        id="newPrimaryFactor"
      />
    );
    warningMessage = (
      <FormattedMessage
        id="selectNewPrimary"
        defaultMessage="Before you remove {deviceName}, select your new primary factor."
        values={{
          deviceName: <b>{deviceToRemove.factorName}</b>
        }}
      />
    );
    actions = (
      <>
        <FactorList factors={factors} onSelect={onSecondaryFactorSelect} />
        <ModalDialog.Actions>
          <Button size="medium" onClick={onClose}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
        </ModalDialog.Actions>
      </>
    );
  }
  return (
    <ModalDialog fixedWidth={SMALL_MODAL_SIZE} onClose={onClose}>
      <ModalDialog.Header>{header}</ModalDialog.Header>
      <ModalDialog.Content>
        <WarningIcon>
          <Icon name="Warning-24" size="44px" alt="" />
        </WarningIcon>

        <WarningMessage data-testid="remove-warning-message">
          {warningMessage}
        </WarningMessage>
      </ModalDialog.Content>
      {actions}
    </ModalDialog>
  );
};

RemoveWarning.propTypes = {
  //all existing devices
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      factorTypeId: PropTypes.number.isRequired,
      factorName: PropTypes.string.isRequired,
      primary: PropTypes.bool.isRequired,
      customIconUrl: PropTypes.string,
      createdAt: PropTypes.string,
      behavior: PropTypes.string,
      lastUsed: PropTypes.string,
      details: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          os: PropTypes.shape({
            name: PropTypes.string,
            version: PropTypes.string
          }),
          device: PropTypes.shape({
            vendor: PropTypes.string,
            model: PropTypes.string,
            type: PropTypes.string
          })
        })
      ])
    })
  ),
  //device selected to remove
  deviceToRemove: PropTypes.shape({
    factorName: PropTypes.string.isRequired,
    primary: PropTypes.bool.isRequired
  }),
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSecondaryFactorSelect: PropTypes.func.isRequired,
  onAddNewFactor: PropTypes.func.isRequired,
  isAssumed: PropTypes.bool
};

export default RemoveWarning;
