import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { COLORS, Icon } from "@onelogin/react-components";
import TableRow from "./TableRow";
import { find, propEq, sort } from "ramda";
import Bowser from "bowser";

import { injectIntl } from "react-intl";

import { NARROW_VIEW_LIMIT } from "../../../constants/Env";
import { commonMessages } from "../../../translations/commonMessages";

const browser = Bowser.getParser(window.navigator.userAgent);
const browserPlatform = browser.getPlatformType();

const CLIPPED_BUTTON_WIDTH = 24;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    margin-bottom: 0;
  }
`;

const NoDataContent = styled.div`
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: ${COLORS.GRAY_COLORS.GRAY_68};
`;

const NoDataTableRow = styled.div`
  width: 100%;
  height: 70px;

  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid ${COLORS.GRAY_COLORS.GRAY_94};
  border-top: 0;
`;

const TableClippedContainer = styled.button`
  background: none;
  border: none;
  padding: 0;
  height: 24px;
  width: ${CLIPPED_BUTTON_WIDTH}px;
`;

const TableClippedContainerPlaceholder = styled.div`
  width: ${CLIPPED_BUTTON_WIDTH}px;
`;

const IconWithChevron = styled(Icon).attrs({
  icon: "Chevron_right-16",
  size: "16"
})`
  cursor: pointer;
`;

class ResponsiveTable extends React.Component {
  constructor(props) {
    super(props);

    this.width = undefined;
    this.sortedByPrio = undefined;
    this.resizeTimer = undefined;
    this.tableContainerRef = React.createRef();

    this.state = {
      rowStructure: undefined
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
    this.sortColumnsByPrio();
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
    clearTimeout(this.resizeTimer);
  }

  componentWillReceiveProps(newProps) {
    if (newProps !== this.props) {
      this.updateDimensions(true);
    }
  }

  onResize = () => {
    clearTimeout(this.resizeTimer);

    //debounce dimensions recounting
    this.resizeTimer = setTimeout(() => {
      this.updateDimensions();
    }, 50);
  };

  sortColumnsByPrio = () => {
    //sort table columns by priority so we can use this when counting what fits into table
    const { tableStructure } = this.props;

    const sortedByPrio = sort((a, b) => {
      if (a.priority === b.priority) {
        return a.weight > b.weight ? -1 : 1;
      }
      return a.priority > b.priority ? -1 : 1;
    }, tableStructure.columns);

    this.sortedByPrio = sortedByPrio;
  };

  updateDimensions = forceRecalulate => {
    const rect = this.tableContainerRef.current.getBoundingClientRect();

    if (forceRecalulate === true || this.width !== rect.width) {
      this.width = rect.width;
      this.calculateVisibleColumns();
    }
  };

  calculateVisibleColumns = () => {
    const { tableStructure } = this.props;

    let widthSum = CLIPPED_BUTTON_WIDTH, //Always reserve place for expander column
      visibleHeaderCells = [],
      visibleKeys = {},
      totalWeight = 0;

    //keep adding columns until sum of their minWidths fits into table width
    for (let column of this.sortedByPrio) {
      widthSum += column.minWidth || 0;

      if (widthSum <= this.width) {
        visibleHeaderCells.push(column);
        visibleKeys[column.key] = column;
        totalWeight += column.weight || 0;
      }
    }

    //filter out columns that are not visible - non-sorted columns here!
    //also count column width here
    visibleHeaderCells = tableStructure.columns
      .filter(column => visibleKeys[column.key] !== undefined)
      .map(column => ({
        ...column,
        width:
          column.width ||
          (column.weight && (column.weight / totalWeight) * 100 + "%")
      }));

    //we want to show expander (chevron icon) when there's more columns then currently visible
    const isClipped = visibleHeaderCells.length < tableStructure.columns.length;
    const isExpanderAlwaysVisible =
      tableStructure.expander && tableStructure.expander.isAlwaysVisible;
    if (isClipped || isExpanderAlwaysVisible) {
      visibleHeaderCells.push({
        width: `${CLIPPED_BUTTON_WIDTH}px`,
        content: <TableClippedContainerPlaceholder />
      });
    }

    //filter out data of columns that are not visible
    const bodyRows = tableStructure.data.map(datum => {
      const cells = datum.cells
        .filter(cell => visibleKeys[cell.key] !== undefined)
        .map(cell => {
          if (cell.takesAllSpace !== true) {
            const relatedColumn = find(
              propEq("key", cell.key),
              visibleHeaderCells
            );
            return {
              ...cell,
              width: relatedColumn && relatedColumn.width
            };
          } else {
            return {
              ...cell,
              width: "100%"
            };
          }
        });

      if ((isClipped && tableStructure.expander) || isExpanderAlwaysVisible) {
        const idItem = this.getCellBy(
          tableStructure.expander.idKey,
          datum.cells
        );

        cells.push({
          width: `${CLIPPED_BUTTON_WIDTH}px`,
          content:
            idItem && idItem.content !== undefined ? (
              <TableClippedContainer
                data-testid="show-details"
                aria-label={this.props.intl.formatMessage(
                  commonMessages.showDetails
                )}
                onClick={this.getShowClipped(datum.cells)}
              >
                <IconWithChevron />
              </TableClippedContainer>
            ) : (
              <TableClippedContainerPlaceholder />
            )
        });
      }

      return { cells, meta: datum.meta };
    });

    this.setState({
      rowStructure: {
        visibleHeaderCells,
        bodyRows,
        isClipped
      }
    });
  };

  getCellBy = (key, datum) => {
    const idItem = find(propEq("key", key), datum);

    return idItem;
  };

  getShowClipped = datum => {
    const { tableStructure } = this.props;

    const isExpander = !!tableStructure.expander;

    if (!isExpander) {
      return undefined;
    }

    const idItem = this.getCellBy(tableStructure.expander.idKey, datum);

    return idItem && idItem.content
      ? () => {
          tableStructure.expander.onClick(idItem.content);
        }
      : undefined;
  };

  render() {
    const { rowStructure } = this.state;

    return (
      <TableContainer ref={this.tableContainerRef}>
        {rowStructure !== undefined && (
          <>
            <TableRow
              isHeader
              cells={rowStructure.visibleHeaderCells}
              isClipped={rowStructure.isClipped}
              hideTopBorderMobile={this.props.hideTopBorderMobile}
            />
            {rowStructure.bodyRows.length > 0 ? (
              rowStructure.bodyRows.map((row, index) => {
                return (
                  <TableRow
                    key={index}
                    isClipped={rowStructure.isClipped}
                    onClick={
                      browserPlatform.mobile || browserPlatform.tablet
                        ? this.getShowClipped(row)
                        : null
                    }
                    {...(row.meta || {})}
                    cells={row.cells}
                  />
                );
              })
            ) : (
              <NoDataTableRow>
                <NoDataContent>{this.props.noDataMessage}</NoDataContent>
              </NoDataTableRow>
            )}
          </>
        )}
      </TableContainer>
    );
  }
}

ResponsiveTable.propTypes = {
  tableStructure: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.node,
        key: PropTypes.string.isRequired,
        minWidth: PropTypes.number,
        priority: PropTypes.number.isRequired,
        weight: PropTypes.number
      })
    ).isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        cells: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string.isRequired,
            content: PropTypes.any.isRequired
          })
        ),
        meta: PropTypes.shape({
          isHighlighted: PropTypes.bool,
          rowTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        })
      })
    ).isRequired,
    expander: PropTypes.shape({
      onClick: PropTypes.func,
      idKey: PropTypes.string
    })
  }),
  noDataMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hideTopBorderMobile: PropTypes.bool
};

export default injectIntl(ResponsiveTable);
