import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { COLORS } from "@onelogin/react-components";
import PropTypes from "prop-types";

import ProfileDialog from "../../common/ProfileDialog/ProfileDialog";
import Moment from "../../MomentWrapper";
import { commonMessages } from "../../../translations/commonMessages";
import { SMALL_MODAL_SIZE } from "../../../constants/Env";

const CurrentMarker = styled.span`
  font-size: 12px;
  padding: 2px 8px;
  margin-left: 10px;
  border-radius: 12px;
  color: #ffffff;
  background-color: ${COLORS.STATUS_COLORS.SUCCESS};
`;

const BrowserRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const BrowserDetailsDialog = ({ browser, onClose, locale }) => {
  const { browserInfo, osInfo, lastUsed, expirationDate, current } = browser;
  const table = {
    header: <FormattedMessage {...commonMessages.trustedBrowsers} />,
    rows: [
      {
        title: (
          <>
            <FormattedMessage {...commonMessages.browser} />
          </>
        ),
        info: (
          <BrowserRow>
            <>
              {browserInfo.name}
              <br />
              {`${osInfo.name} ${osInfo.version}`}
            </>
            {current && (
              <CurrentMarker>
                <FormattedMessage {...commonMessages.current} />
              </CurrentMarker>
            )}
          </BrowserRow>
        )
      },
      {
        title: <FormattedMessage {...commonMessages.lastUsed} />,
        info: (
          <Moment fromNow locale={locale}>
            {lastUsed}
          </Moment>
        )
      },
      {
        title: <FormattedMessage {...commonMessages.expireDate} />,
        info: (
          <Moment fromNow locale={locale}>
            {expirationDate}
          </Moment>
        )
      }
    ]
  };

  return (
    <ProfileDialog
      onClose={onClose}
      table={table}
      fixedWidth={SMALL_MODAL_SIZE}
    />
  );
};

const browserInfoShape = PropTypes.shape({
  name: PropTypes.string.isRequired
});

const osInfoShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired
});

const browserShape = PropTypes.shape({
  browserInfo: browserInfoShape,
  current: PropTypes.bool.isRequired,
  expirationDate: PropTypes.string,
  lastUsed: PropTypes.string.isRequired,
  osInfo: osInfoShape
});

BrowserDetailsDialog.propTypes = {
  browser: browserShape,
  locale: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

export default BrowserDetailsDialog;
