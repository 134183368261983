import React from "react";
import { FormattedMessage } from "react-intl";
import { join, keys, map } from "ramda";
import PropTypes from "prop-types";
import { Button, ModalDialog } from "@onelogin/react-components";
import styled from "styled-components";

import { MIN_SUPPORTED_BROWSERS } from "../constants/Env";
import AlertDialogContent from "./AlertDialogContent";
import { commonMessages } from "../translations/commonMessages";

const BROWSER_NAMES = {
  ie: "Microsoft Internet Explorer",
  edge: "Microsoft Edge",
  firefox: "Firefox",
  chrome: "Chrome",
  safari: "Safari"
};

const Instruction = styled.div`
  margin-top: 16px;
`;

export const UnsupportedBrowser = ({ myBrowser, onOk, onCancel }) => {
  const supportedBrowsers = join(
    ", ",
    map(browser => {
      return `${BROWSER_NAMES[browser]} ${MIN_SUPPORTED_BROWSERS[
        browser
      ].replace(/[<>=]/g, "")}+`;
    }, keys(MIN_SUPPORTED_BROWSERS))
  );

  const message = (
    <div>
      <FormattedMessage
        id={"yourBrowser"}
        defaultMessage="Your browser: {browser}"
        values={{ browser: myBrowser }}
      />
      <br />
      <FormattedMessage
        id={"supportedBrowsers"}
        defaultMessage="Supported browsers: {browsers}"
        values={{ browsers: supportedBrowsers }}
      />
    </div>
  );

  return (
    <ModalDialog data-testid="unsupported-browser">
      <ModalDialog.Header>
        <FormattedMessage
          id={"unsupportedBrowser"}
          defaultMessage="Unsupported Browser"
        />
      </ModalDialog.Header>
      <ModalDialog.Content>
        <AlertDialogContent>
          <FormattedMessage
            id={"unsupportedBrowserHeading"}
            defaultMessage="OneLogin Profile may not work properly in your browser"
          />
        </AlertDialogContent>
        <Instruction>{message}</Instruction>
      </ModalDialog.Content>
      <ModalDialog.Actions>
        <Button onClick={onCancel}>
          <FormattedMessage {...commonMessages.logOut} />
        </Button>
        <Button onClick={onOk} look="primary">
          <FormattedMessage
            id={"proceedAnyway"}
            defaultMessage="Proceed anyway"
          />
        </Button>
      </ModalDialog.Actions>
    </ModalDialog>
  );
};

UnsupportedBrowser.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  myBrowser: PropTypes.string.isRequired
};

export default UnsupportedBrowser;
