import * as types from "../constants/ActionTypes";

const initialState = {
  sending: undefined,
  success: undefined,
  redirectTo: undefined
};

function helpRequestReducer(state = initialState, action) {
  switch (action.type) {
    case types.HELP_SUBMIT_REQUEST:
      return {
        ...state,
        sending: true
      };
    case types.HELP_SUBMIT_SUCCESS:
      return {
        ...state,
        sending: false,
        success: true
      };
    case types.HELP_SUBMIT_FAILED:
      return {
        ...state,
        sending: false,
        success: false
      };
    case types.HELP_SUBMIT_REQUEST_RESET:
      return {
        ...state,
        sending: false,
        success: false
      };
    case types.HELP_SUBMIT_REDIRECT_TO:
      return {
        ...state,
        redirectTo: action.redirectTo
      };
    default:
      return state;
  }
}

export default helpRequestReducer;
