import * as types from "../constants/ActionTypes";
import * as s3Api from "./../api/s3Api.js";
import * as localStorageConstants from "../constants/localStorageConstants";
import { setProceedUnsupportedBrowser } from "../storage/localStorage";

export const fetchBranding = () => async (dispatch, getState) => {
  try {
    const data = await s3Api.fetchBranding();

    dispatch({
      type: types.FETCH_BRANDING_RECEIVED,
      data,
      toSave: {
        [localStorageConstants.BRANDING]: { ...data }
      }
    });
  } catch (error) {
    // TODO: here I believe we should report to airbrake
    dispatch({
      type: types.FETCH_BRANDING_ERROR
    });
  }
};

export const extensionDetected = version => dispatch => {
  dispatch({
    type: types.EXTENSION_DETECTED,
    version
  });
};

export const extensionNotDetected = () => dispatch => {
  dispatch({
    type: types.EXTENSION_NOT_DETECTED
  });
};

export const proceedWithUnsupportedBrowser = isDeprecatedBrowser => dispatch => {
  dispatch({
    type: types.PROCEED_UNSUPPORTED_BROWSER
  });

  return Promise.resolve(setProceedUnsupportedBrowser(isDeprecatedBrowser));
};
