/**
 * based on https://github.com/alexcastillo/redux-airbrake/blob/master/src/index.js
 * more info on middlewares including testing: http://jonnyreeves.co.uk/2016/redux-middleware/
 */
export default airbrake => {
  if (!airbrake) {
    return store => next => action => {
      return next(action);
    };
  }

  return store => next => action => {
    try {
      return next(action);
    } catch (error) {
      airbrake.notify(error, {
        reduxAction: action
      });

      throw error;
    }
  };
};
