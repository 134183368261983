import React from "react";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

import { COLORS, Icon } from "@onelogin/react-components";
import { SMALL_MODAL_SIZE } from "../../../constants/Env";
import { commonMessages } from "../../../translations/commonMessages";
import Moment from "../../MomentWrapper";
import ProfileDialog from "../../common/ProfileDialog/ProfileDialog";
import MfaDetails from "./details/MfaDetails";
import {
  YubiKey,
  VipAccess,
  OLProtect,
  GoogleAuth,
  OLVoice,
  OLEmail
} from "../../../constants/OtpFactorTypes";
import {
  LOGIN_ICON,
  PW_RESET_ICON,
  APP_ICON,
  FORBID_ICON
} from "../../../constants/IconConstants";

const ICON_IMAGE_SIZE = 24;
const ICON_MARGIN = 10;

const messages = defineMessages({
  loginDevice: {
    id: "loginDevice",
    defaultMessage: "Login device"
  },
  pwResetDevice: {
    id: "pwResetDevice",
    defaultMessage: "Password reset device"
  },
  appDevice: {
    id: "appDevice",
    defaultMessage: "App device"
  },
  inactiveDevice: {
    id: "inactiveDevice",
    defaultMessage: "Inactive device"
  }
});

const DeviceList = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeviceListRow = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  height: 36px;
  font-size: 14px;
  color: ${COLORS.TEXT_COLORS.DEFAULT};
  flex-shrink: 0;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex: 0 0 ${ICON_IMAGE_SIZE + ICON_MARGIN}px;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 auto;
  padding-right: ${ICON_MARGIN}px;
  overflow: hidden;
`;

// These factors have useful identifiers which can be added to the Show Details view
// Any other factor won't need to have the identifier displayed
const credentialIDFactors = new Set([
  YubiKey,
  VipAccess,
  OLProtect,
  GoogleAuth,
  OLVoice,
  OLEmail
]);

const DeviceDetailsDialog = ({ device, onClose, locale, intl }) => {
  const {
    factorName,
    createdAt,
    details,
    lastUsed,
    identifier,
    factorTypeId,
    isActive,
    isLoginDevice,
    isPasswordResetDevice,
    isAppDevice
  } = device;

  const getUsedFor = () => {
    return (
      <DeviceList>
        {isLoginDevice && (
          <DeviceListRow>
            <IconWrapper>
              <Icon
                name={LOGIN_ICON}
                size="24px"
                alt={intl.formatMessage(messages.loginDevice)}
              />
            </IconWrapper>
            <TextWrapper>
              <FormattedMessage defaultMessage="Login" id="loginEvent" />
            </TextWrapper>
          </DeviceListRow>
        )}
        {isPasswordResetDevice && (
          <DeviceListRow>
            <IconWrapper>
              <Icon
                name={PW_RESET_ICON}
                size="24px"
                alt={intl.formatMessage(messages.pwResetDevice)}
              />
            </IconWrapper>
            <TextWrapper>
              <FormattedMessage
                defaultMessage="Password Reset"
                id="passwordReset"
              />
            </TextWrapper>
          </DeviceListRow>
        )}
        {isAppDevice && (
          <DeviceListRow>
            <IconWrapper>
              <Icon
                name={APP_ICON}
                size="24px"
                alt={intl.formatMessage(messages.appDevice)}
              />
            </IconWrapper>
            <TextWrapper>
              <FormattedMessage defaultMessage="App" id="app" />
            </TextWrapper>
          </DeviceListRow>
        )}
        {!isActive && (
          <DeviceListRow>
            <IconWrapper>
              <Icon
                name={FORBID_ICON}
                size="24px"
                alt={intl.formatMessage(messages.inactiveDevice)}
              />
            </IconWrapper>
            <TextWrapper>
              <FormattedMessage defaultMessage="Inactive" id="inactive" />
            </TextWrapper>
          </DeviceListRow>
        )}
      </DeviceList>
    );
  };

  const table = {
    header: factorName,
    rows: [
      {
        title: <FormattedMessage {...commonMessages.usedFor} />,
        info: getUsedFor()
      },
      {
        title: <FormattedMessage {...commonMessages.lastUsed} />,
        info: <Moment locale={locale}>{lastUsed}</Moment>
      },
      {
        title: <FormattedMessage {...commonMessages.configuredOn} />,
        info: <Moment locale={locale}>{createdAt}</Moment>
      }
    ]
  };

  if (details) {
    table.rows.push({
      title: <FormattedMessage {...commonMessages.details} />,
      info: <MfaDetails device={device} />
    });
  }

  if (credentialIDFactors.has(factorTypeId) && identifier) {
    table.rows.push({
      title: (
        <FormattedMessage
          id={"identifier"}
          defaultMessage={"Credential ID / Identifier"}
        />
      ),
      info: identifier
    });
  }

  return (
    <ProfileDialog
      onClose={onClose}
      table={table}
      fixedWidth={SMALL_MODAL_SIZE}
    />
  );
};

const detailsShape = PropTypes.shape({
  os: PropTypes.shape({
    name: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired
  }),
  device: PropTypes.shape({
    vendor: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  })
});

const deviceShape = PropTypes.shape({
  createdAt: PropTypes.string.isRequired,
  details: PropTypes.oneOfType([
    PropTypes.string,
    detailsShape
  ]),
  factorName: PropTypes.string.isRequired,
  factorTypeId: PropTypes.number.isRequired,
  identifier: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  isAppDevice: PropTypes.bool,
  isLoginDevice: PropTypes.bool,
  isPasswordResetDevice: PropTypes.bool,
  lastUsed: PropTypes.string.isRequired
});

DeviceDetailsDialog.propTypes = {
  device: PropTypes.oneOfType([PropTypes.shape({}), deviceShape]).isRequired,
  onClose: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired
};

export default injectIntl(DeviceDetailsDialog);
