import styled from "styled-components";
import { COLORS } from "@onelogin/react-components";

export default styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-top: 100px;
  color: ${COLORS.GRAY_COLORS.GRAY_68};
`;
