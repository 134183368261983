import { nth, split, replace, toUpper } from "ramda";
import Bowser from "bowser";

import * as types from "../constants/ActionTypes";

const BROWSER_DATA = {
  chrome: {
    name: "Chrome",
    link: "https://chrome.google.com/webstore/detail/ioalpmibngobedobkmbhgmadaphocjdn"
  },
  safari: {
    name: "Safari",
    link: "https://apps.apple.com/us/app/onelogin-for-safari/id1475824389?mt=12"
  },
  ie: {
    name: "Internet Explorer",
    link: "https://app.onelogin.com/onelogin-downloads/extensions/ie/onelogin_ie.exe"
  },
  edge: {
    name: "Microsoft Edge",
    link: "https://microsoftedge.microsoft.com/addons/detail/onelogin-for-edge/mpnjdddkcbccdhgahjdcjppglaecajdj"
  },
  firefox: {
    name: "Firefox",
    link: "https://app.onelogin.com/onelogin-downloads/extensions/firefox/onelogin.xpi"
  }
};

const getBrowserExtensionData = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  switch (true) {
    case browser.is("chrome", true):
      return BROWSER_DATA.chrome;
    case browser.is("safari", true):
      return BROWSER_DATA.safari;
    case browser.is("firefox", true):
      return BROWSER_DATA.firefox;
    case browser.is("edge", true):
      return BROWSER_DATA.edge;
    case browser.is("ie", true):
      return BROWSER_DATA.ie;
    default:
      return {};
  }
};

const initialState = {
  detected: false,
  version: undefined,
  extensionData: getBrowserExtensionData()
};

function extension(state = initialState, action) {
  switch (action.type) {
    case types.EXTENSION_DETECTED:
      const versionParts = split("_", action.version);
      const version = nth(-1, versionParts);
      const capitalize = replace(/^./, toUpper);
      const name = nth(0, versionParts);

      const detectedName = capitalize(
        name === "ie" ? "Internet Explorer" : name
      );

      return {
        ...state,
        detected: true,
        detectedName,
        version
      };
    case types.EXTENSION_NOT_DETECTED:
      return {
        ...state,
        detected: false
      };
    default:
      return state;
  }
}

export default extension;
