import React from "react";
import { FormattedMessage } from "react-intl";
import * as profileServiceApi from "../api/profileServiceApi";
import * as types from "../constants/ActionTypes";
import { notify, notifyError } from "./notificationsActions";

export const fetchAvailableFactors = () => (dispatch, getState) => {
  const { user } = getState();

  dispatch({
    type: types.FETCH_MFA_TOKEN_REQUEST
  });

  return profileServiceApi
    .fetchAvailableFactors(user.trackingId)
    .then(({ availableFactors, token }) => {
      const formatedAvailableFactors = availableFactors.map(factor => ({
        id: factor.id,
        typeId: factor.type_id,
        name: factor.name,
        customIconUrl: factor.custom_icon_url,
        isLoginFactor: factor.is_login_factor,
        isPasswordResetFactor: factor.is_password_reset_factor,
        isAppFactor: factor.is_app_factor
      }));

      dispatch({
        type: types.RECEIVE_MFA_FACTORS,
        factors: formatedAvailableFactors
      });

      dispatch({
        type: types.FETCH_MFA_TOKEN_RECEIVED,
        token
      });
    })
    .catch(() => {
      dispatch(
        notifyError({
          message: (
            <FormattedMessage
              defaultMessage="Failed to fetch available factors"
              id="factorFetchFailure"
            />
          )
        })
      );
    });
};

//re-export imported action to avoid changes in all app;
//importing from separate file makes it mockable for tests
export { fetchMfaToken, resetMfaToken } from "./mfaActions/fetchMfaToken";
export { errorTypes, getErrorMessage } from "./mfaActions/errorMessages";

export const fetchDevices = () => dispatch => {
  return profileServiceApi
    .fetchDevices()
    .then(devices =>
      dispatch({
        type: types.RECEIVE_MFA_DEVICES,
        devices
      })
    )
    .catch(() => {
      dispatch(
        notifyError({
          message: (
            <FormattedMessage
              defaultMessage="Failed to fetch devices"
              id="deviceFetchFailure"
            />
          )
        })
      );
      return [];
    });
};

export const updateDefaultDevice = (id, factorName) => dispatch => {
  return profileServiceApi
    .updateDefault(id)
    .then(() => {
      dispatch({
        type: types.UPDATE_DEFAULT_MFA_DEVICE_SUCCESS,
        id
      });
      dispatch(
        notify({
          message: (
            <FormattedMessage
              id="updateFactorSuccess"
              defaultMessage="{factorName} is your new default security factor"
              values={{ factorName }}
            />
          ),
          type: "success"
        })
      );
      dispatch({
        type: types.UPDATE_DEFAULT_MFA_DEVICE_RESET
      });

      return true;
    })
    .catch(() => {
      dispatch(
        notifyError({
          message: (
            <FormattedMessage
              id="factorPrimaryFailure"
              defaultMessage="Failed to set {factorName} as your primary factor"
              values={{ factorName }}
            />
          )
        })
      );

      return false;
    });
};

export const unsetDefaultDevice = (id, factorName) => dispatch => {
  return profileServiceApi
    .unsetDefault(id)
    .then(() => {
      dispatch({
        type: types.UNSET_DEFAULT_MFA_DEVICE_SUCCESS,
        id
      });
      dispatch(
        notify({
          message: (
            <FormattedMessage
              id="unsetFactorSuccess"
              defaultMessage="{factorName} is no longer your default security factor"
              values={{ factorName }}
            />
          ),
          type: "success"
        })
      );
      dispatch({
        type: types.UNSET_DEFAULT_MFA_DEVICE_RESET
      });

      return true;
    })
    .catch(() => {
      dispatch(
        notifyError({
          message: (
            <FormattedMessage
              id="factorUnsetPrimaryFailure"
              defaultMessage="Failed to unset {factorName} as your primary factor"
              values={{ factorName }}
            />
          )
        })
      );

      return false;
    });
};

export const deletePrimaryDevice = (
  newPrimaryId,
  newPrimaryFactorName,
  deleteId,
  deleteFactorName
) => dispatch => {
  dispatch(updateDefaultDevice(newPrimaryId, newPrimaryFactorName)).then(
    success => {
      if (success) {
        dispatch(deleteDevice(deleteId, deleteFactorName));
      }
    }
  );
};

export const deleteDevice = (id, factorName) => dispatch => {
  //TODO: UX: device name should be removed immediately for better UX (don't wait for server response)
  //it may be just marked as removed to be not displayed in a table
  //when request succeeds, remove it completely (or reload list)
  //when it fails, revert 'deleted' flag (or reload list)
  return profileServiceApi
    .deleteDevice(id)
    .then(() => {
      dispatch({
        type: types.REMOVE_MFA_DEVICE_SUCCESS,
        id
      });
      dispatch(
        notify({
          message: (
            <FormattedMessage
              id="deleteDeviceSuccess"
              defaultMessage="{factorName} was successfully deleted"
              values={{
                factorName
              }}
            />
          ),
          type: "success"
        })
      );
      dispatch({
        type: types.REMOVE_MFA_DEVICE_RESET
      });
    })
    .catch(() => {
      dispatch(
        notifyError({
          message: (
            <FormattedMessage
              defaultMessage="Failed to remove {factorName}"
              id="deviceDeleteFailure"
              values={{ factorName }}
            />
          )
        })
      );
    });
};

export const renameAuthenticationFactor = (id, newName) => dispatch => {
  //TODO: UX: device name should be updated immediately for even better UX (don't wait for server response);
  //keep changed name when request succeeds, revert to original name when failed
  dispatch({ type: types.UPDATING_DEVICE_NAME, id, newName });

  return profileServiceApi
    .updateDeviceName(id, newName)
    .then(() => {
      dispatch({
        type: types.UPDATED_DEVICE_NAME,
        id,
        newName: newName
      });
      dispatch(
        notify({
          message: (
            <FormattedMessage
              id="updateDeviceNameSuccess"
              defaultMessage="{newName} was successfully updated"
              values={{ newName: newName }}
            />
          ),
          type: "success"
        })
      );
    })
    .catch(() => {
      dispatch({ type: types.UPDATE_DEVICE_NAME_FAILED, id });
      dispatch(
        notifyError({
          message: (
            <FormattedMessage
              defaultMessage="Failed to update device name"
              id="deviceRenameFailure"
            />
          )
        })
      );
    });
};

export const deleteDeviceAfterNewFactor = id => dispatch => {
  dispatch({
    type: types.REMOVE_MFA_DEVICE_AFTER_NEW_FACTOR,
    id
  });
};
