import React from "react";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { withRouter, Route, Switch } from "react-router-dom";
import ScreenContentWrapper from "../ScreenContentWrapper";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import PropTypes from "prop-types";

import { NARROW_VIEW_LIMIT, PUBLIC_URL } from "../../../constants/Env";
import { RECENT_ACTIVITY_PATH } from "../../../constants/Menu";
import ScreenHeader from "../ScreenHeader";
import ActivityTable from "./ActivityTable";
import ActivityDetailsDialog from "./ActivityDetailsDialog";
import FilterTabs from "./FilterTabs";
import LoadingDiv from "../LoadingDiv";
import {
  setActivityToReport,
  setRecentActivityFilter
} from "../../../actions/recentActivityActions";
import { setHelpRequestRedirectTo } from "../../../actions/helpRequestActions";
import { getActivityByIdSelector } from "../../../selectors/recentActivitySelector";
import { getFilters, getFilterUrl, filterEvents, ALL_FILTER } from "./events";
import { commonMessages } from "../../../translations/commonMessages";
import { reportSuspicious } from "../../../actionsLog";

const messages = defineMessages({
  eventUuid: {
    id: "eventUuid",
    defaultMessage: `Event UUID`
  },
  eventUserAgent: {
    id: "eventUserAgent",
    defaultMessage: `Event Useragent`
  },
  prefix: {
    id: "prefix",
    defaultMessage: "Suspicious login report"
  }
});

const NotMobileControl = styled.div`
  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    display: none;
  }
`;

class RecentActivityScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = { dataIndex: null };
  }

  componentDidMount() {
    document.title = this.props.intl.formatMessage(
      {
        id: "recentActivityTitle",
        defaultMessage: "Recent Activity | {brandName}"
      },
      { brandName: this.props.brandedPageTitle }
    );

    if (!this.props.match.params.name && !this.props.match.params.id) {
      // reset the redux stored filter to null, when a user clicks the left nav link
      this.props.setSelectedFilter(null);
    } else if (this.props.match.params.name) {
      if (
        getFilters()
          .map(filter => filter.filter)
          .includes(this.props.match.params.name.toLowerCase())
      ) {
        this.props.setSelectedFilter(
          this.props.match.params.name.toLowerCase()
        );
      } else {
        this.props.history.push(getFilterUrl());
      }
    }
  }

  showDetails = dataIndex => {
    this.props.history.push(
      `${PUBLIC_URL}/${RECENT_ACTIVITY_PATH}/${dataIndex}`
    );
  };

  onActivityDetailClose = () => {
    if (this.props.recentActivity.selectedFilter) {
      this.props.history.push(
        getFilterUrl(this.props.recentActivity.selectedFilter)
      );
    } else {
      this.props.history.push(getFilterUrl());
    }
  };

  onSelectedFilterChange = (event, filter) => {
    event.preventDefault();

    if (filter === ALL_FILTER) {
      this.props.setSelectedFilter(null);
    } else {
      this.props.setSelectedFilter(filter);
    }
  };

  //TODO: reuse activity content building for activityDetailsDialog
  stringifyActivity = activity => {
    let rows = [];

    //TODO: this could be done probably only once(?)
    moment.locale(this.props.locale);

    if (activity.deviceDetails.userAgent) {
      rows.push(
        `${this.props.intl.formatMessage(commonMessages.browserDevice)}: ${
          activity.deviceDetails.client
        } ${activity.deviceDetails.osName} ${activity.deviceDetails.osVersion}`
      );
    }
    rows.push(
      `${this.props.intl.formatMessage(commonMessages.location)}: ${activity
        .login.location || ""}`
    );

    if (activity.appDetails && activity.appDetails.appName) {
      rows.push(
        `${this.props.intl.formatMessage(commonMessages.appName)}: ${
          activity.appDetails.appName
        }`
      );
    }

    if (activity.appDetails && activity.appDetails.loginName) {
      rows.push(
        `${this.props.intl.formatMessage(commonMessages.loginName)}: ${
          activity.appDetails.loginName
        }`
      );
    }

    rows.push(
      `${this.props.intl.formatMessage(
        commonMessages.recentActivity
      )}: ${moment(activity.login.time)} (${moment(
        activity.login.time
      ).fromNow()})`
    );

    rows.push(
      `${this.props.intl.formatMessage(
        commonMessages.eventType
      )}: ${activity.eventType || ""}`
    );

    rows.push(
      `${this.props.intl.formatMessage(
        messages.eventUuid
      )}: ${activity.event_uuid || ""}`
    );

    if (activity.deviceDetails.userAgent) {
      rows.push(
        `${this.props.intl.formatMessage(messages.eventUserAgent)}: ${
          activity.deviceDetails.userAgent
        }`
      );
    }

    return rows;
  };

  onReportClick = activity => {
    const prefix = `${this.props.intl.formatMessage(messages.prefix)}\n\n`;
    const stringifiedActivityReport = this.stringifyActivity(activity).join(
      "\n"
    );
    this.props.setActivityToReport(`${prefix}${stringifiedActivityReport}`);
    this.props.setHelpRequestRedirectTo(
      `${PUBLIC_URL}/${RECENT_ACTIVITY_PATH}`
    );
    reportSuspicious();
    this.props.history.push(`${PUBLIC_URL}/help`); // TODO: should be unified in a navigation actions or routes definitions or similar
  };

  render() {
    return (
      <>
        <NotMobileControl>
          <ScreenHeader>
            <FormattedMessage {...commonMessages.recentActivity} />
          </ScreenHeader>
        </NotMobileControl>
        {this.props.recentActivity.loaded ? (
          <ScreenContentWrapper column isMobileFullWidth>
            <FilterTabs
              filters={getFilters()}
              onChangeFilter={this.onSelectedFilterChange}
              selectedFilterName={this.props.match.params.name || ALL_FILTER}
            />
            <ActivityTable
              ActivityData={filterEvents(
                this.props.recentActivity.data,
                this.props.recentActivity.selectedFilter
              )}
              showDetails={this.showDetails}
              onReportClick={this.onReportClick}
              helpEnabled={this.props.helpEnabled}
              locale={this.props.locale}
            />
          </ScreenContentWrapper>
        ) : (
          <LoadingDiv>
            <FormattedMessage
              defaultMessage="Loading recent activity details, please wait..."
              id={"loadingRecentActivity"}
            />
          </LoadingDiv>
        )}
        <Switch>
          <Route exact path={`${PUBLIC_URL}/${RECENT_ACTIVITY_PATH}/:id`}>
            {this.props.activityDetails && (
              <ActivityDetailsDialog
                data={this.props.activityDetails}
                onClose={this.onActivityDetailClose}
                locale={this.props.locale}
              />
            )}
          </Route>
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  brandedPageTitle: state.account.brandedPageTitle,
  recentActivity: state.recentActivity,
  locale: state.user.currentLanguageLocale,
  helpEnabled: state.account.helpEnabled,
  activityDetails: getActivityByIdSelector(
    state,
    parseInt(ownProps.match.params.id, 10)
  )
});

const mapDispatchToProps = dispatch => ({
  setActivityToReport: activity => {
    dispatch(setActivityToReport(activity));
  },
  setSelectedFilter: filter => {
    dispatch(setRecentActivityFilter(filter));
  },
  setHelpRequestRedirectTo: redirectTo => {
    dispatch(setHelpRequestRedirectTo(redirectTo));
  }
});

const appDetailsShape = PropTypes.shape({
  appName: PropTypes.string,
  loginName: PropTypes.string
});

const deviceDetailsShape = PropTypes.shape({
  client: PropTypes.string.isRequired,
  clientVersion: PropTypes.string.isRequired,
  osName: PropTypes.string.isRequired,
  osVersion: PropTypes.string.isRequired,
  userAgent: PropTypes.string.isRequired,
});

const loginShape = PropTypes.shape({
  ip: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired
});

const activityDetailsShape = PropTypes.shape({
  appDetails: appDetailsShape,
  deviceDetails: deviceDetailsShape,
  eventType: PropTypes.string.isRequired,
  event_id: PropTypes.number.isRequired,
  event_uuid: PropTypes.string.isRequired,
  login: loginShape
});

const matchShape = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })
});

const recentActivityShape = PropTypes.shape({
  data: PropTypes.array,
  loaded: PropTypes.bool.isRequired,
  reportActivity: PropTypes.string,
  selectedFilter: PropTypes.string
});
// include location?
RecentActivityScreen.propTypes = {
  activityDetails: activityDetailsShape,
  brandedPageTitle: PropTypes.string.isRequired,
  helpEnabled: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  match: matchShape,
  recentActivity: recentActivityShape,
  setActivityToReport: PropTypes.func.isRequired,
  setHelpRequestRedirectTo: PropTypes.func.isRequired,
  setSelectedFilter: PropTypes.func.isRequired
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(RecentActivityScreen)
  )
);
