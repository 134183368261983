import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { takeWhile } from "ramda";
import { COLORS } from "@onelogin/react-components";

import { NARROW_VIEW_LIMIT } from "../../../constants/Env";

const TableCellInternal = styled.div`
  width: ${props => props.width};
`;

const TableTitleWrap = styled.div`
  position: relative;
`;

const TableTitle = styled.div`
  display: inline-block;
  background-color: white;
  color: ${props =>
    props.isHighlighted
      ? COLORS.STATUS_COLORS.SUCCESS
      : COLORS.GRAY_COLORS.GRAY_35};
  padding: 0px 10px;
  position: absolute;
  left: 10px;
  top: -10px;
`;

const TableRowInternal = styled.div`
  height: ${props => (props.isHeader ? "58px" : "70px")};
  background-color: #ffffff;
  border: 1px solid
    ${props =>
      props.isHighlighted
        ? COLORS.STATUS_COLORS.SUCCESS
        : COLORS.GRAY_COLORS.GRAY_94};
  margin-top: ${props => (props.isHighlighted ? "-1px" : "0px")};
  border-top-width: ${props =>
    props.isHeader || props.isHighlighted ? "1px" : "0px"};
  display: flex;
  align-items: center;
  padding: 0px 20px;
  font-size: 13px;
  line-height: 1.23;
  font-weight: ${props => (props.isHeader ? 500 : 400)};

  &:not(:first-of-type):hover {
    background-color: ${COLORS.GRAY_COLORS.GRAY_96};
  }

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    &:first-child {
      border-top-width: ${props => (props.hideTopBorderMobile ? 0 : "1px")};
    }
    border-left: 0;
    border-right: 0;
  }
`;

/* PORTAL-2630: The principle here is to allow cells to take up the space of all following
 * invisible cells, up until the next non-invisible cell. The resulting width would appear
 * as a css calc() line containing the current cell's width and the following invisible
 * cells widths (eg. "calc(33% + 17% + 17%)")
 */
const calculateCellWidth = (cells, index) => {
  const curCell = cells[index];
  const postCells = cells.slice(index + 1);
  const absorbedCells = takeWhile(cell => cell.hidden, postCells);
  if (absorbedCells.length === 0) {
    return cells[index].width;
  }
  return `calc(${[curCell, ...absorbedCells]
    .map(cell => cell.width)
    .join(" + ")})`;
};

export const TableRow = props => (
  <>
    {!props.isHeader && props.rowTitle && (
      <TableTitleWrap>
        <TableTitle isHighlighted={props.isHighlighted}>
          {props.rowTitle}
        </TableTitle>
      </TableTitleWrap>
    )}
    <TableRowInternal
      isHeader={props.isHeader}
      onClick={props.onClick}
      isHighlighted={props.isHighlighted}
      hideTopBorderMobile={props.hideTopBorderMobile}
    >
      {props.cells.map(
        (column, index) =>
          column.width !== undefined &&
          !column.hidden && (
            <TableCellInternal
              width={calculateCellWidth(props.cells, index)}
              key={index}
              onClick={() => {
                column.onClick && column.onClick(props.isClipped);
              }}
            >
              {column.content}
            </TableCellInternal>
          )
      )}
    </TableRowInternal>
  </>
);

TableRow.propTypes = {
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      width: PropTypes.string, //width containing units
      content: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
        .isRequired
    })
  ),
  isHeader: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  rowTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isClipped: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  hideTopBorderMobile: PropTypes.bool
};

export default TableRow;
