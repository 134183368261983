import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { COLORS } from "@onelogin/react-components";

import { NARROW_VIEW_LIMIT } from "../../../constants/Env";

const FilterWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GRAY_COLORS.GRAY_94};
  border-bottom: none;
  display: flex;

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }
`;

const FilterOption = styled.div`
  display: flex;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  ${props => props.selected && "border-bottom: 2px solid #016B91;"};
  ${props =>
    props.selected && `background-color: ${COLORS.GRAY_COLORS.GRAY_96}`};
  margin-right: 2px;
  &:hover {
    border-bottom: 2px solid ${COLORS.GRAY_COLORS.GRAY_78};
    background-color: ${COLORS.GRAY_COLORS.GRAY_98};
  }

  a {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${COLORS.GRAY_COLORS.GRAY_35};
    text-decoration: none;
    justify-content: center;
    padding: 10px 20px;
    text-transform: capitalize;
  }
`;

const FilterTabs = props => {
  let selected = null;

  if (props.selectedFilterName) {
    selected = props.filters
      .map(item => item.filter.toLowerCase())
      .indexOf(props.selectedFilterName.toLowerCase());
  }

  return (
    <FilterWrapper>
      {props.filters.map((item, index) => (
        <FilterOption
          key={`filter_${index}`}
          onClick={event =>
            props.onChangeFilter &&
            props.onChangeFilter(event, item.filter.toLowerCase())
          }
          selected={index === selected}
          data-testid={`${item.filter}-tab`}
        >
          <Link to={item.href}>{item.display}</Link>
        </FilterOption>
      ))}
    </FilterWrapper>
  );
};

FilterTabs.propTypes = {
  filters: PropTypes.array.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  selectedFilterName: PropTypes.string.isRequired
};

export default FilterTabs;
