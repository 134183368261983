/*
 * Taking full name as parameter and returns initials. It doesn't return more
 * than 2 letters but can return just one or empty string.
 * ex. 'Bob Dilan' => 'BD'; 'Makus von Baden' => 'MB', 'Bob' => 'B', ' ' => ''
 */
export const parseInitials = name => {
  if (!name) {
    return "";
  }

  const getInitialRegex = new RegExp(/(\p{L}{1})\p{L}*/, "gu");

  const initialsArray = [...name.matchAll(getInitialRegex)] || [];

  const initials = (
    (initialsArray.shift()?.[1] || "") + (initialsArray.pop()?.[1] || "")
  ).toUpperCase();

  return initials;
};
