import React from "react";
import { bucketName, region, s3StoragePath } from "../../../constants/Api";
import PropTypes from "prop-types";

const SupportedBrowserIcons = [
  "Safari",
  "IE",
  "Edge",
  "Chrome",
  "Opera",
  "Firefox"
];

const UNKNOWN_BROWSER_BASE = "Other";

const getS3ImageURL = browserName =>
  `https://${bucketName}.s3.${region}.amazonaws.com/${s3StoragePath}/${
    SupportedBrowserIcons.includes(browserName)
      ? browserName
      : UNKNOWN_BROWSER_BASE
  }-128x128.png`;

export const BrowserIcon = ({ browserName, ...props }) => {
  return (
    <img
      src={getS3ImageURL(browserName)}
      {...props}
      alt=""
      width="16px"
      height="16px"
    />
  );
};

BrowserIcon.propTypes = {
  browserName: PropTypes.string.isRequired
};
