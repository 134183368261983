import React from "react";
import PropTypes from "prop-types";

import {
  TextItem,
  SubTextItem
} from "../../../common/Table/ResponsiveTableStyles";

const ProtectDetails = ({ details }) => {
  if (!details || typeof details !== "object") {
    return null;
  }

  const { device, os } = details;

  return (
    <>
      {device && device.vendor ? (
        <TextItem>
          {device.vendor} {device.model}
        </TextItem>
      ) : null}
      {os && os.name ? (
        <SubTextItem>
          {os.name} {os.version}
        </SubTextItem>
      ) : null}
    </>
  );
};

ProtectDetails.propTypes = {
  details: PropTypes.oneOfType([
    PropTypes.shape({
      os: PropTypes.shape({
        name: PropTypes.string.isRequired,
        version: PropTypes.string.isRequired
      }),
      device: PropTypes.shape({
        vendor: PropTypes.string.isRequired,
        model: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
      })
    })
  ])
};

export default ProtectDetails;
