import React, { Component } from "react";
import PropTypes from "prop-types";
import Bowser from "bowser";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";

import {
  InstallProtect,
  PairProtectOrAuthenticator,
  LoginLoading,
  RegisterWithCredentialIdAndTwoOtps
} from "@onelogin/react-components";

import PeriodicStatusCheck from "../PeriodicStatusCheck";
import getMfaApi, {
  REGISTRATION_ACCEPTED,
  QR_ROTATION_INTERVAL
} from "../../../../api/mfaService";

const browser = Bowser.getParser(window.navigator.userAgent);
const osName = browser.getOSName();

const messages = defineMessages({
  registrationTimedout: "Registration context timed out",
  invalidCode: "Invalid code"
});

const RETRY_TIMES = 4;

const getInitialState = () => ({
  totpUrl: undefined,
  verificationToken: undefined,
  showInstall: true,
  totpFlow: false,
  loading: true,
  platform: "mobile"
});

class RegisterProtect extends Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();

    this.mfaService = getMfaApi(
      this.props.trackingId,
      this.props.defaultLanguage
    );
    this.registrationId = undefined;
    this.failedRequestCount = 0;
  }

  componentDidMount = () => {
    this.initRegistration();
    this.timer = setInterval(this.initRegistration, QR_ROTATION_INTERVAL);
  };

  componentWillUnmount = () => {
    clearInterval(this.timer);
  };

  // TODO: this method is same as within RegisterAuthenticator - think about extraction
  initRegistration = () => {
    this.mfaService
      .initRegistration(this.props.mfaApiToken, this.props.factorId)
      .then(({ id, verificationToken, totpUrl }) => {
        this.registrationId = id;
        this.setState({
          totpUrl,
          verificationToken,
          loading: false
        });
      })
      .catch(err => err.response.json().then(this.props.onError));
  };

  // TODO: this method (code) is within all register methods - think about extraction
  handleRegistrationError = err => {
    this.failedRequestCount = 0;
    if (err && err.code === 404) {
      this.props.onError(messages.registrationTimedout);
    } else {
      this.props.onError(err);
    }
  };

  retryForCheckStatus = err => {
    if (err && Math.floor(err.code / 100) === 5) {
      this.failedRequestCount++;
      if (this.failedRequestCount === RETRY_TIMES) {
        this.onError(err);
      }
    } else {
      this.onError(err);
    }
  };

  checkStatus = () => {
    this.mfaService
      .getRegistration(this.props.mfaApiToken, this.registrationId)
      .then(body => {
        if (body.status === REGISTRATION_ACCEPTED) {
          this.props.onSuccess();
        }
        // there is no else as we ignores rejection (ex. we don't want to tell user that push was rejected)
      })
      .catch(err => err.response.json().then(this.retryForCheckStatus));
  };

  totpLink = () => {
    if (this.state.totpUrl) return this.state.totpUrl;

    const subdomain = window.location.hostname.split(".")[0];

    return `onelogin-otpauth://totp/${subdomain}:${this.props.username}?secret=${this.state.verificationToken}&issuer=OneLogin`;
  };

  onContinueInstall = () => {
    if (osName === "Android" || osName === "iOS") {
      // redirect to URL that OneLogin Protect app should handle (provided by Ron Craswell)
      window.location.href = this.totpLink();
      this.setState({ showInstall: false, totpFlow: true });
    } else {
      this.setState({ showInstall: false, totpFlow: false });
    }
  };

  onSubmitOtp = otp => {
    this.setState({
      loading: true
    });

    const { mfaApiToken } = this.props;

    const normalizedOtp = {
      tokenId: otp.credentialId,
      otp1: otp.otp1,
      otp2: otp.otp2
    };
    this.mfaService
      .submitRegistrationOtp(mfaApiToken, this.registrationId, normalizedOtp)
      .then(() =>
        this.mfaService.getRegistration(mfaApiToken, this.registrationId)
      )
      .then(body => {
        switch (body.status) {
          case "accepted":
            this.props.onSuccess(body.jwt);
            break;
          case "rejected":
            this.props.backToMainFlow();
            break;
          default:
            this.props.showNotification(messages.invalidCode);
            this.setState({
              loading: false
            });
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 404) {
          this.props.showNotification(messages.registrationTimedout);
          this.initRegistration();
        } else {
          this.props.onError(err);
        }
      });
  };

  render = () => {
    if (this.state.loading) {
      return <LoginLoading />;
    }

    if (this.state.showInstall) {
      return (
        <InstallProtect
          bowser={{ android: osName === "Android", ios: osName === "iOS" }}
          onContinue={this.onContinueInstall}
        />
      );
    }

    return (
      <div>
        {this.state.totpFlow ? (
          <div>
            <LoginLoading />
            <PeriodicStatusCheck
              checkStatus={this.checkStatus}
              checkPeriodMs={2000}
            />
          </div>
        ) : (
          <div>
            {this.state.platform === "mobile" ? (
              <div>
                <PeriodicStatusCheck
                  checkStatus={this.checkStatus}
                  checkPeriodMs={2000}
                />
                <PairProtectOrAuthenticator
                  appName="OneLogin Protect"
                  qrLink={this.totpLink()}
                  code={this.state.verificationToken}
                />
              </div>
            ) : (
              <RegisterWithCredentialIdAndTwoOtps
                onSubmit={this.onSubmitOtp}
                credentialIdText={
                  <FormattedMessage
                    defaultMessage="Credential ID"
                    id="credentialId"
                  />
                }
                otp1Label={
                  <FormattedMessage defaultMessage="OTP 1" id="otp1" />
                }
                otp2Label={
                  <FormattedMessage defaultMessage="OTP 2" id="otp2" />
                }
              />
            )}
          </div>
        )}
      </div>
    );
  };
}

RegisterProtect.propTypes = {
  mfaApiToken: PropTypes.string.isRequired,
  factorId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  trackingId: PropTypes.string.isRequired,
  defaultLanguage: PropTypes.string.isRequired
};

export default injectIntl(RegisterProtect);
