import React from "react";
import { ModalDialog } from "@onelogin/react-components";
import PropTypes from "prop-types";
import styled from "styled-components";

import ProfileDialogRow from "./ProfileDialogRow";

const DialogContainer = styled.div`
  font-size: 14px;
  border-radius: 0 0 4px 4px;
`;

const ProfileDialog = props => {
  return (
    <ModalDialog
      onClose={props.onClose}
      closeOnClick
      fixedWidth={props.fixedWidth}
    >
      <ModalDialog.Header>{props.table.header}</ModalDialog.Header>
      <DialogContainer>
        {props.table.rows.map(
          (row, index) =>
            row.visibleCondition !== false && (
              <ProfileDialogRow row={row} key={index} />
            )
        )}
      </DialogContainer>
    </ModalDialog>
  );
};

ProfileDialog.propTypes = {
  table: PropTypes.shape({
    header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        visibleCondition: PropTypes.bool,
        title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        info: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
      })
    ).isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  fixedWidth: PropTypes.number
};

export default ProfileDialog;
