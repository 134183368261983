import { combineReducers } from "redux";

import user from "./userReducer";
import account from "./accountReducer";
import branding from "./brandingReducer";
import notifications from "./notificationsReducer";
import environment from "./environmentReducer";
import menu from "./menuReducer";
import recentActivity from "./recentActivityReducer";
import passwordPolicy from "./passwordPolicyReducer";
import mfa from "./mfaReducer";
import desktop from "./desktopReducer";
import trustedBrowsers from "./trustedBrowserReducer";
import helpRequest from "./helpRequestReducer";
import extension from "./extensionReducer";
import fatalError from "./fatalErrorReducer";

import { intlReducer } from "react-intl-redux";

const rootReducer = combineReducers({
  branding,
  environment,
  user,
  account,
  notifications,
  menu,
  recentActivity,
  passwordPolicy,
  mfa,
  desktop,
  trustedBrowsers,
  helpRequest,
  extension,
  fatalError,
  intl: intlReducer
});

export default rootReducer;
