import * as types from "../constants/ActionTypes";

export const setActiveMenuItem = path => (dispatch, getState) => {
  const { menu = {} } = getState();

  dispatch({
    type: types.SET_ACTIVE_MENU_ITEM,
    path,
    isChanged: menu.activeItem && menu.activeItem.linkPath !== path
  });
};
