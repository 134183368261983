import React, { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";

import { EnterYubiKey, LoginLoading } from "@onelogin/react-components";
import getMfaApi from "../../../../api/mfaService";

const messages = defineMessages({
  invalidCode: "Invalid code",
  registrationTimedout: "Registration context timed out"
});

class RegisterYubiKey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };

    this.mfaService = getMfaApi(
      this.props.trackingId,
      this.props.defaultLanguage
    );
    this.registrationId = undefined;
  }

  componentDidMount = () => {
    this.initRegistration();
  };

  initRegistration = () => {
    this.mfaService
      .initRegistration(this.props.mfaApiToken, this.props.factorId)
      .then(({ id }) => {
        this.registrationId = id;
        this.setState({
          loading: false
        });
      })
      .catch(err => err.response.json().then(this.props.onError));
  };

  onSubmitOtp = otp => {
    this.setState({
      loading: true
    });

    const { mfaApiToken } = this.props;
    this.mfaService
      .submitRegistrationOtp(mfaApiToken, this.registrationId, otp)
      .then(() =>
        this.mfaService.getRegistration(mfaApiToken, this.registrationId)
      )
      .then(body => {
        switch (body.status) {
          case "accepted":
            this.props.onSuccess();
            break;
          case "rejected":
            this.props.onError();
            break;
          default:
            this.props.onError(messages.invalidCode);
            this.setState({
              loading: false
            });
        }
      })
      .catch(err => err.response.json().then(this.handleRegistrationError));
  };

  handleRegistrationError = err => {
    if (err && err.code === 404) {
      this.props.onError(messages.registrationTimedout);
    } else {
      this.props.onError(err);
    }
  };

  render = () => {
    if (this.state.loading) {
      return <LoginLoading />;
    }

    return <EnterYubiKey onSubmit={this.onSubmitOtp} />;
  };
}

RegisterYubiKey.propTypes = {
  mfaApiToken: PropTypes.string.isRequired,
  factorId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  showMfaNotification: PropTypes.func.isRequired,
  trackingId: PropTypes.string.isRequired,
  defaultLanguage: PropTypes.string.isRequired
};

export default RegisterYubiKey;
