import * as types from "../constants/ActionTypes";
import * as profileService from "../api/profileServiceApi";

export const fetchRecentActivity = dispatch => {
  profileService.fetchRecentActivity().then(response => {
    dispatch({
      type: types.FETCH_RECENT_ACTIVITY,
      activity: response
    });
  });
};

export const setActivityToReport = activity => (dispatch, getState) => {
  dispatch({
    type: types.REPORT_ACTIVITY,
    activity
  });
};

export const setRecentActivityFilter = filter => (dispatch, getState) => {
  dispatch({ type: types.SET_RECENT_ACTIVITY_FILTER, filter });
};
