import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import moment from "moment";

import Moment from "../../MomentWrapper";
import ProfileDialog from "../../common/ProfileDialog/ProfileDialog";
import { commonMessages } from "../../../translations/commonMessages";
import { getEventByType } from "./events";

const ActivityDetailsDialog = props => {
  const table = {
    header: <FormattedMessage {...commonMessages.recentActivity} />,
    rows: [
      {
        visibleCondition:
          !!props.data.deviceDetails &&
          !(
            props.data.otpDevice &&
            (props.data.otpDevice.deviceCurrentName ||
              props.data.otpDevice.deviceName)
          ) &&
          getEventByType(props.data.eventType).showDevice,
        title: <FormattedMessage {...commonMessages.browserDevice} />,
        info: (
          <>
            {props.data.deviceDetails.client}
            <br />
            {`${props.data.deviceDetails.osName} ${props.data.deviceDetails.osVersion}`}
          </>
        )
      },
      {
        visibleCondition:
          !!(
            props.data.otpDevice &&
            (props.data.otpDevice.deviceCurrentName ||
              props.data.otpDevice.deviceName)
          ) && getEventByType(props.data.eventType).showDevice,
        title: <FormattedMessage {...commonMessages.browserDevice} />,
        info: (
          <>
            {props.data.otpDevice && (
              <>
                {props.data.otpDevice.deviceCurrentName ||
                  props.data.otpDevice.deviceName ||
                  props.data.otpDevice.productDisplayName}
              </>
            )}
            <br />
            {getEventByType(props.data.eventType).showPrevious && (
              <FormattedMessage
                defaultMessage="Previously {previousName}"
                id="previousDeviceName"
                values={{ previousName: props.data.otpDevice.deviceName }}
              />
            )}
          </>
        )
      },
      {
        title: <FormattedMessage {...commonMessages.location} />,
        info: props.data.login.location
      },
      {
        visibleCondition: !!(
          props.data.appDetails && props.data.appDetails.appName
        ),
        title: <FormattedMessage {...commonMessages.appName} />,
        info: props.data.appDetails && props.data.appDetails.appName
      },
      {
        visibleCondition: !!(
          props.data.appDetails && props.data.appDetails.loginName
        ),
        title: props.data.appDetails && (
          <FormattedMessage {...commonMessages.loginName} />
        ),
        info: props.data.appDetails && props.data.appDetails.loginName
      },
      {
        title: <FormattedMessage {...commonMessages.time} />,
        info: (
          <>
            <Moment locale={props.locale}>{props.data.login.time}</Moment>{" "}
            {moment(props.data.login.time).isValid() && (
              <>
                {"("}
                <Moment fromNow locale={props.locale}>
                  {props.data.login.time}
                </Moment>
                {")"}
              </>
            )}
          </>
        )
      },
      {
        title: <FormattedMessage {...commonMessages.eventType} />,
        info: props.data.eventType
      },
      {
        title: <FormattedMessage defaultMessage="Event UUID" id="eventUUID" />,
        info: props.data.event_uuid
      },
      {
        //added the window.forceUserAgent for debugging
        visibleCondition:
          !!props.data.deviceDetails.userAgent &&
          (getEventByType(props.data.eventType).showUserAgentDetails ||
            window.forceUserAgent === true),
        title: props.data.deviceDetails && (
          <FormattedMessage
            defaultMessage="Event Useragent"
            id="eventUseragent"
          />
        ),
        info: props.data.deviceDetails.userAgent
      }
    ]
  };

  return <ProfileDialog onClose={props.onClose} table={table} />;
};

ActivityDetailsDialog.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};
export default ActivityDetailsDialog;
