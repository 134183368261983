import * as types from "../constants/ActionTypes";
import { getUniqueId } from "../utils/uniqueIdGenerator";

const NOTIFICATION_TIMEOUT = 2000;
const NOTIFICATION_ERROR_TIMEOUT = 5000;

// Save notification to redux store, there could be already queue of previous notifications waiting to be displayed
// Notification expects a message (string or React component), and optional dismissDelay and type
export const notify = notification =>
  notifyPayload({
    // Defaults
    dismissDelay: NOTIFICATION_TIMEOUT,
    type: "info",

    // Overriding defaults
    ...notification
  });

// Shorthand for notify with error type, expects a message (string or React component), and optional dismissDelay
export const notifyError = notification =>
  notifyPayload({
    // Default
    dismissDelay: NOTIFICATION_ERROR_TIMEOUT,

    // Overriding default
    ...notification,

    // Always error type
    type: "error"
  });

const notifyPayload = ({ message, dismissDelay, type }) => ({
  type: types.PUSH_NOTIFICATION_TO_QUEUE,
  notification: {
    message,
    dismissDelay,
    type,
    id: getUniqueId()
  }
});

export const clearNotification = id => ({
  type: types.REMOVE_NOTIFICATION_FROM_QUEUE,
  id
});

export const showMfaNotification = ({
  message,
  type = "info",
  dismissDelay = 5000
}) => {
  return {
    type: types.PUSH_MFA_NOTIFICATION_TO_QUEUE,
    mfaNotification: {
      id: getUniqueId(),
      message,
      type,
      dismissDelay
    }
  };
};

export const clearMfaNotification = id => ({
  type: types.REMOVE_MFA_NOTIFICATION_FROM_QUEUE,
  id
});
