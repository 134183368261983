import PropTypes from "prop-types";

import styled, { css } from "styled-components";
import { NARROW_VIEW_LIMIT, WIDE_VIEW_LIMIT } from "../constants/Env";

const ResponsiveBlock = styled.div`
  margin: auto;
  width: ${WIDE_VIEW_LIMIT}px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: ${props => props.centered && `center`};
  min-height: ${props => props.fillHeight && "100%"};

  ${props =>
    props.maxHeight === true &&
    css`
      max-height: 100%;
      flex-grow: 1;
    `};

  ${props =>
    props.noPaddings !== true &&
    css`
      padding: 0 32px;
    `};

  @media screen and (max-width: ${WIDE_VIEW_LIMIT}px) {
    width: 100%;
    ${props =>
      props.noPaddings !== true &&
      css`
        padding: 0 20px;
      `};
  }

  @media screen and (max-width: ${props => props.columnTrigger}px) {
    flex-direction: column;
  }
`;

ResponsiveBlock.defaultProps = {
  columnTrigger: NARROW_VIEW_LIMIT
};

ResponsiveBlock.propTypes = {
  noPaddings: PropTypes.bool,
  fillHeight: PropTypes.bool,
  centered: PropTypes.bool,
  columnTrigger: PropTypes.number
};

export default ResponsiveBlock;
