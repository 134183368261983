import React, { Children } from "react";
import styled from "styled-components";

import { COLORS, BREAKPOINTS } from "@onelogin/react-components";

// This is a copy of the `/src/components/FormFooter.js` file. I needed to modify the
// flex-direcion and justify-content atributes and I couldn't just override them by
// passing the FormFooter through styled().

const Buttons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  border-top: solid 1px ${COLORS.SEPARATOR_COLORS.MEDIUM};
  padding: 16px 0;

  @media screen and (max-width: ${BREAKPOINTS.PHONE_LARGE}) {
    flex-direction: column;
    padding: 24px 0;
  }
`;

const ButtonWrapper = styled.div`
  &:first-child {
    margin-right: 0px;
  }

  @media screen and (max-width: ${BREAKPOINTS.PHONE_LARGE}) {
    margin-right: 0px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-top: 0px;
    }
  }
`;

const ButtonGroup = ({ children }) => (
  <Buttons>
    {Children.map(
      children,
      child => child && <ButtonWrapper>{child}</ButtonWrapper>
    )}
  </Buttons>
);

export default ButtonGroup;
