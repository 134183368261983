import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import ProfileDialog from "../../common/ProfileDialog/ProfileDialog";
import { TrustedDevice, UntrustedDevice } from "./OneLoginDesktopIcon";
import Moment from "../../MomentWrapper";
import { commonMessages } from "../../../translations/commonMessages";

const OneLoginDesktopDialog = props => {
  const table = {
    header: <FormattedMessage {...commonMessages.oneloginDesktop} />,
    rows: [
      {
        title: <FormattedMessage {...commonMessages.application} />,
        info: (
          <>
            {`${props.data.desktopInstall.nameInShort} ${props.data.desktopInstall.type}`}
            <br />
            <FormattedMessage
              id="version"
              defaultMessage={`Version: {versionString}`}
              values={{ versionString: props.data.desktopInstall.version }}
            />
          </>
        )
      },
      {
        title: <FormattedMessage {...commonMessages.hostname} />,
        info: (
          <>
            {props.data.deviceDetails.name}
            <br />
            {props.data.deviceDetails.os}
          </>
        )
      },
      {
        title: <FormattedMessage {...commonMessages.lastAuthentication} />,
        info: (
          <Moment fromNow locale={props.locale}>
            {props.data.lastAuthentication.time}
          </Moment>
        )
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="Trusted Device"
            id="trustedDevice"
          />
        ),
        info: props.data.trustedDevice ? (
          <TrustedDevice size="16px" />
        ) : (
          <UntrustedDevice size="16px" />
        )
      }
    ]
  };

  return <ProfileDialog onClose={props.onClose} table={table} />;
};

OneLoginDesktopDialog.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export default OneLoginDesktopDialog;
