import React from "react";
import ProtectDetails from "./ProtectDetails";
import GenericDetails from "./GenericDetails";
import { OLProtect } from "../../../../constants/OtpFactorTypes";
import PropTypes from "prop-types";

const MfaDetails = ({ device: { factorTypeId, details } }) => {
  // Render the non-string format with the specialized details component
  if (factorTypeId === OLProtect && typeof details === "object") {
    return <ProtectDetails details={details} />;
  }

  if (["string", "number"].includes(typeof details)) {
    return <GenericDetails details={details} />;
  }

  return null;
};

MfaDetails.propTypes = {
  device: PropTypes.shape({
    factorTypeId: PropTypes.number.isRequired,
    details: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.shape({
        os: PropTypes.shape({
          name: PropTypes.string.isRequired,
          version: PropTypes.string.isRequired
        }),
        device: PropTypes.shape({
          vendor: PropTypes.string.isRequired,
          model: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired
        })
      })
    ])
  }).isRequired
};

export default MfaDetails;
