import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, defineMessages } from "react-intl";
import {
  EnterRegistrationCode,
  ContactAdmin,
  LoginLoading,
  WaitForEmail,
  EnterEmail
} from "@onelogin/react-components";

import getMfaApi, {
  REGISTRATION_ACCEPTED,
  REGISTRATION_REJECTED
} from "../../../../api/mfaService";

import PeriodicStatusCheck from "../PeriodicStatusCheck";

const messages = defineMessages({
  invalidCode: "Invalid code",
  registrationTimedout: "Registration context timed out",
  codeResent: "Code has been sent to your email."
});

const getInitialState = () => ({
  loading: false,
  email: null,
  emailOtpType: 0,
  error: null
});

class RegisterEmailOtp extends Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();

    this.mfaService = getMfaApi(
      this.props.trackingId,
      this.props.defaultLanguage
    );
    this.registrationId = undefined;
  }

  componentDidMount = () => {
    this.setState({
      loading: true
    });
    this.initRegistration();
  };

  initRegistration = () => {
    this.mfaService
      .initRegistration(this.props.mfaApiToken, this.props.factorId)
      .then(({ id, payload }) => {
        this.registrationId = id;
        this.setState({
          error: payload.error,
          email: payload.email,
          emailOtpType: payload.email_otp_type,
          allowEdit: payload.allow_edit,
          loading: false
        });
      })
      .catch(err => err.response.json().then(this.props.onError));
  };

  resendCode = (email = null) => {
    if (email) {
      this.setState({
        email: email
      });
    }

    let eupEmail = email ? JSON.stringify({ email: email }) : "";

    this.mfaService
      .resendRegistrationOtp(
        this.props.mfaApiToken,
        this.registrationId,
        eupEmail
      )
      .then(({ payload }) => {
        payload = JSON.parse(payload);

        this.setState({
          error: payload.error,
          loading: false
        });

        if (!payload.error) this.props.showMfaNotification(messages.codeResent);
      })
      .catch(err => err.response.json().then(this.handleRegistrationError));
  };

  onTryAgain = () => {
    this.setState({
      loading: true
    });

    if (this.state.allowEdit) {
      this.setState({
        loading: false,
        email: ""
      });
    } else {
      this.resendCode();
    }
  };

  checkStatus = () => {
    this.mfaService
      .getRegistration(this.props.mfaApiToken, this.registrationId)
      .then(body => {
        if (body.status === REGISTRATION_ACCEPTED) {
          this.props.onSuccess();
        } else if (body.status === REGISTRATION_REJECTED) {
          this.props.onError();
        }
      })
      .catch(this.handleRegistrationError);
  };

  handleRegistrationError = err => {
    if (err && err.code === 404) {
      this.setState(getInitialState());
      this.props.onError(messages.registrationTimedout);
    } else {
      this.props.onError(err);
    }
  };

  onSubmitEmail = email => {
    this.setState({
      loading: true
    });
    this.resendCode(email);
  };

  onSubmitOtp = otp => {
    this.setState({
      loading: true
    });
    const { mfaApiToken } = this.props;
    this.mfaService
      .submitRegistrationOtp(mfaApiToken, this.registrationId, otp)
      .then(body => {
        switch (body.status) {
          case "accepted":
            this.props.onSuccess();
            break;
          case "rejected":
            this.props.onError();
            break;
          default:
            this.props.onError(messages.invalidCode);
            this.setState({
              loading: false
            });
        }
      })
      .catch(err => err.response.json().then(this.handleRegistrationError));
  };

  enterEmail = () => {
    return this.state.allowEdit && this.state.email === "";
  };

  render = () => {
    if (this.state.loading) {
      return <LoginLoading />;
    }

    if (this.state.error) {
      return (
        <ContactAdmin
          formattedMessage={
            <FormattedMessage
              defaultMessage="Contact your admin to set up an email for your Email OTP."
              id="contactAdminEmailOtp"
            />
          }
          onContinue={this.props.onClose}
        />
      );
    }

    if (this.enterEmail()) {
      return (
        <EnterEmail
          onSubmitEmail={this.onSubmitEmail}
          email={this.state.email}
        />
      );
    }

    if (!this.state.emailOtpType || this.state.emailOtpType === 0) {
      return (
        <EnterRegistrationCode
          onSubmit={this.onSubmitOtp}
          contactInfo={this.state.email}
          onTryAgain={this.onTryAgain}
        />
      );
    }

    return (
      <div>
        <PeriodicStatusCheck
          checkStatus={this.checkStatus}
          checkPeriodMs={1000}
        />
        <WaitForEmail email={this.state.email} onTryAgain={this.onTryAgain} />
      </div>
    );
  };
}

RegisterEmailOtp.propTypes = {
  mfaApiToken: PropTypes.string.isRequired,
  factorId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  showMfaNotification: PropTypes.func.isRequired,
  trackingId: PropTypes.string.isRequired,
  defaultLanguage: PropTypes.string.isRequired
};

export default RegisterEmailOtp;
