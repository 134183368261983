import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  all,
  drop,
  fromPairs,
  keys,
  pick,
  splitAt,
  sort,
  take,
  values,
  without
} from "ramda";

import { WIDE_VIEW_LIMIT } from "../../../constants/Env";
import ResponsiveBlock from "../../ResponsiveBlock";
import ButtonGroup from "./ButtonGroup";
import AnswerQuestion from "./AnswerQuestion";
import SecurityQuestionsInfo from "./SecurityQuestionsInfo";

import { COLORS, Button, Icon, Loading } from "@onelogin/react-components";

// Basic sort comparator
const comp = (a, b) => a - b;

const ResponsiveView = styled(ResponsiveBlock)`
  width: 100%;
  max-width: ${WIDE_VIEW_LIMIT}px;
`;

const QuestionGroup = styled.div`
  width: 100%;
  padding: 32px 22px 32px 0;
  border-right: 1px solid ${COLORS.SEPARATOR_COLORS.MEDIUM};

  @media screen and (max-width: ${WIDE_VIEW_LIMIT}px) {
    padding-right: 0;
    border: 0;
  }
`;

const SubHeader = styled.div`
  font-size: 18px;
  color: #3f4040;
  margin-bottom: 30px;
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

class QuestionHandler extends Component {
  constructor(props) {
    super(props);

    const { questions, required } = props;

    const questionKeys = keys(questions);

    const picked = take(required, questionKeys);
    this.state = {
      loading: false,
      picked,
      available: drop(required, questionKeys),
      answerMap: fromPairs(questionKeys.map(id => [id, ""]))
    };
  }

  replace(oldItem, newItem, list) {
    return list.map(item => (item === oldItem ? newItem : item));
  }

  // If one of the question dropdowns picks a new question, the new question is moved to the "picked" list,
  // and the old question is moved to the "available" list. The old question's answer is also removed from
  // the answer map, which means that if they reselect the old question, their answer isn't there.
  onPick = (oldKey, newKey) => {
    if (oldKey === newKey) {
      // This fixes a bug where a question being deleted causes the questions after it to lose their answers
      return;
    }

    const { picked, available, answerMap } = this.state;
    this.setState({
      picked: this.replace(oldKey, newKey, picked),
      available: this.replace(newKey, oldKey, available),
      answerMap: {
        ...answerMap,
        [oldKey]: "" // Deleting the previous answer to prevent confusion
      }
    });
  };

  // When the user wants to add a question, the first available question ID is added to the list
  // of picked questions
  onAddQuestion = () => {
    const { picked, available } = this.state;
    // Just in case
    if (available.length === 0) {
      return;
    }

    const [newPicked, ...rest] = available;
    this.setState({
      picked: [...picked, newPicked],
      available: rest
    });
  };

  // Remove a question from the form
  onRemove = questionId => {
    const { picked, available, answerMap } = this.state;
    this.setState({
      picked: without([questionId], picked),
      available: [questionId, ...available],
      answerMap: {
        ...answerMap,
        [questionId]: ""
      }
    });
  };

  // Updating the answer map
  onChange = (questionId, value) => {
    const answerMap = this.state.answerMap;
    this.setState({
      answerMap: {
        ...answerMap,
        [questionId]: value
      }
    });
  };

  validateAnswers = answers => {
    const { required } = this.props;

    const validAnswer = str => typeof str === "string" && str.length > 0;
    return (
      keys(answers).length >= required && all(validAnswer, values(answers))
    );
  };

  currentAnswers = () => {
    const { picked, answerMap } = this.state;
    return pick(picked, answerMap);
  };

  renderRequired = requiredList => {
    const { questions, required } = this.props;
    const { available, answerMap } = this.state;
    const availableQuestions = sort(comp, available).map(key => ({
      value: key,
      text: questions[key]
    }));

    // If all questions must be answered, there's no need for a header to say "required", they all are
    const shouldShowSubHeader = keys(questions).length > required;

    return (
      <>
        {shouldShowSubHeader && (
          <SubHeader>
            <FormattedMessage
              defaultMessage="Required Questions"
              id="requiredQuestions"
            />
          </SubHeader>
        )}
        {requiredList.map((questionId, index) => {
          const questionText = questions[questionId];
          return (
            <AnswerQuestion
              key={questionId}
              questionId={questionId}
              available={[
                { value: questionId, text: questionText },
                ...availableQuestions
              ]}
              position={index + 1}
              value={answerMap[questionId]}
              onPick={this.onPick}
              onChange={this.onChange}
            />
          );
        })}
      </>
    );
  };

  renderExtra = additionalList => {
    const { questions, required } = this.props;
    const { picked, available, answerMap } = this.state;
    // Sorting everytime would be a big deal if there were more than a few thousand possible questions,
    // but there are like 3 dozen
    const availableQuestions = sort(comp, available).map(key => ({
      value: key,
      text: questions[key]
    }));

    return (
      <>
        <SubHeader>
          <FormattedMessage
            defaultMessage="Additional Questions"
            id="additionalQuestions"
          />
        </SubHeader>
        {additionalList.map((questionId, index) => {
          const questionText = questions[questionId];
          return (
            <AnswerQuestion
              key={index}
              questionId={questionId}
              available={[
                { value: questionId, text: questionText },
                ...availableQuestions
              ]}
              position={index + required + 1}
              value={answerMap[questionId]}
              showRemove={picked.length > required}
              onPick={this.onPick}
              onChange={this.onChange}
              onRemove={this.onRemove}
            />
          );
        })}
        <Button
          type="submit"
          look="outline"
          onClick={this.onAddQuestion}
          data-testid="add-security-question"
          disabled={available.length === 0}
        >
          <ButtonText>
            <Icon name="Plus-24" onClick={this.onAddQuestion} alt="" />
            <FormattedMessage
              defaultMessage="Add New Security Question"
              id="addNewSecurityQuestion"
            />
          </ButtonText>
        </Button>
      </>
    );
  };

  render = () => {
    const { picked, loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    const { required, onSubmit } = this.props;
    const [requiredList, extraList] = splitAt(required, picked);

    return (
      <>
        <ResponsiveView noPaddings columnTrigger={WIDE_VIEW_LIMIT}>
          <QuestionGroup>
            {this.renderRequired(requiredList)}
            {this.renderExtra(extraList)}
          </QuestionGroup>
          <SecurityQuestionsInfo required={required} />
        </ResponsiveView>
        <ButtonGroup>
          <Button
            type="submit"
            look="primary"
            onClick={() => onSubmit(this.currentAnswers())}
            data-testid="submit-security-questions"
            disabled={!this.validateAnswers(this.currentAnswers())}
          >
            Submit
          </Button>
        </ButtonGroup>
      </>
    );
  };
}

QuestionHandler.propTypes = {
  questions: PropTypes.object.isRequired,
  required: PropTypes.number,
  onSubmit: PropTypes.func.isRequired
};

export default QuestionHandler;
