import React from "react";
import styled from "styled-components";
import { ModalDialog, Button } from "@onelogin/react-components";
import { FormattedMessage } from "react-intl";
import Cropper from "react-easy-crop";
import { commonMessages } from "../../../translations/commonMessages";
import PropTypes from "prop-types";

const Container = styled.div`
  font-family: Roboto;
  font-size: 14px;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
`;

const CropperWrapper = styled.div`
  position: relative;
  height: 350px;
  width: 100%;
  background-color: white;
`;

const PhotoCropDialog = props => {
  return (
    <ModalDialog onClose={props.onClose} closeOnClick>
      <ModalDialog.Header>
        <FormattedMessage defaultMessage="Image Upload" id={"imageUpload"} />
      </ModalDialog.Header>

      <Container>
        <CropperWrapper>
          <Cropper
            image={props.imageToCrop}
            crop={props.crop}
            zoom={props.zoom}
            aspect={1}
            onCropChange={props.onCropChange}
            onCropComplete={props.onCropComplete}
            onZoomChange={props.onZoomChange}
            cropShape={"round"}
            cropSize={{ height: 318, width: 318 }}
            minZoom={0.8}
            restrictPosition={false}
          />
        </CropperWrapper>
      </Container>
      <ModalDialog.Actions>
        <Button onClick={props.onClose} data-testid="cancel-button">
          <FormattedMessage {...commonMessages.cancel} />
        </Button>
        <Button
          onClick={props.onSave}
          look="primary"
          data-testid="save-changes-button"
        >
          <FormattedMessage {...commonMessages.save} />
        </Button>
      </ModalDialog.Actions>
    </ModalDialog>
  );
};

const cropShape = PropTypes.shape({
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired
});

PhotoCropDialog.propTypes = {
  crop: cropShape,
  imageToCrop: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onCropChange: PropTypes.func.isRequired,
  onCropComplete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  zoom: PropTypes.number.isRequired
};

export default PhotoCropDialog;
