import React from "react";
import { FormattedMessage } from "react-intl";
import { Content, ContentBlock, Label } from "@onelogin/react-components";

export const RedirectionNotice = () => (
  <Content data-testid="click-redirect-url">
    <ContentBlock>
      <Label forId="click-redirect-url">
        <FormattedMessage defaultMessage="You are being redirected to complete the MFA verification." />
      </Label>
    </ContentBlock>
  </Content>
);
