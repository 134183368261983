import React from "react";
import { FormattedMessage } from "react-intl";
import { PUBLIC_URL } from "../../../constants/Env";
import { RECENT_ACTIVITY_PATH } from "../../../constants/Menu";
import { isEmpty, isNil } from "ramda";

const RECENT_ACTIVITY_ROOT_URL = `${PUBLIC_URL}/${RECENT_ACTIVITY_PATH}`;
export const getFilterUrl = (filter = "") =>
  !isEmpty(filter) && !isNil(filter)
    ? `${RECENT_ACTIVITY_ROOT_URL}/filter/${filter}`
    : RECENT_ACTIVITY_ROOT_URL;
export const ALL_FILTER = "all";

const userAuthDisplay = (
  <FormattedMessage defaultMessage="Login" id="loginEvent" />
);

const FilterData = [
  {
    filter: ALL_FILTER,
    display: <FormattedMessage defaultMessage={ALL_FILTER} id="all" />,
    href: RECENT_ACTIVITY_ROOT_URL
  },
  {
    filter: "login",
    display: <FormattedMessage defaultMessage="login" id="login" />,
    href: getFilterUrl("login")
  },
  {
    filter: "password",
    display: <FormattedMessage defaultMessage="password" id="password" />,
    href: getFilterUrl("password")
  },
  {
    filter: "factors",
    display: <FormattedMessage defaultMessage="factors" id="factors" />,
    href: getFilterUrl("factors")
  }
];

const EventsData = [
  {
    type: "USER_LOGGED_INTO_ONELOGIN",
    filter: "login",
    displayName: userAuthDisplay,
    showDevice: true,
    showUserAgentDetails: true
  },
  {
    type: "ONELOGIN_DESKTOP_MAC_LOGIN_SUCCESS",
    filter: "login",
    displayName: userAuthDisplay,
    showDevice: true,
    showUserAgentDetails: true
  },
  {
    type: "ONELOGIN_DESKTOP_LOGIN_SUCCESS",
    filter: "login",
    displayName: userAuthDisplay,
    showDevice: true,
    showUserAgentDetails: true
  },
  {
    type: "USER_SUCCEEDED_PROXY_LOGIN",
    filter: "login",
    displayName: userAuthDisplay,
    showDevice: true,
    showUserAgentDetails: true
  },
  {
    type: "USER_AUTHENTICATED_BY_API",
    filter: "login",
    displayName: userAuthDisplay,
    showDevice: true,
    showUserAgentDetails: true
  },
  {
    type: "MAC_LOGIN_SUCCESS",
    filter: "login",
    displayName: userAuthDisplay,
    showDevice: true,
    showUserAgentDetails: true
  },
  {
    type: "USER_AUTHENTICATED_BY_RADIUS",
    filter: "login",
    displayName: (
      <FormattedMessage
        defaultMessage="Service Login via Radius"
        id="radiusAuthentication"
      />
    ),
    showDevice: false,
    showUserAgentDetails: false
  },
  {
    type: "USER_FAILED_ONELOGIN_LOGIN",
    filter: "login",
    displayName: (
      <FormattedMessage defaultMessage="Login Failed" id="loginFailedEvent" />
    ),
    showDevice: false,
    showUserAgentDetails: false
  },
  {
    type: "FORCE_LOGOUT_USER",
    filter: "login",
    displayName: (
      <FormattedMessage defaultMessage="Forced Logout" id="forcedLogout" />
    ),
    showDevice: false,
    showUserAgentDetails: false
  },
  {
    type: "USER_LOGGED_OUT_OF_ONELOGIN",
    filter: "login",
    displayName: (
      <FormattedMessage defaultMessage="Logged Out" id="loggedOut" />
    ),
    showDevice: true,
    showUserAgentDetails: true
  },
  {
    type: "USER_CHANGED_PASSWORD",
    filter: "password",
    displayName: (
      <FormattedMessage
        defaultMessage="Password Changed"
        id="passwordChanged"
      />
    ),
    showDevice: false,
    showUserAgentDetails: false
  },
  {
    type: "PROFILE_CHANGE_PASSWORD",
    filter: "password",
    displayName: (
      <FormattedMessage
        defaultMessage="Password Changed"
        id="passwordChanged"
      />
    ),
    showDevice: false,
    showUserAgentDetails: false
  },
  {
    type: "ADMIN_CHANGED_USER_PASSWORD",
    filter: "password",
    displayName: (
      <FormattedMessage defaultMessage="Password Reset" id="passwordReset" />
    ),
    showDevice: false,
    showUserAgentDetails: false
  },
  {
    type: "SECURITY_QUESTIONS_UPDATED",
    filter: "factors",
    displayName: (
      <FormattedMessage
        defaultMessage="Security Factors Updated"
        id="securityFactorsUpdated"
      />
    ),
    showDevice: false,
    showUserAgentDetails: false
  },
  {
    type: "USER_REMOVED_OTP_DEVICE",
    filter: "factors",
    displayName: (
      <FormattedMessage
        defaultMessage="Security Factor Removed"
        id="securityFactorRemoved"
      />
    ),
    showDevice: true,
    showUserAgentDetails: false
  },
  {
    type: "USER_ADDED_OTP_DEVICE",
    filter: "factors",
    displayName: (
      <FormattedMessage
        defaultMessage="Security Factor Added"
        id="securityFactorAdded"
      />
    ),
    showDevice: true,
    showUserAgentDetails: false
  },
  {
    type: "PROFILE_DEVICES_RENAME_DEVICE",
    filter: "factors",
    displayName: (
      <FormattedMessage
        defaultMessage="Security Factor Renamed"
        id="securityFactorRenamed"
      />
    ),
    showDevice: true,
    showPrevious: true,
    showUserAgentDetails: false
  }
];

export const getEventTypesByFilter = filter => {
  return EventsData.filter(
    item => item.filter.toLowerCase() === filter.toLowerCase()
  ).map(item => item.type);
};

export const getFilters = () => {
  return FilterData;
};

export const getEventByType = type => {
  const data = EventsData.filter(item => item.type === type);
  if (data && Array.isArray(data)) {
    return data[0];
  }
  return null;
};

export const filterEvents = (events, filterName) => {
  return events.filter(event =>
    filterName
      ? getEventTypesByFilter(filterName).includes(event.eventType)
      : true
  );
};
