import React, { Component } from "react";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import { FormattedMessage, defineMessages } from "react-intl";

import {
  ShowPinCode,
  ContactAdmin,
  LoginLoading,
  EnterPhoneNumber
} from "@onelogin/react-components";
import PeriodicStatusCheck from "../PeriodicStatusCheck";
import getMfaApi, {
  REGISTRATION_ACCEPTED,
  REGISTRATION_REJECTED
} from "../../../../api/mfaService";

import phoneNumberValidator from "../register/mfa/utils/phoneNumberValidator";
import maskPhoneNumber from "../register/mfa/utils/maskPhoneNumber";

const messages = defineMessages({
  registrationTimedout: "Registration context timed out",
  callUserAgain: "Calling you again",
  invalidPhoneNumber: "Please use valid phone number.",
  invalidCode: "We could not validate your code. Please try again."
});

const getInitialState = () => ({
  loading: false,
  allowEdit: false,
  enteredNumber: undefined,
  displayNumber: undefined,
  verificationToken: null
});

class RegisterVoice extends Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();

    this.mfaService = getMfaApi(
      this.props.trackingId,
      this.props.defaultLanguage
    );
    this.registrationId = undefined;
  }

  componentDidMount = () => {
    this.setState({
      loading: true
    });
    this.initRegistration();
  };

  initRegistration = () => {
    this.mfaService
      .initRegistration(this.props.mfaApiToken, this.props.factorId)
      .then(({ id, payload, verificationToken }) => {
        this.registrationId = id;
        this.setState({
          displayNumber: payload.phone_number || undefined,
          allowEdit: payload.allow_edit || false, //delete false for phase 2
          verificationToken: verificationToken,
          loading: false
        });
      })
      .catch(err => err.response.json().then(this.props.onError));
  };

  resendCode = (phoneNumber = undefined) => {
    if (phoneNumber) {
      this.setState({
        enteredNumber: phoneNumber,
        displayNumber: maskPhoneNumber(phoneNumber)
      });
    }

    let payload = phoneNumber
      ? JSON.stringify({ phone_number: phoneNumber })
      : "";

    this.mfaService
      .resendRegistrationOtp(
        this.props.mfaApiToken,
        this.registrationId,
        payload
      )
      .then(({ verification_token }) => {
        this.setState({
          loading: false,
          verificationToken: verification_token
        });
        this.props.showMfaNotification(messages.callUserAgain);
      })
      .catch(err => err.response.json().then(this.handleRegistrationError));
  };

  onTryAgain = () => {
    this.setState({
      loading: true
    });

    if (this.state.allowEdit) {
      this.setState({
        loading: false,
        displayNumber: undefined
      });
    } else {
      this.resendCode();
    }
  };

  onSubmitPhoneNumber = phoneNumber => {
    this.setState({
      loading: true
    });
    this.resendCode(phoneNumber);
  };

  handleRegistrationError = err => {
    if (err && err.code === 404) {
      this.setState(getInitialState());
      this.props.onError(messages.registrationTimedout);
    } else {
      this.props.onError(err);
    }
  };

  checkStatus = () => {
    this.mfaService
      .getRegistration(this.props.mfaApiToken, this.registrationId)
      .then(body => {
        if (body.status === REGISTRATION_ACCEPTED) {
          this.props.onSuccess();
        } else if (body.status === REGISTRATION_REJECTED) {
          this.props.onError();
        }
      })
      .catch(err => err.response.json().then(this.handleRegistrationError));
  };

  validateAndCallUser = phoneNumber => {
    phoneNumber = phoneNumber.trim();
    if (phoneNumberValidator(phoneNumber)) {
      this.onSubmitPhoneNumber(phoneNumber);
    } else {
      this.props.onError(messages.invalidPhoneNumber);
    }
  };

  contactAdmin = () => !this.state.allowEdit && isNil(this.state.displayNumber);

  enterPhoneNumber = () =>
    this.state.allowEdit && isNil(this.state.displayNumber);

  render = () => {
    if (this.state.loading) {
      return <LoginLoading />;
    }

    if (this.contactAdmin()) {
      return (
        <ContactAdmin
          formattedMessage={
            <FormattedMessage
              defaultMessage="Contact your admin to set up a phone number for your Voice OTP."
              id="contactAdminForVoice"
            />
          }
          onContinue={this.props.onClose}
        />
      );
    }

    if (this.enterPhoneNumber()) {
      return (
        <EnterPhoneNumber
          onSubmitSMS={this.validateAndCallUser}
          phoneNumber={this.state.enteredNumber}
        />
      );
    }

    return (
      <div>
        <PeriodicStatusCheck
          checkStatus={this.checkStatus}
          checkPeriodMs={2000}
        />
        <ShowPinCode
          phoneNumber={this.state.displayNumber}
          onTryAgain={this.onTryAgain}
          verificationToken={this.state.verificationToken}
        />
      </div>
    );
  };
}

RegisterVoice.propTypes = {
  mfaApiToken: PropTypes.string.isRequired,
  factorId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  showMfaNotification: PropTypes.func.isRequired,
  trackingId: PropTypes.string.isRequired,
  defaultLanguage: PropTypes.string.isRequired
};

export default RegisterVoice;
