import { map } from "ramda";
import locale from "locale2";
import client from "./httpClient";
import * as apiConstants from "../constants/Api";
const baseUrl = `${apiConstants.SERVER_URL}`;

export const VERIFICATION_ACCEPTED = "accepted";
export const VERIFICATION_REJECTED = "rejected";

export const REGISTRATION_ACCEPTED = "accepted";
export const REGISTRATION_REJECTED = "rejected";

export const BEHAVIOR_POLL = "poll";

export const QR_ROTATION_INTERVAL = 120000;

export const FACTOR_TYPES = {
  EMAIL: 0,
  YUBIKEY: 1,
  VIPACCESS: 2,
  FIREID: 3,
  DIGIPASS: 4,
  IDENTIKEY: 5,
  SAFENET: 6,
  SECURID: 7,
  ONELOGIN: 8,
  AUTHENTICATOR: 10,
  SMS: 11,
  DUOSECURITY: 12,
  SECURITYQUESTIONS: 13,
  PINSAFE: 14,
  TEMP_OTP: 16,
  VOICE: 17,
  WEBAUTHN_OTP: 18,
  EMAIL_OTP: 19,
  TRUSTED_IDP: 20,
  OATH: 22
};

const convertPayload = JSON.parse;

const extractBody = ({ data }) => data;

const convertOtp = otp => {
  if (typeof otp === "string") {
    return otp;
  } else {
    return JSON.stringify(otp);
  }
};

export default (trackingId, defaultLanguage) => {
  const acceptLanguage = `${locale},${defaultLanguage};q=1`;

  const getToken = async profileServiceToken => {
    const response = await client
      .get(`${baseUrl}/mfa/v1/profile/auth`, {
        headers: {
          Authorization: `Bearer ${profileServiceToken}`,
          "X-Correlation-Id": trackingId,
          "Accept-Language": acceptLanguage
        }
      })
      .json();

    return response;
  };

  const verifyEmail = async otp => {
    return await client
      .put(
        `${baseUrl}/mfa/v1/otp/email`,
        JSON.stringify({
          otp
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": acceptLanguage
          }
        }
      )
      .then(extractBody);
  };

  const listUserDevices = async mfaToken => {
    return await client
      .get(`${baseUrl}/mfa/v1/devices`, {
        headers: {
          Authorization: `Bearer ${mfaToken}`,
          "X-Correlation-Id": trackingId,
          "Accept-Language": acceptLanguage
        }
      })
      .json()
      .then(
        map(device => ({
          id: device.id,
          factorTypeId: device.type_id,
          factorName: device.factor_name,
          customIconUrl: device.custom_icon_url,
          default: device.default,
          behavior: device.behavior
        }))
      );
  };

  const listFactors = async mfaToken => {
    return await client
      .get(`${baseUrl}/mfa/v1/factors`, {
        headers: {
          Authorization: `Bearer ${mfaToken}`,
          "X-Correlation-Id": trackingId,
          "Accept-Language": acceptLanguage
        }
      })
      .json()
      .then(
        map(factor => ({
          id: factor.id,
          typeId: factor.type_id,
          name: factor.name,
          customIconUrl: factor.custom_icon_url
        }))
      );
  };

  /*
   * =============================================================================
   * Registration API:
   */

  const initRegistration = async (mfaToken, factorId) => {
    return await client
      .post(`${baseUrl}/mfa/v1/registrations`, {
        headers: {
          Authorization: `Bearer ${mfaToken}`,
          "Content-Type": "application/json",
          "X-Correlation-Id": trackingId,
          "Accept-Language": acceptLanguage
        },
        body: JSON.stringify({ factor_id: factorId })
      })
      .json()
      .then(registration => ({
        id: registration.id,
        verificationToken: registration.verification_token,
        totpUrl: registration.totp_url,
        payload: registration.payload && convertPayload(registration.payload)
      }));
  };

  const getRegistration = async (mfaToken, registrationId) => {
    return await client
      .get(`${baseUrl}/mfa/v1/registrations/${registrationId}`, {
        headers: {
          Authorization: `Bearer ${mfaToken}`,
          "X-Correlation-Id": trackingId,
          "Accept-Language": acceptLanguage
        }
      })
      .json();
  };

  const resendRegistrationOtp = async (mfaToken, registrationId, payload) => {
    return await client
      .post(`${baseUrl}/mfa/v1/registrations/${registrationId}/otp`, {
        headers: {
          Authorization: `Bearer ${mfaToken}`,
          "Content-Type": "application/json",
          "X-Correlation-Id": trackingId,
          "Accept-Language": acceptLanguage
        },
        body: JSON.stringify({ payload })
      })
      .json();
  };

  const submitRegistrationOtp = async (mfaToken, registrationId, otp) => {
    return await client
      .put(`${baseUrl}/mfa/v1/registrations/${registrationId}`, {
        method: "put",
        headers: {
          Authorization: `Bearer ${mfaToken}`,
          "Content-Type": "application/json",
          "X-Correlation-Id": trackingId,
          "Accept-Language": acceptLanguage
        },
        body: JSON.stringify({
          otp: convertOtp(otp)
        })
      })
      .json();
  };

  return {
    getToken,
    verifyEmail,
    listUserDevices,
    listFactors,
    initRegistration,
    getRegistration,
    resendRegistrationOtp,
    submitRegistrationOtp
  };
};
