import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Icon } from "@onelogin/react-components";
import PropTypes from "prop-types";

const TrustedIcon = styled(Icon).attrs({
  icon: "Checkmark-24",
  size: "24px"
})`
  margin-right: 12px;
`;

const UntrustedIcon = styled(Icon).attrs({
  icon: "Alert",
  size: "24px"
})`
  margin-right: 12px;
`;

const TrustDeviceCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 6px;
`;

export const TrustedDevice = ({ size }) => (
  <TrustDeviceCell>
    <TrustedIcon size={size} />
    <FormattedMessage defaultMessage="Trusted" id={"trusted"} />
  </TrustDeviceCell>
);

TrustedDevice.propTypes = {
  size: PropTypes.string.isRequired
};

export const UntrustedDevice = ({ size }) => (
  <TrustDeviceCell>
    <UntrustedIcon size={size} />
    <FormattedMessage defaultMessage="Alert" id={"alert"} />
  </TrustDeviceCell>
);

UntrustedDevice.propTypes = {
  size: PropTypes.string.isRequired
};
