import React, { Component } from "react";
import styled from "styled-components";
import { withRouter, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ReactMarkdown from "react-markdown";

import { Button, TextField } from "@onelogin/react-components";
import PasswordRedirectModal from "./PasswordRedirectModal";
import {
  NARROW_VIEW_LIMIT,
  MOBILE_VIEW_LIMIT,
  PUBLIC_URL
} from "../../../constants/Env";

const ScreenDescription = styled.div`
  padding: 0 32px;
  margin-bottom: 24px;

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    padding: 0 20px;
  }
`;

const ButtonWrapper = styled.div`
  margin-left: 32px;
  margin-bottom: 12px;

  @media screen and (max-width: ${MOBILE_VIEW_LIMIT}px) {
    margin-left: 20px;
  }
`;

class PasswordRedirect extends Component {
  renderDescription = (passwordRedirectUrl, passwordRedirectMessage) => {
    if (!passwordRedirectMessage && !passwordRedirectUrl) {
      return (
        <TextField>
          <FormattedMessage
            defaultMessage="Contact your admin to reset password."
            id="contactAdminPasswordReset"
          />
        </TextField>
      );
    } else if (passwordRedirectMessage && !passwordRedirectUrl) {
      return (
        <TextField>
          <ReactMarkdown source={passwordRedirectMessage} />
        </TextField>
      );
    } else {
      return (
        <TextField>
          <FormattedMessage
            defaultMessage="Your administrator has configured an external site for resetting your password."
            id="passwordRedirectDescription"
          />
        </TextField>
      );
    }
  };

  renderButton = (
    passwordRedirectUrl,
    passwordRedirectMessage,
    onClickChangePassword
  ) => {
    if (passwordRedirectUrl && passwordRedirectMessage) {
      return (
        <Button
          look="regular"
          data-testid="redirect-button"
          onClick={onClickChangePassword}
        >
          <FormattedMessage
            defaultMessage="Change Password"
            id="changePassword"
          />
        </Button>
      );
    } else if (passwordRedirectUrl && !passwordRedirectMessage) {
      return (
        <a href={passwordRedirectUrl} target="_blank" rel="noopener noreferrer">
          <Button look="regular" data-testid="redirect-button">
            <FormattedMessage
              defaultMessage="Go to external site"
              id="goToExternalSite"
            />
          </Button>
        </a>
      );
    } else {
      return null;
    }
  };

  render = () => {
    const {
      passwordRedirectUrl,
      passwordRedirectMessage,
      onClickChangePassword,
      onCancelChangePassword
    } = this.props;

    return (
      <>
        <ScreenDescription>
          {this.renderDescription(passwordRedirectUrl, passwordRedirectMessage)}
        </ScreenDescription>
        <ButtonWrapper>
          {this.renderButton(
            passwordRedirectUrl,
            passwordRedirectMessage,
            onClickChangePassword
          )}
        </ButtonWrapper>
        <Switch>
          <Route exact path={`${PUBLIC_URL}/password/redirect`}>
            <PasswordRedirectModal
              onCancelChangePassword={onCancelChangePassword}
              redirectUrl={passwordRedirectUrl}
              redirectMessage={passwordRedirectMessage}
            />
          </Route>
        </Switch>
      </>
    );
  };
}

PasswordRedirect.propTypes = {
  passwordRedirectUrl: PropTypes.string,
  passwordRedirectMessage: PropTypes.string,
  onClickChangePassword: PropTypes.func.isRequired,
  onCancelChangePassword: PropTypes.func.isRequired
};

export default withRouter(PasswordRedirect);
