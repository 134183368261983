import * as types from "../constants/ActionTypes";
import { PUBLIC_URL } from "../constants/Env";
import { ROOT_MENU_ITEM_ID, RECENT_ACTIVITY_PATH } from "../constants/Menu";

const filterMenuItems = (items, menuConditions = {}) =>
  items
    .filter(item => (item.visible ? item.visible(menuConditions) : true))
    .map(({ linkPath, id }) => {
      return { linkPath, id };
    });

const menuItemsData = [
  { linkPath: `${PUBLIC_URL}`, id: ROOT_MENU_ITEM_ID },
  {
    linkPath: `${PUBLIC_URL}/password`,
    id: "changePassword",
    visible: menuConditions => menuConditions.showChangePasswordMenu
  },
  {
    linkPath: `${PUBLIC_URL}/mfa`,
    id: "authentication"
  },
  {
    linkPath: `${PUBLIC_URL}/browser_extension`,
    id: "browserExtension",
    visible: menuConditions => menuConditions.showBrowserExtensionMenu
  },
  {
    linkPath: `${PUBLIC_URL}/desktop`,
    id: "oneloginDesktop",
    visible: menuConditions => menuConditions.showOlDesktopMenu
  },
  {
    linkPath: `${PUBLIC_URL}/${RECENT_ACTIVITY_PATH}`,
    id: "recentActivity"
  },
  {
    linkPath: `${PUBLIC_URL}/trusted_browsers`,
    id: "trustedBrowsers",
    visible: menuConditions => menuConditions.showTrustedBrowsersMenu
  }
];

const getActiveItemBy = (prop, value) =>
  menuItemsData.find(item => item[prop] === value);

const activeItemByLinkPath = location => {
  //select the option that is most specific, by sorting the longest match first
  const options = menuItemsData
    .filter(item => item.linkPath && location.startsWith(item.linkPath))
    .sort((a, b) => b.linkPath.length - a.linkPath.length);
  if (options && options.length > 0) {
    return options[0];
  } else {
    return null;
  }
};

export const initialState = {
  menuItemsData: filterMenuItems(menuItemsData),
  activeItem: activeItemByLinkPath(window.location.pathname) || menuItemsData[0]
};

function menu(state = initialState, action) {
  switch (action.type) {
    case types.SET_ACTIVE_MENU_ITEM:
      return {
        ...state,
        activeItem: getActiveItemBy("linkPath", action.path)
      };
    case types.FILTER_MENU_ITEMS:
      return {
        ...state,
        menuItemsData: filterMenuItems(menuItemsData, action.menuConditions)
      };
    default:
      return state;
  }
}

export default menu;
