import React, { useState } from "react";
import PropTypes from "prop-types";
import { LinkButton } from "@onelogin/react-components";
import ResponsiveTable from "../../common/Table/ResponsiveTable";
import { FormattedMessage } from "react-intl";
import {
  EventCellMap,
  DeviceCellMap,
  CompositeLocationTime
} from "./ActivityTableCells";
import { commonMessages } from "../../../translations/commonMessages";

const getTableStructure = props => {
  return {
    columns: [
      {
        minWidth: 150,
        weight: 33,
        priority: 3,
        content: <FormattedMessage defaultMessage="Event" id={"event"} />,
        key: "event"
      },
      {
        minWidth: 150,
        weight: 33,
        priority: 1,
        content: <FormattedMessage {...commonMessages.browserDevice} />,
        key: "device"
      },
      {
        minWidth: 170,
        weight: 33,
        priority: 2,
        content: (
          <FormattedMessage
            defaultMessage="Location/Time"
            id={"locationTime"}
          />
        ),
        key: "locationTime"
      },
      props.helpEnabled
        ? {
            minWidth: 90,
            weight: 10,
            priority: 3,
            content: (
              <FormattedMessage
                defaultMessage="Report Activity"
                id={"reportActivity"}
              />
            ),
            key: "report"
          }
        : undefined
    ].filter(item => item !== undefined)
  };
};

const ActivityTable = props => {
  const [tableStructure] = useState(getTableStructure(props));
  tableStructure.data = [];
  if (props.ActivityData) {
    for (let item of props.ActivityData) {
      const event = (
        <EventCellMap eventType={item.eventType} {...item.deviceDetails} />
      );
      const device = (
        <DeviceCellMap
          eventType={item.eventType}
          userAgentDevice={item.deviceDetails}
          otpDevice={item.otpDevice}
        />
      );

      const locationTime = (
        <CompositeLocationTime
          location={item.login.location}
          time={item.login.time}
          locale={props.locale}
        />
      );

      const cells = [
        {
          key: "id",
          content: item.event_id
        },
        {
          key: "event",
          content: event
        },
        { key: "device", content: device },
        {
          key: "locationTime",
          content: locationTime
        },
        props.helpEnabled
          ? {
              key: "report",
              content: (
                <LinkButton
                  onClick={() => {
                    props.onReportClick(item);
                  }}
                  data-testid="report-button"
                >
                  <FormattedMessage defaultMessage="Report" id="report" />
                </LinkButton>
              )
            }
          : undefined
      ].filter(item => item !== undefined);

      tableStructure.data.push({ cells, meta: {} });
    }

    tableStructure.expander = {
      isAlwaysVisible: true,
      onClick: data => {
        props.showDetails(data);
      },
      idKey: "id"
    };
  }

  return (
    <ResponsiveTable
      tableStructure={tableStructure}
      noDataMessage={
        <FormattedMessage
          id="noActivities"
          defaultMessage="No activities have been made"
        />
      }
    />
  );
};

ActivityTable.propTypes = {
  ActivityData: PropTypes.array.isRequired,
  showDetails: PropTypes.func.isRequired,
  onReportClick: PropTypes.func.isRequired,
  helpEnabled: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired
};

export default ActivityTable;
