import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { NARROW_VIEW_LIMIT, MENU_WIDTH } from "../constants/Env";
import Dropdown from "./Dropdown";
import MenuItem from "./MenuItem";
import { goto } from "../actions/navigationActions";

import { commonMessages } from "../translations/commonMessages";

const menuItemsTranslations = {
  profile: <FormattedMessage {...commonMessages.profile} />,
  changePassword: <FormattedMessage {...commonMessages.changePassword} />,
  authentication: <FormattedMessage {...commonMessages.securityFactors} />,
  browserExtension: <FormattedMessage {...commonMessages.browserExtension} />,
  oneloginDesktop: <FormattedMessage {...commonMessages.oneloginDesktop} />,
  recentActivity: <FormattedMessage {...commonMessages.recentActivity} />,
  trustedBrowsers: <FormattedMessage {...commonMessages.trustedBrowsers} />
};

const DropdownControl = styled.div`
  font-size: 16px;
  @media screen and (min-width: ${NARROW_VIEW_LIMIT + 1}px) {
    display: none;
  }
`;

const SideMenuWrapper = styled.div`
  padding-top: 16px;
  border-right: 1px solid #e8eaeb;

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    display: none;
  }
`;

const SideMenu = styled.div`
  padding-top: 4px;
  font-size: 14px;
  width: ${MENU_WIDTH}px;
`;

const mapMenuItems = (items, goto, activeItem) =>
  items.map(item => (
    <MenuItem
      key={item.id}
      id={item.id}
      onClick={event => goto(item.linkPath)}
      active={activeItem.id === item.id}
      linkPath={item.linkPath}
    >
      {menuItemsTranslations[item.id]}
    </MenuItem>
  ));

const Menu = ({ menuItemsData, activeItem, goto }) => {
  const menuItems = mapMenuItems(menuItemsData, goto, activeItem);

  return (
    <>
      <DropdownControl>
        <Dropdown activeItemTitle={menuItemsTranslations[activeItem.id]}>
          {menuItems}
        </Dropdown>
      </DropdownControl>
      <SideMenuWrapper>
        <SideMenu>{menuItems}</SideMenu>
      </SideMenuWrapper>
    </>
  );
};

const mapStateToProps = state => ({
  menuItemsData: state.menu.menuItemsData,
  activeItem: state.menu.activeItem
});

const mapDispatchToProps = (dispatch, props) => ({
  goto: path => {
    dispatch(goto(props.history, path));
  }
});

const menuItemShape = PropTypes.shape({
  linkPath: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
});

Menu.propTypes = {
  menuItemsData: PropTypes.arrayOf(menuItemShape).isRequired,
  activeItem: menuItemShape.isRequired,
  goto: PropTypes.func.isRequired
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Menu)
);
