import React from "react";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { Route, Switch } from "react-router-dom";
import { GoogleAnalytics } from "../utils/googleAnalytics";

import {
  Notifications,
  Loading,
  CommonLayout,
  BREAKPOINTS,
  COLORS,

} from "@onelogin/react-components";
import ProfileContent from "./ProfileContent";
import HelpContent from "./HelpContent";
import UnsupportedBrowser from "./UnsupportedBrowser";
import AssumedUserNotificationBanner from "./AssumedUserNotificationBanner";
import { clearNotification } from "../actions/notificationsActions";
import userTabLinksSelector from "../selectors/userTabLinksSelector";
import { PUBLIC_URL, WIDE_VIEW_LIMIT } from "../constants/Env";

import { proceedWithUnsupportedBrowser } from "../actions/otherActions";

const goLogout = () => {
  // eventsEmitter.userLogout();
  window.location.replace("/logout"); //TODO: navigation actions?
};

const MainContent = styled.main`
  top: 0px;
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  position: relative;
  max-height: calc(100vh - 0px);
  width: 100%;
  height: calc(100vh - 0px);
  font-size: 14px;
  color: ${COLORS.GRAY_COLORS.GRAY_35};
`;

export const AppContent = ({
  notifications,
  loading,
  clearDisplayedNotification,
  myBrowser,
  isNavbarless,
  menus,
  isDeprecatedBrowser,
  proceedUnsupportedBrowser,
  isAssumed,
  isSupportedBrowser,
  onProceedWithUnsupportedBrowser
}) => {
  if (loading) {
    return <Loading />;
  }

  const nodes =
    !isSupportedBrowser && !proceedUnsupportedBrowser ? (
      <UnsupportedBrowser
        myBrowser={myBrowser}
        onOk={() => onProceedWithUnsupportedBrowser(isDeprecatedBrowser)}
        onCancel={goLogout}
      />
    ) : (
      <Switch>
        <Route path={`${PUBLIC_URL}/help`} component={HelpContent} />
        <Route path={`${PUBLIC_URL}`} component={ProfileContent} />
      </Switch>
    );

  return (
    <>
      {isNavbarless ? (
        <MainContent role="main">
          <Notifications
            notifications={notifications}
            clearDisplayedNotification={clearDisplayedNotification}
          />
          {nodes}
        </MainContent>
      ) : (
        <CommonLayout
          menus={menus}
          projectRoot="profile2"
          navbarMaxWidth={WIDE_VIEW_LIMIT}
          mobileBreakpoint={parseInt(BREAKPOINTS.PORTAL_MIDDLE, 10)}
          notifications={notifications}
          clearDisplayedNotification={clearDisplayedNotification}
          flatifyMobileMenus
        >
          {isAssumed && <AssumedUserNotificationBanner />}
          {nodes}
        </CommonLayout>
      )}
      <GoogleAnalytics />
    </>
  );
};

const mapStateToProps = state => {
  const menus = [
    {
      initials: state.user.initials,
      name: state.user.fullName,
      picture: state.user.profilePicture,
      align: "right",
      links: userTabLinksSelector(state)
    }
  ];

  if (state.user.isAdmin) {
    menus.unshift({
      title: (
        <FormattedMessage id="administration" defaultMessage="Administration" />
      ),
      align: "right",
      href: "/admin"
    });
  }

  return {
    menus,
    notifications: state.notifications.notificationsQueue,
    myBrowser: state.environment.myBrowser,
    isSupportedBrowser: state.environment.isSupportedBrowser,
    isNavbarless: state.environment.isNavbarless,
    isDeprecatedBrowser: state.environment.isDeprecatedBrowser,
    proceedUnsupportedBrowser: state.environment.proceedUnsupportedBrowser,
    isAssumed: state.user.isAssumed,
    loading:
      state.user.loading || !state.branding.loaded || !state.account.loaded
  };
};

const mapDispatchToProps = dispatch => ({
  clearDisplayedNotification: notificationId => {
    dispatch(clearNotification(notificationId));
  },
  onProceedWithUnsupportedBrowser: isDeprecatedBrowser => {
    dispatch(proceedWithUnsupportedBrowser(isDeprecatedBrowser));
  }
});

AppContent.propTypes = {
  notifications: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  clearDisplayNotification: PropTypes.func,
  myBrowser: PropTypes.string.isRequired,
  isNavBarless: PropTypes.bool,
  menus: PropTypes.array.isRequired,
  isDeprecatedBrowser: PropTypes.bool.isRequired,
  proceedUnsupportedBrowser: PropTypes.bool.isRequired,
  isAssumed: PropTypes.bool,
  isSupportedBrowser: PropTypes.bool.isRequired,
  onProceedWithUnsupportedBrowser: PropTypes.func.isRequired
};

export default withTheme(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppContent)
);
