import React from "react";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  Label,
  ContentBlock,
  Dropdown,
  PasswordInput,
  Icon
} from "@onelogin/react-components";

const messages = defineMessages({
  sqDropdownAriaLabel: {
    id: "sqDropdownAriaLabel",
    defaultMessage: "Question {position} dropdown"
  },
  deleteQuestion: {
    id: "deleteQuestion",
    defaultMessage: "Delete question {position}"
  }
});

const InputWrapper = styled.div`
  padding-top: 22px;
`;

const Block = styled(ContentBlock)`
  margin-bottom: 30px;
`;

const LabelBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AnswerQuestion = ({
  questionId,
  available,
  position,
  value,
  showRemove,
  onPick,
  onChange,
  onRemove,
  intl
}) => {
  const questionInputId = `question_${position}`;
  const answerInputId = `answer_${position}`;

  return (
    <Block>
      <LabelBar>
        <Label forId={answerInputId}>
          <FormattedMessage
            defaultMessage="Question {position}"
            id="questionNumber"
            values={{ position: position }}
          />
        </Label>
        {showRemove && (
          <Icon
            id={`delete_question_${position}`}
            name="Trashcan-24"
            onClick={() => onRemove(questionId)}
            tabIndex={0}
            alt={intl.formatMessage(messages.deleteQuestion, { position })}
          />
        )}
      </LabelBar>
      <Dropdown
        ariaLabel={intl.formatMessage(messages.sqDropdownAriaLabel, {
          position
        })}
        onChange={value => onPick(questionId, value)}
        list={available}
        value={questionId}
        id={questionInputId}
      />
      <InputWrapper>
        <PasswordInput
          id={answerInputId}
          value={value}
          onChange={e => onChange(questionId, e.currentTarget.value)}
        />
      </InputWrapper>
    </Block>
  );
};

AnswerQuestion.propTypes = {
  questionId: PropTypes.string.isRequired,
  available: PropTypes.array.isRequired,
  position: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  showRemove: PropTypes.bool,
  onPick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func
};

export default injectIntl(AnswerQuestion);
