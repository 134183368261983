import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { ModalDialog, Icon, Button } from "@onelogin/react-components";
import styled from "styled-components";
import { commonMessages } from "../../../translations/commonMessages";

const ModalBodyWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 24px;
  justify-content: center;
`;

const ModalContentItem = styled.div`
  margin: 0 12px;
`;

const SwitchLanguageDialog = ({ newLanguage, onClose, onConfirm }) => (
  <ModalDialog fixedWidth={360}>
    <ModalBodyWrapper>
      <ModalContentItem>
        <Icon icon={"Alert"} size={"24"} alt="" />
      </ModalContentItem>
      <ModalContentItem>
        <FormattedMessage
          defaultMessage="Are you sure you want to switch to {language}?"
          id={"languageConfirmation"}
          values={{
            language: <b>{newLanguage}</b>
          }}
        />
      </ModalContentItem>
    </ModalBodyWrapper>
    <ModalDialog.Actions>
      <Button onClick={onClose} data-testid="cancel-button">
        <FormattedMessage {...commonMessages.cancel} />
      </Button>
      <Button look="primary" data-testid="switch-button" onClick={onConfirm}>
        <FormattedMessage defaultMessage="Switch" id={"switch"} />
      </Button>
    </ModalDialog.Actions>
  </ModalDialog>
);

SwitchLanguageDialog.propTypes = {
  newLanguage: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default SwitchLanguageDialog;
