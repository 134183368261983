//import polyfills; mainly needed for IE11 to work properly; features are used mostly by 3rd party libraries
import "blueimp-canvas-to-blob"; //Edge: canvas.toBlob, needed for profile image cropping (https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob)
import "core-js/es/array/find"; //IE11
import "core-js/es/array/find-index"; //IE11
import "core-js/es/array/includes"; //IE11
import "core-js/es/array/index-of"; //IE11
import "core-js/es/map"; //IE10
import "core-js/es/object/entries"; //IE11
import "core-js/es/reflect"; //IE11: Reflect (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Reflect)
import "core-js/es/set"; //IE10
import "core-js/es/string/includes"; //IE11
import "core-js/es/string/repeat"; //IE11
import "core-js/es/string/starts-with"; //IE11
import "core-js/es/symbol/iterator"; //IE11: Symbol.iterator; react-app-polyfill doesn't contain this one (https://github.com/zloirock/core-js/blob/v3.6.4/packages/core-js/modules/es.symbol.iterator.js)
import "core-js/es/weak-map"; //IE10
import "core-js/features/dom-collections/for-each"; //IE11 - for mutationsObserver used in navbar2
import "react-app-polyfill/ie11"; //IE11: Promise, window.fetch, Object.assign, Symbol, Array.from (https://www.npmjs.com/package/react-app-polyfill)
import "./customEventPolyfill"; //IE11: window.CustomEvent()

//IE10 only, remove when support for ie10 is deprecated
import * as trackEvents from "./actionsLog";
import { intlPolyfill } from "./translations";
import { loadAnalytics } from "./utils/googleAnalytics";

import isDevelopment from "./utils/environment";
import ErrorReporting from "./utils/errorReporting";

import "moment/locale/cs";
import "moment/locale/da";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/fi";
import "moment/locale/fr";
import "moment/locale/hu";
import "moment/locale/it";
import "moment/locale/ja";
import "moment/locale/ko";
import "moment/locale/ms";
import "moment/locale/nb";
import "moment/locale/nl";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/ru";
import "moment/locale/sk";
import "moment/locale/sv";
import "moment/locale/th";
import "moment/locale/tr";
import "moment/locale/vi";
import "moment/locale/lv";
import { initProfile } from "./initProfile";

import moment from "moment";
moment.suppressDeprecationWarnings = true;
moment.locale("en");

//support for older browsers (e.g. IE10), needs to be loaded before react-intl provider is initialized
intlPolyfill();

loadAnalytics(
  process.env.REACT_APP_GA_MAIN_CODE,
  process.env.REACT_APP_GA_SEC_CODE
);

//catch errors and report to Airbrake
const errorReporting = new ErrorReporting({
  isEnabled: !isDevelopment,
  environment: process.env.REACT_APP_ENVIRONMENT || "development",
  projectId: process.env.REACT_APP_AIRBRAKE_PROJECT_ID,
  projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY
});

initProfile({
  isDevelopment,
  errorReporting,
  dataVersion: process.env.REACT_APP_BUILD_TIME || "development"
});

trackEvents.profileLoaded();
