import * as types from "../constants/ActionTypes";
import { computeFontColor } from "../utils/branding";

//TODO: this should be taken from localstorage; it should be also saved there on successful request
const DEFAULT_COLOR = "#494b54";
const DEFAULT_ACCENT_COLOR = "#00779e";

const computeBranding = ({ common }) => {
  const {
    accent_color = DEFAULT_ACCENT_COLOR,
    primary_color = DEFAULT_COLOR,
    logo_url = undefined
  } = common;

  const primaryFontColor = computeFontColor(primary_color);

  return {
    // general (used also by react-components):
    primaryColor: primary_color,
    primaryFontColor,
    accentColor: accent_color,
    logoUrl: logo_url
  };
};

export const initialState = {
  ...computeBranding({ common: {} }),
  loaded: undefined
};

function branding(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_BRANDING_RECEIVED: {
      //TODO: save branding to LS to avoid app re-render for different branding object
      return {
        ...state,
        ...computeBranding(action.data),
        loaded: true
      };
    }
    case types.FETCH_BRANDING_ERROR:
      return {
        ...state,
        loaded: true
      };
    default:
      return state;
  }
}

export default branding;
