import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

import { CommonLayout, COLORS, BREAKPOINTS } from "@onelogin/react-components";

import { WIDE_VIEW_LIMIT } from "../../../constants/Env";

import { clearNotification } from "../../../actions/notificationsActions";
import QuestionHandler from "./QuestionHandler";

const Header = styled.div`
  font-size: 22px;
  color: #3f4040;
  width: 100%;
  padding: 0;
  border-bottom: 1px solid ${COLORS.SEPARATOR_COLORS.MEDIUM};
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  max-width: ${WIDE_VIEW_LIMIT}px;
  padding: 22px 30px;
`;

const MainContent = styled.div`
  height: 100%;
  background-color: ${COLORS.GRAY_COLORS.GRAY_98};
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const FormWrapper = styled.div`
  width: 100%;
  max-width: ${WIDE_VIEW_LIMIT}px;
  padding: 0 30px 22px 30px;
`;

const SecurityQuestionsPage = ({
  notifications,
  clearDisplayedNotification,
  questions,
  required,
  onSubmit
}) => {
  return (
    <CommonLayout
      projectRoot="profile2"
      navbarMaxWidth={WIDE_VIEW_LIMIT}
      mobileBreakpoint={parseInt(BREAKPOINTS.PORTAL_MIDDLE, 10)}
      notifications={notifications}
      clearDisplayedNotification={clearDisplayedNotification}
    >
      <Header>
        <HeaderWrapper>
          <FormattedMessage
            defaultMessage="Register Security Questions"
            id="registerSecurityQuestions"
          />
        </HeaderWrapper>
      </Header>
      <MainContent>
        <FormWrapper>
          <QuestionHandler
            questions={questions}
            required={required}
            onSubmit={onSubmit}
          />
        </FormWrapper>
      </MainContent>
    </CommonLayout>
  );
};

const mapStateToProps = state => ({
  notifications: state.notifications.notificationsQueue
});

const mapDispatchToProps = dispatch => ({
  clearDisplayedNotification: notificationId => {
    dispatch(clearNotification(notificationId));
  }
});

SecurityQuestionsPage.propTypes = {
  questions: PropTypes.object.isRequired,
  required: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
  clearDisplayedNotification: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurityQuestionsPage);
