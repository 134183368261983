import client from "./httpClient";
import * as apiConstants from "../constants/Api";
import { normalizeError } from "./apiErrorHandling";

export const fetchBranding = async () => {
  try {
    // fetch branding data from CDN: https://cdn.onelogin.com/brands/airbus.onelogin.com.json
    return await client
      .get(`${apiConstants.SERVER_URL}/api/2/branding/branding.json`)
      .json();
  } catch (error) {
    // it returns errors with "application/xml" content-type
    throw await normalizeError(error);
  }
};
