import { Component } from "react";
import PropTypes from "prop-types";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.children !== this.errorChildren) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    const { airbrake } = this.context;

    if (airbrake && airbrake.notify) {
      airbrake.notify(error, {
        origin: info
      });
    }
  }

  render() {
    if (this.state.hasError) {
      this.errorChildren = this.props.children;
      return null;
    }

    return this.props.children;
  }
}

ErrorBoundary.contextTypes = {
  airbrake: PropTypes.object
};

export default ErrorBoundary;
