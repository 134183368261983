import * as types from "../constants/ActionTypes";
import * as apiConstants from "../constants/Api";
import { parseInitials } from "../utils/initials";

export const initialState = {
  loading: true,
  doReload: undefined,
  changedFormData: undefined,
  saving: undefined
};

const addDefaultCountryCode = phoneNumber => {
  if (phoneNumber) {
    const trimmedPhoneNumber = phoneNumber.trim();
    if (trimmedPhoneNumber[0] !== "+") {
      if (trimmedPhoneNumber[0] === "1") {
        return "+" + trimmedPhoneNumber;
      } else {
        return "+1" + trimmedPhoneNumber;
      }
    } else {
      return trimmedPhoneNumber;
    }
  } else {
    return phoneNumber;
  }
};

function user(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_USER_RECEIVED:
      const { user } = action;
      user.phoneNumber = addDefaultCountryCode(user.phoneNumber);
      const { userSettings } = user;
      const tabOptions = userSettings.tab_options;
      const { passwordExpiration = {} } = user;
      return {
        ...state,
        loading: false,
        fullName: user.name,
        firstName: user.name.split(" ")[0],
        initials: parseInitials(user.name),
        email: user.email,
        username: user.username,
        id: user.id,
        title: user.title,
        isDirectoryUser: user.isDirectoryUser,
        canManageDevices: user.canManageDevices,
        profilePicture: user.profilePicture,
        isAdmin: user.userType === apiConstants.ADMIN_USER_TYPE,
        isAssumed: !!user.assumedBy,
        assumedByAdmin: user.assumedBy === apiConstants.ADMIN,
        assumedBySuperadmin: user.assumedBy === apiConstants.SUPERADMIN,
        assumedBySandbox: user.assumedBy === apiConstants.SANDBOX,
        currentLanguageLocale: user.locale || "en",
        passwordExpires: passwordExpiration.expires,
        passwordExpirationDays: Math.max(passwordExpiration.days, 0),
        passwordChangedDays: user.passwordChangedDays,
        appAutoDetect: !!userSettings.app_auto_detect,
        phoneNumber: user.phoneNumber || undefined,
        phoneNumberIsEditable: userSettings.phone_number_editable || false,
        defaultTabId: tabOptions.default_tab_id,
        tabOptions: tabOptions.default_tab_options,
        profilePictureUploadEnabled:
          userSettings.profile_picture_upload_enabled,
        isMfaRequired: !!userSettings.otp_auth_enabled
      };
    case types.FETCH_TRACKING_ID:
      return {
        ...state,
        trackingId: action.trackingId
      };
    case types.FETCH_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.RELOAD_PROFILE_PHOTO:
      return { ...state, profilePicture: action.profilePicture };
    case types.UPDATE_USER_RECEIVED:
      return {
        ...state,
        saving: false,
        changedFormData: undefined, //remove changes after save
        ...action.data
      };
    case types.UPDATE_USER_FAILED:
      return {
        ...state,
        saving: false
      };
    case types.UPDATE_USER_RECEIVED_LANGUAGE_CHANGED:
      return {
        ...state,
        doReload: true
      };
    case types.SET_PROFILE_IMAGE_DATA:
      return {
        ...state,
        imageData: action.imageData
      };
    case types.SET_PROFILE_FORM_DATA:
      return {
        ...state,
        changedFormData: {
          ...state.changedFormData, //keep all previous changes
          ...action.formData //add recent change(s)
        }
      };
    case types.SET_ACTIVE_MENU_ITEM:
      return {
        ...state,
        changedFormData: action.isChanged
          ? undefined
          : { ...state.changedFormData } //remove changedFormData state when navigating away from profile
      };
    case types.UPDATE_USER_REQUEST:
      return {
        ...state,
        saving: true
      };
    default:
      return state;
  }
}

export default user;
