import { Component } from "react";
import PropTypes from "prop-types";

class PeriodicStatusCheck extends Component {
  componentDidMount() {
    const { checkStatus, checkPeriodMs } = this.props;
    this.checkInterval = setInterval(checkStatus, checkPeriodMs);
  }

  componentWillUnmount() {
    clearInterval(this.checkInterval);
  }

  render = () => null;
}

PeriodicStatusCheck.propTypes = {
  /** Function that is called with given period to check status */
  checkStatus: PropTypes.func.isRequired,
  /** Period of performing check in milisecond  */
  checkPeriodMs: PropTypes.number.isRequired
};

export default PeriodicStatusCheck;
