import React, { Children } from "react";
import styled from "styled-components";

import { COLORS, BREAKPOINTS } from "@onelogin/react-components";

const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  border-top: solid 1px ${COLORS.SEPARATOR_COLORS.MEDIUM};
  margin-top: 32px;
  padding: 16px 0;

  @media screen and (max-width: ${BREAKPOINTS.PHONE_LARGE}) {
    flex-direction: column;
    padding: 24px 0;
  }
`;

const StyledItem = styled.div`
  margin-right: 12px;

  &:first-child {
    margin-right: 0px;
  }

  @media screen and (max-width: ${BREAKPOINTS.PHONE_LARGE}) {
    margin-right: 0px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-top: 0px;
    }
  }
`;

const FormFooter = ({ children }) => (
  <Footer>
    {Children.map(children, child => child && <StyledItem>{child}</StyledItem>)}
  </Footer>
);

FormFooter.propTypes = {};

export default FormFooter;
