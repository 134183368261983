import { defineMessages } from "react-intl";

export const commonMessages = defineMessages({
  securityFactors: {
    id: "securityFactors",
    defaultMessage: "Security Factors"
  },
  remove: {
    id: "remove",
    defaultMessage: "Remove"
  },
  device: {
    id: "device",
    defaultMessage: "Device"
  },
  lastUsed: {
    id: "lastUsed",
    defaultMessage: "Last Used"
  },
  usedFor: {
    id: "usedFor",
    defaultMessage: "Used For"
  },
  configuredOn: {
    id: "configuredOn",
    defaultMessage: "Configured On"
  },
  cancel: {
    id: "cancel",
    defaultMessage: "Cancel"
  },
  browserExtension: {
    id: "broswerExtension",
    defaultMessage: "Browser Extension"
  },
  changePassword: {
    id: "changePassword",
    defaultMessage: "Change Password"
  },
  oneloginDesktop: {
    id: "oneloginDesktop",
    defaultMessage: "OneLogin Desktop"
  },
  oneloginLogo: {
    id: "oneloginLogo",
    defaultMessage: "OneLogin logo"
  },
  application: {
    id: "application",
    defaultMessage: "Application"
  },
  hostname: {
    id: "hostname",
    defaultMessage: "Hostname & OS"
  },
  lastAuthentication: {
    id: "lastAuthentication",
    defaultMessage: "Last Authentication"
  },
  noDevicesConfigured: {
    id: "noDevicesConfigured",
    defaultMessage: "No devices have been configured"
  },
  profile: {
    id: "profile",
    defaultMessage: "Profile"
  },
  recentActivity: {
    id: "recentActivity",
    defaultMessage: "Recent Activity"
  },
  location: {
    id: "location",
    defaultMessage: "Location"
  },
  eventType: {
    id: "eventType",
    defaultMessage: "Event Type"
  },
  appName: {
    id: "appName",
    defaultMessage: "App Name"
  },
  loginName: {
    id: "loginName",
    defaultMessage: "Login Name"
  },
  logOut: {
    id: "logOut",
    defaultMessage: "Log Out"
  },
  save: {
    defaultMessage: "Save Changes",
    id: "save"
  },
  browserDevice: {
    defaultMessage: "Browser/Device",
    id: "browserDevice"
  },
  time: {
    defaultMessage: "Time",
    id: "time"
  },
  trustedBrowsers: {
    defaultMessage: "Trusted Browsers",
    id: "trustedBrowsers"
  },
  more: {
    defaultMessage: "More",
    id: "more"
  },
  showDetails: {
    defaultMessage: "Show details",
    id: "showDetails"
  },
  current: {
    defaultMessage: "Current",
    id: "current"
  },
  revoke: {
    id: "revoke",
    defaultMessage: "Revoke"
  },
  browser: {
    id: "browser",
    defaultMessage: "Browser"
  },
  expireDate: {
    id: "expireDate",
    defaultMessage: "Expire Date"
  },
  details: {
    id: "details",
    defaultMessage: "Details"
  },
  revertToAdmin: {
    id: "revertToAdmin",
    defaultMessage: "Revert to Admin"
  },
  revertToSuperadmin: {
    id: "revertToSuperadmin",
    defaultMessage: "Revert to OneLogin Admin"
  },
  close: {
    id: "close",
    defaultMessage: "Close"
  }
});
