import { map } from "ramda";
import client from "./httpClient";
import * as apiConstants from "../constants/Api";
import { getErrorHandler, normalizeError } from "./apiErrorHandling";

const errorHandler = getErrorHandler({});

const handleProfileServiceError = async error => {
  const normalized = await normalizeError(error);
  errorHandler(normalized);
  // we can't stop execution even if error was handled so we rethrow
  throw normalized;
};

const baseUrl = `${apiConstants.SERVER_URL}/profile-service`;

export const uploadProfilePhoto = async blob => {
  const formData = new FormData();
  formData.append("type", blob.type);
  formData.append("data", blob);

  const upload = await client
    .post(`${baseUrl}/me/photo`, {
      body: formData
    })
    .json()
    .catch(handleProfileServiceError);

  return upload;
};

export const deleteProfilePhoto = async () => {
  const deletePhoto = await client
    .delete(`${baseUrl}/me/photo`)
    .json()
    .catch(handleProfileServiceError);

  return deletePhoto;
};

export const fetchRecentActivity = async () => {
  const activity = await client
    .get(`${baseUrl}/recent-activity-grouped`)
    .json()
    .then(activity => activity.find(item => item.name === "ALL"))
    .then(activity => activity.events)
    .catch(handleProfileServiceError);

  return activity;
};

export const updateSettings = async data => {
  const user = await client
    .put(`${baseUrl}/settings`, {
      json: data
    })
    .json()
    .catch(handleProfileServiceError);

  return user;
};

export const fetchMe = async () => {
  const user = await client
    .get(`${baseUrl}/me`)
    .json()
    .catch(handleProfileServiceError);

  if (user.status === apiConstants.STATUS_EXPIRED_PASSWORD) {
    window.location.replace(apiConstants.PASSWORD_CHANGE_URL);
  }

  return user;
};

export const fetchAccount = async () => {
  const company = await client
    .get(`${baseUrl}/account`)
    .json()
    .catch(handleProfileServiceError);

  return company;
};

// MFA Endpoints

export const fetchDevices = async () => {
  const devices = await client
    .get(`${baseUrl}/devices`)
    .json()
    .then(
      map(device => ({
        id: device.id,
        factorTypeId: device.type_id,
        factorName: device.factor_name,
        customIconUrl: device.custom_icon_url,
        primary: device.default,
        createdAt: device.created_at,
        behavior: device.behavior,
        lastUsed: device.last_used,
        details: device.details,
        identifier: device.identifier,
        defaultFactorName: device.default_factor_name,
        isActive: device.is_active,
        isLoginDevice: device.is_login_device,
        isPasswordResetDevice: device.is_password_reset_device,
        isAppDevice: device.is_app_device
      }))
    )
    .catch(handleProfileServiceError);

  return devices;
};

export const updateDefault = async id => {
  await client
    .put(`${baseUrl}/devices/update-default`, {
      json: { id }
    })
    .json()
    .catch(handleProfileServiceError);
};

export const unsetDefault = async id => {
  await client
    .put(`${baseUrl}/devices/unset-default`, {
      json: { id }
    })
    .json()
    .catch(handleProfileServiceError);
};

export const deleteDevice = async id => {
  await client
    .delete(`${baseUrl}/devices/${id}`)
    .json()
    .catch(handleProfileServiceError);
};

export const fetchDesktopDetails = async () => {
  return await client
    .get(`${baseUrl}/desktop`)
    .json()
    .catch(handleProfileServiceError);
};

export const updateDeviceName = async (id, newName) => {
  return await client
    .put(`${baseUrl}/devices/rename`, {
      json: { id, new_device_name: newName }
    })
    .json()
    .catch(handleProfileServiceError);
};

// SSO Password Endpoints

export const fetchPasswordPolicy = async () => {
  const policy = await client
    .get(`${baseUrl}/sso-password/policy`)
    .json()
    .catch(handleProfileServiceError);

  return policy;
};

export const updatePassword = async passwordInfo => {
  const response = await client
    .put(`${baseUrl}/sso-password`, {
      json: passwordInfo
    })
    .json()
    .catch(handleProfileServiceError);

  return response;
};

// Factors Endpoint

export const fetchAvailableFactors = async trackingId => {
  const { availableFactors, token } = await client
    .get(`${baseUrl}/factors`, {
      headers: {
        "X-Correlation-Id": trackingId
      }
    })
    .json()
    .catch(handleProfileServiceError);

  return { availableFactors, token };
};

export const fetchMfaToken = async trackingId => {
  const token = await client
    .get(`${baseUrl}/factors/mfa-token`, {
      headers: {
        "X-Correlation-Id": trackingId
      }
    })
    .json()
    .catch(handleProfileServiceError);
  return token;
};

// Browsers endpoints

export const fetchTrustedBrowsers = async () => {
  const browsers = await client
    .get(`${baseUrl}/browsers`)
    .json()
    .catch(handleProfileServiceError);

  return browsers;
};

export const deleteTrustedBrowser = async id => {
  const response = await client
    .delete(`${baseUrl}/browsers/${id}`)
    .json()
    .catch(handleProfileServiceError);

  return response;
};
