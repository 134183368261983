import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { ModalDialog, Button, Icon } from "@onelogin/react-components";
import PropTypes from "prop-types";

import { commonMessages } from "../../../translations/commonMessages";
import { SMALL_MODAL_SIZE, MOBILE_VIEW_LIMIT } from "../../../constants/Env";

const Warning = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 24px;
  line-height: 1.71;

  @media screen and (max-width: ${MOBILE_VIEW_LIMIT}) {
    flex-direction: column;
    padding: 24px 20px;
    line-height: 1.5;
  }
`;

const WarningIcon = styled.div`
  margin-right: 16px;

  @media screen and (max-width: ${MOBILE_VIEW_LIMIT}) {
    margin-right: 0;
    margin-bottom: 21px;
  }
`;

const WarningMessage = styled.div`
  width: 100%;
  font-size: 14px;

  @media screen and (max-width: ${MOBILE_VIEW_LIMIT}) {
    font-size: 16px;
  }
`;

const RemoveCurrentBrowserWarning = ({ browser, onRemove, onClose }) => {
  return (
    <ModalDialog fixedWidth={SMALL_MODAL_SIZE} onClose={onClose}>
      <ModalDialog.Header>
        <FormattedMessage {...commonMessages.trustedBrowsers} />
      </ModalDialog.Header>

      <Warning>
        <WarningIcon>
          <Icon name="Warning-24" size="44px" alt="" />
        </WarningIcon>

        <WarningMessage>
          <FormattedMessage
            id="removeCurrentBrowserWarning"
            defaultMessage={`Revoke {browser} browser? You will be automatically logged out.`}
            values={{ browser: browser.name }}
          />
        </WarningMessage>
      </Warning>

      <ModalDialog.Actions>
        <Button size="medium" onClick={onClose} data-testid="cancel-button">
          <FormattedMessage {...commonMessages.cancel} />
        </Button>

        <Button
          size="medium"
          look="primary"
          onClick={() => onRemove(browser)}
          data-testid="confirm-revoke-button"
        >
          <FormattedMessage {...commonMessages.revoke} />
        </Button>
      </ModalDialog.Actions>
    </ModalDialog>
  );
};

const browserInfoShape = PropTypes.shape({
  name: PropTypes.string.isRequired
});

const osInfoShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired
});

const browserShape = PropTypes.shape({
  browserInfo: browserInfoShape,
  current: PropTypes.bool.isRequired,
  expirationDate: PropTypes.string,
  lastUsed: PropTypes.string.isRequired,
  osInfo: osInfoShape
});

RemoveCurrentBrowserWarning.propTypes = {
  browser: browserShape,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default RemoveCurrentBrowserWarning;
