import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { PUBLIC_URL } from "../constants/Env";

const LegacyRedirect = ({ location: { search } }) => {
  // If we had more querystring parsing in this repo, this would need to be extracted
  const paramPairs =
    search.length === 0
      ? {}
      : search
          .slice(1)
          .split("&")
          .reduce((o, pairStr) => {
            const [key, val] = pairStr.split("=");
            o[key] = val;
            return o;
          }, {});

  return (
    <Redirect
      to={`${PUBLIC_URL}${
        paramPairs["password_reset"] === "true" ? "/password" : ""
      }`}
    />
  );
};

LegacyRedirect.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  })
};

export default LegacyRedirect;
