import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import ReactMarkdown from "react-markdown";

import { connect } from "react-redux";
import {
  Button,
  SingleValueForm,
  Label,
  TextArea,
  COLORS
} from "@onelogin/react-components";
import styled from "styled-components";
import {
  submitHelpRequest,
  resetHelpRequestRedirectTo
} from "../actions/helpRequestActions";
import { goto } from "../actions/navigationActions";
import { helpContentSelector } from "../selectors/helpContentSelector";
import ErrorBoundary from "./ErrorBoundary";
import ResponsiveBlock from "./ResponsiveBlock";
import { PUBLIC_URL, NARROW_VIEW_LIMIT } from "../constants/Env";
import { commonMessages } from "../translations/commonMessages";

const ButtonWrap = styled.div`
  text-align: right;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
`;

const HelpDialog = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  text-align: left;
  flex: 1 1 100%;

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    padding: 0px;
  }
`;
const HEADER_HEIGHT = 70;
//this const should be removed or defined within RC; could wait for Navbar2
const MENUBAR_HEIGHT = 52;

const HelpHeader = styled.div`
  height: ${HEADER_HEIGHT}px;
  display: flex;
  justify-content: space-between;
`;

const HelpHeaderLeft = styled.div`
  align-self: center;
  font-size: 26px;
  color: #494b54;
`;

const HelpMainContent = styled.div`
  background: #f7f9fa;
  flex-grow: 1;
  border-top: 1px solid ${COLORS.GRAY_COLORS.GRAY_88};
  min-height: ${props =>
    `calc(100vh - ${HEADER_HEIGHT}px - ${
      props.isNavbarless ? 0 : MENUBAR_HEIGHT
    }px)`};
`;

const TwoColumnContainer = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    display: block;
  }
`;

const LeftColumnContainer = styled.div`
  border-right: 1px solid ${COLORS.GRAY_COLORS.GRAY_88};
  position: relative;
  flex: 0 0 40%;
  padding-top: 32px;

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    border-right: none;
  }
`;

const RightColumnContainer = styled.div`
  position: relative;
  flex: 0 0 60%;
  padding-top: 32px;
  padding-left: 32px;
  margin-bottom: 24px;

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    padding: 0;
  }
`;

const SupportRequestDescription = styled.div`
  padding-right: 32px;
  font-family: Roboto, Helvetica Neue, Helvetica, Segoe UI, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.29;
  text-align: left;
  color: #181d27;
  text-align: justify;

  p {
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    margin-bottom: 24px;
    line-height: 1.29;
    color: #181d27;
    padding-right: 0px;
  }
`;

const RequestFieldTextArea = styled(TextArea)`
  height: 462px;
`;

const FooterButtons = styled.div`
  border-top: 1px solid ${COLORS.GRAY_COLORS.GRAY_88};

  button {
    margin-left: 15px;
  }
`;

export class HelpContent extends Component {
  onHelpCancel = () => {
    this.navigateOut();
  };

  navigateOut = () => {
    const { goto, redirectTo, history } = this.props;

    //when user came from recent activity screen, redirect him back there
    //otherwise when he used 'Support' from user menu, redirect him to profile root
    goto(history, redirectTo || PUBLIC_URL);
  };

  render() {
    const {
      onHelpSubmit,
      account,
      content,
      sending,
      success,
      isAssumed,
      isNavbarless
    } = this.props;

    if (success === true) {
      this.navigateOut();
      return null;
    }

    return (
      <ErrorBoundary>
        <SingleValueForm
          onSubmit={onHelpSubmit}
          value={content}
          render={({ invalid, value, onChange }) => (
            <HelpDialog className="help-dialog">
              <ResponsiveBlock>
                <HelpHeader>
                  <HelpHeaderLeft>
                    <FormattedMessage
                      defaultMessage="Support Request"
                      id="supportRequestHeader"
                    />
                  </HelpHeaderLeft>
                </HelpHeader>
              </ResponsiveBlock>
              <HelpMainContent isNavbarless={isNavbarless}>
                <ResponsiveBlock>
                  <TwoColumnContainer>
                    <LeftColumnContainer>
                      <SupportRequestDescription>
                        {account.helpInstruction ? (
                          <ReactMarkdown source={account.helpInstruction} />
                        ) : (
                          <FormattedMessage
                            defaultMessage="Please describe the issue you are experiencing and send it to your OneLogin administrator."
                            id="supportDefaultDescription"
                          />
                        )}
                      </SupportRequestDescription>
                    </LeftColumnContainer>
                    <RightColumnContainer>
                      <Label forId="help" isRequired>
                        <FormattedMessage
                          defaultMessage="Describe the issue you're having"
                          id="requestField"
                        />
                      </Label>
                      <RequestFieldTextArea
                        value={value}
                        onChange={event => onChange(event.target.value)}
                        invalid={invalid}
                        restrictResize
                      />
                    </RightColumnContainer>
                  </TwoColumnContainer>
                </ResponsiveBlock>
                <FooterButtons>
                  <ResponsiveBlock>
                    <ButtonWrap>
                      <Button
                        onClick={this.onHelpCancel}
                        data-testid="cancel-button"
                      >
                        <FormattedMessage {...commonMessages.cancel} />
                      </Button>
                      <Button
                        look="primary"
                        type="submit"
                        data-testid="submit-button"
                        disabled={isAssumed}
                      >
                        {sending ? (
                          <FormattedMessage
                            defaultMessage="Submitting…"
                            id={"submitting"}
                          />
                        ) : (
                          <FormattedMessage
                            defaultMessage="Submit"
                            id={"submit"}
                          />
                        )}
                      </Button>
                    </ButtonWrap>
                  </ResponsiveBlock>
                </FooterButtons>
              </HelpMainContent>
            </HelpDialog>
          )}
        />
      </ErrorBoundary>
    );
  }
}

HelpContent.propTypes = {
  account: PropTypes.object
};

const mapStateToProps = (state, ownProps) => ({
  account: state.account,
  content: helpContentSelector(state),
  sending: state.helpRequest.sending,
  success: state.helpRequest.success,
  isAssumed: state.user.isAssumed,
  redirectTo: state.helpRequest.redirectTo,
  isNavbarless: state.environment.isNavbarless
});

const mapDispatchToProps = dispatch => ({
  onHelpSubmit: supportRequest => {
    dispatch(
      submitHelpRequest({
        supportRequest
      })
    );
  },
  goto: (history, redirectTo) => {
    dispatch(resetHelpRequestRedirectTo());
    dispatch(goto(history, redirectTo));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HelpContent)
);
