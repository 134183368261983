import styled from "styled-components";

const ScreenHeader = styled.div`
  font-size: 22px;
  color: #3f4040;
  margin-bottom: 30px;
  padding: 32px 0px 0px 32px;
`;

export default ScreenHeader;
