import * as types from "../constants/ActionTypes";

function desktopDetails(state = { loaded: false }, action) {
  switch (action.type) {
    case types.FETCH_DESKTOP_DETAILS:
      return {
        ...state,
        data: action.desktop,
        loaded: true
      };
    default:
      return state;
  }
}

export default desktopDetails;
