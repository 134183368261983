import { partition, prop } from "ramda";
import * as types from "../constants/ActionTypes";
import userAgentParser from "../utils/userAgentWrapper";

const initialState = {
  browsers: null,
  browsersLoaded: false,
  browsersError: false,
  browserRemoveSuccess: null
};

function trustedBrowser(state = initialState, action) {
  switch (action.type) {
    case types.RECEIVE_TRUSTED_BROWSERS_SUCCESS:
      return {
        ...state,
        browsers: currentBrowserFirst(action.browsers.map(convertBrowser)),
        browsersLoaded: true
      };
    case types.RECEIVE_TRUSTED_BROWSERS_FAILURE:
      return {
        ...state,
        browsersError: true
      };
    case types.REMOVE_TRUSTED_BROWSER_SUCCESS:
      return {
        ...state,
        browsers: state.browsers.filter(b => b.id !== action.id),
        browserRemoveSuccess: true
      };
    case types.REMOVE_TRUSTED_BROWSER_RESET:
      return {
        ...state,
        browserRemoveSuccess: null
      };
    case types.REMOVE_CURRENT_BROWSER:
      window.location.reload(true);
      return {};
    default:
      return state;
  }
}

const convertBrowser = browser => {
  const agent = userAgentParser(browser.userAgentString);

  return {
    ...browser,
    browserInfo: agent.browser,
    osInfo: agent.os
  };
};

const currentBrowserFirst = browsers => {
  const [current, others] = partition(prop("current"), browsers);
  return current.concat(others);
};

export default trustedBrowser;
