import styled from "styled-components";
import { NARROW_VIEW_LIMIT } from "../../constants/Env";

export const DesktopViewControl = styled.div`
  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    display: none;
  }
`;

export const MobileViewControl = styled.div`
  display: none;
  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    display: inherit;
  }
`;
