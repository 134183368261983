import AirbrakeClient from "airbrake-js";

export class Airbrake {
  constructor(config) {
    this.environment = config.environment;

    this.airbrakeClient = new AirbrakeClient({
      projectId: config.projectId,
      projectKey: config.projectKey
    });

    this.airbrakeClient.addFilter(this._addEnvironment);
    this.airbrakeClient.addFilter(this._addExtraParams);
  }

  notify(err, extraParams) {
    this.extraParams = extraParams || {};
    this.airbrakeClient.notify(err);
  }

  _addEnvironment = notice => {
    notice.context = {
      ...(notice.context || {}),
      environment: this.environment
    };

    return notice;
  };

  _addExtraParams = notice => {
    notice.params = { ...(notice.params || {}), ...this.extraParams };

    return notice;
  };
}
